import { FC, useMemo } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useNavigate } from 'react-router-dom';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { parseJSON } from '../../../../lib/utils/helpers';
import {
  openLinkAndTrack,
  sendDataToAnalytics,
} from '../../../../lib/utils/sendDataToAnalytics';

import styles from './index.module.scss';
import { Operation } from '../../../../lib/types/product';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useDispatch } from 'react-redux';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { useGetCurrentPageName } from '../../../../lib/hooks/endUserHooks/useGetCurrentPageName';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
} from '../../../campaignDetails/hooks/useGetPositionOption';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

export const FriendsSection: FC = () => {
  const { isMobile } = useContainerQuery();
  const { sections, allProducts } = useAppSelector(({ endUser }) => endUser);
  const friendsSectionContent = sections?.[SectionName.FRIENDS];
  const parsedAdditionalInfo = parseJSON(friendsSectionContent?.product?.additionalInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentPage } = useGetCurrentPageName();
  const { foundObjects } = useGetCampaignObjectPositions({
    page: currentPage,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS],
    elements: [CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO],
  });

  const handleClickMainButton = () => {
    if (friendsSectionContent?.button.operation === Operation.IFRAME) {
      openLinkAndTrack(friendsSectionContent.button.link);
    } else if (friendsSectionContent?.button.operation === Operation.REDIRECT) {
      const associatedProduct = allProducts.find(
        (product) => product.id === friendsSectionContent.product.associatedProduct,
      );

      const associatedProductCategory = associatedProduct?.category;

      if (associatedProductCategory) {
        navigate(
          `/${ROUTER_PATHS.houseModel}/${friendsSectionContent.product.category.demandAreaId}`,
          {
            state: { scrollTo: SectionName.PRODUCT_DETAILS },
          },
        );

        setTimeout(() => {
          dispatch(setCurrentCategory({ category: associatedProductCategory }));
        }, 100);
      }
    }

    sendDataToAnalytics(
      'Interesse an energie schwaben',
      'Wechsel zur Website',
      'User nimmt Kontakt zu energie schwaben auf',
    );
  };

  const containerCampaignObject = useMemo(() => {
    if (foundObjects.length) {
      return foundObjects.find(
        (obj) => obj.type === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO,
      );
    }

    return null;
  }, [foundObjects]);

  if (!friendsSectionContent?.headline2) {
    return null;
    // maybe need more reliable check instead of headline2
  }

  return (
    <>
      {isMobile ? (
        <div className={styles.wrapper}>
          <EndUserTypography
            type="headlineSecond"
            component="Title"
            className={styles.title}
          >
            {friendsSectionContent.headline2}
          </EndUserTypography>
          <EndUserTypography
            type="headlineThird"
            component="Title"
            className={styles.subTitle}
          >
            {friendsSectionContent.headline3}
          </EndUserTypography>
          <div className={styles.imageWrapper}>
            <img
              className={styles.image}
              src={parsedAdditionalInfo.picture.s3Url}
              alt={parsedAdditionalInfo.picture.originalFileName}
            />

            {containerCampaignObject && (
              <CampaignObjectImage
                campaignObject={containerCampaignObject}
                imageClassName={styles.campaignObject}
              />
            )}
          </div>

          <EndUserTypography type="body" component="Text" className={styles.description}>
            {friendsSectionContent.product.description}
          </EndUserTypography>

          <EndUserButton
            onClick={handleClickMainButton}
            type="primary"
            className={styles.button}
          >
            {friendsSectionContent?.button?.text}
          </EndUserButton>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <EndUserTypography
              type="headlineSecond"
              component="Title"
              className={styles.title}
            >
              {friendsSectionContent.headline2}
            </EndUserTypography>
            <EndUserTypography
              type="headlineThird"
              component="Title"
              className={styles.subTitle}
            >
              {friendsSectionContent.headline3}
            </EndUserTypography>
            <EndUserTypography
              type="body"
              component="Text"
              className={styles.description}
            >
              {friendsSectionContent.product.description}
            </EndUserTypography>

            <EndUserButton
              onClick={handleClickMainButton}
              type="primary"
              className={styles.button}
            >
              {friendsSectionContent?.button?.text}
            </EndUserButton>
          </div>
          <div className={styles.column}>
            <img
              src={parsedAdditionalInfo.picture.s3Url}
              alt={parsedAdditionalInfo.picture.originalFileName}
            />
            {containerCampaignObject && (
              <div className={styles.objectPosition}>
                <CampaignObjectImage campaignObject={containerCampaignObject} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
