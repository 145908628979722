import { useTranslation } from 'react-i18next';
import { CAMPAIGN_COLUMNS } from '../helpers/consts';
import { useCampaignDateColumn } from './useCampaignDateColumn';

const title = 'pages.campaigns.campaignTable.columns.startDate';

export function useStartDateColumn() {
  const { t } = useTranslation();

  return useCampaignDateColumn({
    arrangedId: CAMPAIGN_COLUMNS.START_DATE,
    title: t(title),
    filterFromName: 'startDateFrom',
    filterToName: 'startDateTo',
    columnProps: {
      width: 240,
    },
  });
}
