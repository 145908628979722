import { api, TAGS_KEY } from './index';
import {
  BonusCatalogItemT,
  ContactFormMutation,
  DomainT,
  EndUserDataT,
  EndUserGuestWishlistProduct,
  EndUserPublicDemandAreasT,
  EndUserPublicModelT,
  EndUserRuleActionStatusLevelResponseT,
  EndUserStatusLevelResponseT,
  GetActiveCampaignsResponse,
  GetActiveCampaignsTRequest,
  GetCompletedCampaignElementsT,
  GetEndUserTenantAndProjectT,
  GetFinderRecommendationsT,
  UpdateWishlistMutation,
} from '../../types/endUser';
import { GLOBAL_SETTINGS_NAMES } from '../../utils/constants';
import { ResultWithPagination } from '../../types/pagination';
import { SectionResponseT } from '../../types/sections';
import { ProductRecommendationT, ProductT } from '../../types/product';
import {
  FinderProduct,
  GetGlobalSettingsPayloadT,
  GlobalSettingsResponseT,
} from '../../types/entities';
import { BonusT } from '../../types/statusLevel';
import { store } from '../index';

type GetDesignSettingsPayload = {
  projectId?: number | string;
  tenantId?: number | string;
};

type DesignSettingsResponse = {
  name: GLOBAL_SETTINGS_NAMES;
  projectId: number;
  settings: string;
}[];

export const endUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEndUserDemandAreas: build.query<
      EndUserDataT,
      { modelUrl: string; tenantId: string }
    >({
      query: ({ modelUrl, tenantId }) => ({
        url: 'end-user/demand-area',
        method: 'GET',
        params: { modelUrl },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      transformResponse: (response: EndUserDataT) => {
        const { listDemandArea, ...rest } = response;
        const sortedDemandAreas = listDemandArea?.sort(
          (a, b) => a?.position?.number - b?.position?.number,
        );

        return { ...rest, listDemandArea: sortedDemandAreas };
      },
      providesTags: [TAGS_KEY.END_USER_DEMAND_AREAS, TAGS_KEY.CAMPAIGNS],
    }),
    getEndUserStatusLevel: build.query<
      EndUserStatusLevelResponseT,
      { projectId: string; tenantId: string }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/status-level/${projectId}/get-status-levels`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getEndUserGuestStatusLevel: build.query<
      EndUserStatusLevelResponseT,
      { projectId: string; tenantId: string }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/status-level/${projectId}/get-status-levels`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getEndUserRuleActionStatusLevel: build.query<
      EndUserRuleActionStatusLevelResponseT[],
      { projectId: string; tenantId: string }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/rule-action?projectId=${projectId}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getDesignSettingsForEndUser: build.query<
      DesignSettingsResponse,
      GetDesignSettingsPayload
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/global-setting/${projectId}?settingsName=${GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getBrandKitSettingsForEndUser: build.query<
      DesignSettingsResponse,
      GetDesignSettingsPayload
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/global-setting/${projectId}?settingsName=${GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS_BRAND_KIT}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getGeneralSettingsForEndUser: build.query<
      GlobalSettingsResponseT,
      GetGlobalSettingsPayloadT
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/global-setting/${projectId}?settingsName=${GLOBAL_SETTINGS_NAMES.GENERAL_SETTINGS}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getSection: build.query<
      ResultWithPagination<SectionResponseT[]>,
      { projectId?: string; tenantId?: string }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/section/${projectId}/get-section`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getWishList: build.query<
      ResultWithPagination<ProductT[]>,
      { projectId: string; tenantId: string; page?: number; size?: number }
    >({
      query: ({ projectId, tenantId, page = 0, size = 100 }) => ({
        url: `end-user/wish-list/${projectId}?page=${page}&size=${size}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      providesTags: [TAGS_KEY.END_USER_WISHLIST],
    }),
    addProductToTheWishlist: build.mutation<unknown, UpdateWishlistMutation>({
      query: ({ projectId, productId, tenantId }) => ({
        url: `end-user/wish-list/${projectId}/${productId}`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.END_USER_WISHLIST],
    }),
    deleteProductFromTheWishlist: build.mutation<unknown, UpdateWishlistMutation>({
      query: ({ projectId, productId, tenantId }) => ({
        url: `end-user/wish-list/${projectId}/${productId}/delete`,
        method: 'DELETE',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.END_USER_WISHLIST],
    }),
    switchActivationBonuses: build.mutation<unknown, any>({
      query: ({ projectId, tenantId, status, bonuses }) => ({
        url: `end-user/set-bonus-status/${projectId}`,
        method: 'POST',
        body: { status, bonuses },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    setBonusPayoutType: build.mutation<unknown, any>({
      query: ({ projectId, tenantId, payoutType, bonusId }) => ({
        url: `end-user/set-bonus-payout-type/${projectId}/${bonusId}/${payoutType}`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    addProductToIrrelevant: build.mutation<unknown, any>({
      query: ({ projectId, productId, tenantId, reason }) => ({
        url: `end-user/product/${productId}/${projectId}/add/irrelevant-reason`,
        method: 'PUT',
        body: {},
        params: { reason },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.END_USER_DEMAND_AREAS],
    }),
    deleteProductFromIrrelevant: build.mutation<unknown, any>({
      query: ({ projectId, productId, tenantId }) => ({
        url: `end-user/product/${productId}/${projectId}/delete/irrelevant-reason`,
        method: 'DELETE',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.END_USER_DEMAND_AREAS],
    }),
    getPublicDomains: build.query<DomainT[], void>({
      query: () => ({
        url: `end-user/public/get-domains`,
        method: 'GET',
      }),
    }),
    getTenantAndProjectIds: build.query<
      GetEndUserTenantAndProjectT,
      { modelUrl: string }
    >({
      query: ({ modelUrl }) => ({
        url: `end-user/public/get-tenant-project`,
        method: 'GET',
        params: { modelUrl },
      }),
    }),
    getPublicDemandAreas: build.query<
      EndUserPublicDemandAreasT,
      { projectId?: number; tenantId?: number }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/demand-area/${projectId}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      transformResponse: (response: EndUserPublicDemandAreasT) => {
        const { listDemandArea, ...rest } = response;
        const sortedDemandAreas = listDemandArea?.sort(
          (a, b) => a?.position?.number - b?.position?.number,
        );

        return { ...rest, listDemandArea: sortedDemandAreas };
      },
    }),
    sendContactForm: build.mutation<unknown, ContactFormMutation>({
      query: ({ isGuestMode, projectId, tenantId, body }) => ({
        url: `end-user/${isGuestMode ? 'public/' : ''}contact-form?projectId=${projectId}`,
        method: 'POST',
        body,
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getPublicModelInfo: build.query<
      EndUserPublicModelT,
      { projectId?: number; tenantId?: number }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/project/${projectId}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getReceivedBonuses: build.query<
      ResultWithPagination<BonusT[]>,
      { projectId?: number; tenantId?: number }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/bonus-catalog/${projectId}/bonuses?page=0`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      transformResponse: (response: ResultWithPagination<BonusT[]>) => {
        const updatedBonuses = response.content.map((bonus) =>
          bonus.historicBonus
            ? {
                ...bonus,
                bonusCatalog: bonus.historicBonus,
              }
            : bonus,
        );

        return { ...response, content: updatedBonuses };
      },
    }),
    startCalculationRulesForGuest: build.mutation<
      {
        wishList: EndUserGuestWishlistProduct[];
        recommendations: ProductRecommendationT[];
        endUserStatusLevels: EndUserRuleActionStatusLevelResponseT[];
        wishListBonuses: number[];
      },
      void
    >({
      query: () => {
        const { wishlistProducts, tenantAndProject } = store.getState().endUser;

        const projectId = Number(tenantAndProject?.projectId);
        const tenantId = String(tenantAndProject?.tenantId);

        const wishList = wishlistProducts.map((product) => ({
          projectId: projectId,
          productId: product.id,
          special: Boolean(product.countSpecialProducts),
          product: { ...product },
        }));

        return {
          url: `end-user/public/rule/calculate/${projectId}`,
          method: 'POST',
          body: {
            wishList,
          },
          headers: {
            'X-TENANT': String(tenantId),
          },
        };
      },
    }),
    getRecommendations: build.query<
      ResultWithPagination<ProductRecommendationT[]>,
      { tenantId: string; page?: number; size?: number }
    >({
      query: ({ tenantId, page = 0, size = 100 }) => {
        return {
          url: `end-user/recommendations?page=${page}&size=${size}`,
          method: 'GET',
          headers: {
            'X-TENANT': String(tenantId),
          },
        };
      },
    }),
    getBonusesFromRules: build.query<
      ResultWithPagination<{ bonusId: number }[]>,
      { page?: number; size?: number }
    >({
      query: ({ page = 0, size = 100 }) => {
        const { tenantAndProject } = store.getState().endUser;
        const projectId = Number(tenantAndProject?.projectId);
        const tenantId = String(tenantAndProject?.tenantId);

        return {
          url: `end-user/bonus-wish-list/${projectId}?page=${page}&size=${size}`,
          method: 'GET',
          headers: {
            'X-TENANT': String(tenantId),
          },
        };
      },
      providesTags: [TAGS_KEY.END_USER_WISHLIST],
    }),
    getEndUserBonusCatalog: build.query<
      ResultWithPagination<BonusCatalogItemT[]>,
      { projectId: string; tenantId: string }
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/${projectId}/get-bonus-catalog`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    saveFinderRecommendationProducts: build.mutation<
      void,
      { finderProducts: FinderProduct[]; tenantId: string }
    >({
      query: ({ finderProducts, tenantId }) => ({
        url: 'end-user/konto-recommendation/create',
        method: 'POST',
        body: finderProducts,
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getFinderRecommendationProducts: build.query<
      GetFinderRecommendationsT,
      { tenantId: string }
    >({
      query: ({ tenantId }) => ({
        url: 'end-user/konto-recommendation/get',
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getActiveCampaigns: build.query<
      GetActiveCampaignsResponse,
      GetActiveCampaignsTRequest
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/campaign/${projectId}/get-active-campaigns`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getCompletedCampaignElements: build.query<
      GetCompletedCampaignElementsT,
      { campaignId: number; tenantId: number }
    >({
      query: ({ tenantId, campaignId }) => ({
        url: `end-user/campaign/${campaignId}/list`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      providesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    findCampaignElementForUser: build.mutation<
      void,
      { campaignId: number; uuid: string; tenantId: string }
    >({
      query: ({ campaignId, uuid, tenantId }) => ({
        url: `end-user/campaign/${campaignId}/find?uuid=${uuid}`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.CAMPAIGNS, TAGS_KEY.CAMPAIGNS],
    }),
    getPublicActiveCampaigns: build.query<
      GetActiveCampaignsResponse,
      GetActiveCampaignsTRequest
    >({
      query: ({ projectId, tenantId }) => ({
        url: `end-user/public/${projectId}/get-active-campaigns`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
  }),
});

export const {
  useGetEndUserDemandAreasQuery,
  useGetEndUserStatusLevelQuery,
  useGetEndUserGuestStatusLevelQuery,
  useGetEndUserRuleActionStatusLevelQuery,
  useGetDesignSettingsForEndUserQuery,
  useGetBrandKitSettingsForEndUserQuery,
  useGetSectionQuery,
  useGetWishListQuery,
  useAddProductToTheWishlistMutation,
  useDeleteProductFromTheWishlistMutation,
  useGetPublicDomainsQuery,
  useGetTenantAndProjectIdsQuery,
  useGetPublicDemandAreasQuery,
  useSendContactFormMutation,
  useGetPublicModelInfoQuery,
  useGetGeneralSettingsForEndUserQuery,
  useAddProductToIrrelevantMutation,
  useDeleteProductFromIrrelevantMutation,
  useSetBonusPayoutTypeMutation,
  useSwitchActivationBonusesMutation,
  useGetReceivedBonusesQuery,
  useStartCalculationRulesForGuestMutation,
  useGetRecommendationsQuery,
  useGetBonusesFromRulesQuery,
  useGetEndUserBonusCatalogQuery,
  useSaveFinderRecommendationProductsMutation,
  useGetFinderRecommendationProductsQuery,
  useGetActiveCampaignsQuery,
  useGetCompletedCampaignElementsQuery,
  useFindCampaignElementForUserMutation,
  useGetPublicActiveCampaignsQuery,
} = endUserApi;
