import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { AppSelect } from '../../../../../components/AppSelect';
import { ElementSubValues, ElementValues } from '../../../types/entities';
import { UpdateRuleBuilderPayload } from '../../CustomRuleBuilder';
import { dataFieldOptions, statusLevelStaticOptions } from '../../../helpers';
import { useGetElementOperatorOptions } from '../../../hooks/useGetElementOperatorOptions';
import { ProductSubValue2T, SubValue2T, SubValueT } from '../../../types';
import { OnSelectGroupPropsT } from '../../../../../components/AppSelect/types';

type Props = {
  value: string;
  subValue?: SubValueT;
  subValue2?: SubValue2T;
  ruleId: string;
  handleChange: (values: UpdateRuleBuilderPayload) => void;
  disabled: boolean;
  index?: number;
  campaignId?: number;
};

export const ElementOperator: FC<Props> = ({
  value,
  subValue,
  subValue2,
  handleChange,
  ruleId,
  disabled,
  index,
  campaignId,
}) => {
  // TODO add translation

  const {
    elementOptions,
    bonusOptions,
    thresholdOptions,
    statusLevelOptions,
    productOptions,
    productSubelementOptions,
    campaignsOptions,
    campaignElementsOptions,
  } = useGetElementOperatorOptions();

  const isScoreSelected = value == ElementValues.Score;
  const isRightBordersHidden =
    (value === ElementValues.Product ||
      value === ElementValues.StatusLevel ||
      value === ElementValues.Campaign) &&
    subValue;

  const onChange = (values: { value: string }) => {
    handleChange({
      value: values.value,
      ruleId,
      subValue: undefined,
      subValue2: undefined,
    });
  };

  const onChangeFirstAdditionalField = useCallback(
    (newValue: string | number | { value: number }[]) => {
      if (value && typeof newValue === 'string') {
        handleChange({
          ruleId,
          subValue: newValue,
          value,
        });
      } else if (value && Array.isArray(newValue)) {
        const arrayIds = newValue?.map((item) => item?.value);
        handleChange({ value, ruleId, subValue, subValue2: arrayIds });
      } else if (value && typeof newValue === 'number') {
        handleChange({
          value,
          subValue: newValue,
          ruleId,
        });
      }
    },
    [handleChange, ruleId, subValue, value],
  );

  const onChangeSecondSubValue = useCallback(
    (newSubValue2: string | { value: number }[]) => {
      if (value && subValue && typeof newSubValue2 === 'string') {
        handleChange({ value, ruleId, subValue, subValue2: newSubValue2 });
      }
      if (value && subValue && Array.isArray(newSubValue2)) {
        const arrayIds = newSubValue2?.map((item) => item?.value);
        handleChange({ value, ruleId, subValue, subValue2: arrayIds });
      }
    },
    [handleChange, ruleId, subValue, value],
  );

  const onChangeSecondSubProductsValue = useCallback(
    (
      newSubValue2: { value: number; categoryId: number }[],
      selectedCampaignId?: number,
    ) => {
      if (value && subValue && Array.isArray(newSubValue2)) {
        const arrayIds = newSubValue2?.map((item) => item?.value);
        handleChange({
          value,
          ruleId,
          subValue,
          subValue2: {
            products: arrayIds,
            productCategories: (subValue2 as ProductSubValue2T)?.productCategories,
            isListEnabled: (subValue2 as ProductSubValue2T)?.isListEnabled,
          },
          campaignId: selectedCampaignId ? selectedCampaignId : null,
        });
      }
    },
    [handleChange, ruleId, subValue, subValue2, value],
  );
  const onChangeSecondSubValueGroup = useCallback(
    ({ productCategories, products }: OnSelectGroupPropsT) => {
      const productsIds = products?.map((item) => Number(item?.value));
      handleChange({
        value,
        ruleId,
        subValue,
        subValue2: {
          products:
            productsIds !== undefined
              ? productsIds
              : (subValue2 as ProductSubValue2T)?.products,
          productCategories,
          isListEnabled: (subValue2 as ProductSubValue2T)?.isListEnabled,
        },
      });
    },
    [handleChange, ruleId, subValue, subValue2, value],
  );

  const onSelectList = useCallback(
    (isListEnabled: boolean) => {
      handleChange({
        value,
        ruleId,
        subValue,
        subValue2: {
          products: (subValue2 as any)?.products,
          productCategories: (subValue2 as any)?.productCategories,
          isListEnabled,
        },
      });
    },
    [handleChange, ruleId, subValue, subValue2, value],
  );

  const FirstAdditionalComponent = useMemo(() => {
    switch (value) {
      case ElementValues.Bonus:
        return (
          <AppSelect
            key={ElementValues.Bonus}
            isMultiSelect
            selectAll
            disabled={disabled}
            onChange={(values: any) => onChangeFirstAdditionalField(values)}
            className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
            options={bonusOptions}
            value={subValue2}
            label="Bonus"
          />
        );
      case ElementValues.StatusLevel:
        return (
          <AppSelect
            key={ElementValues.StatusLevel}
            disabled={disabled}
            onChange={(values: { value: string }) =>
              onChangeFirstAdditionalField(values.value)
            }
            className={cn(styles.select, styles.asSecondSelect, styles.mSize, {
              [styles.hideRightBorders]: isRightBordersHidden,
            })}
            options={statusLevelStaticOptions}
            value={subValue}
            label="Status Level Type"
          />
        );
      case ElementValues.Product:
        return (
          <AppSelect
            key={ElementValues.Product}
            disabled={disabled}
            onChange={(values: { value: string }) =>
              onChangeFirstAdditionalField(values.value)
            }
            className={cn(styles.select, styles.asSecondSelect, styles.mSize, {
              [styles.hideRightBorders]: isRightBordersHidden,
            })}
            options={productSubelementOptions}
            value={subValue}
            label="Subelement"
          />
        );
      case ElementValues.DataField:
        return (
          <AppSelect
            key={ElementValues.DataField}
            disabled={disabled}
            onChange={(values: { value: string }) =>
              onChangeFirstAdditionalField(values.value)
            }
            className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
            options={dataFieldOptions}
            value={subValue}
            label="Datafield"
          />
        );
      case ElementValues.Campaign:
        return (
          <AppSelect
            key={ElementValues.Campaign}
            disabled={disabled}
            onChange={(values: { value: string }) =>
              onChangeFirstAdditionalField(values.value)
            }
            className={cn(styles.select, styles.asSecondSelect, styles.mSize, {
              [styles.hideRightBorders]: isRightBordersHidden,
            })}
            options={campaignsOptions}
            value={Number(subValue)}
            label="Campaign name"
          />
        );
      default:
        return null;
    }
  }, [
    bonusOptions,
    campaignsOptions,
    disabled,
    isRightBordersHidden,
    onChangeFirstAdditionalField,
    productSubelementOptions,
    subValue,
    subValue2,
    value,
  ]);

  const SecondAdditionalComponent = useMemo(() => {
    if (subValue === ElementSubValues.StatusLevel) {
      return (
        <AppSelect
          key={ElementSubValues.StatusLevel}
          isMultiSelect
          selectAll
          disabled={disabled}
          onChange={(values: { value: number }[]) => onChangeSecondSubValue(values)}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={statusLevelOptions}
          value={subValue2}
          label="Status Level"
        />
      );
    } else if (subValue === ElementSubValues.Threshold) {
      return (
        <AppSelect
          key={ElementSubValues.Threshold}
          disabled={disabled}
          onChange={(values: { value: string }) => onChangeSecondSubValue(values.value)}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={thresholdOptions}
          value={subValue2}
          label="Threshold"
        />
      );
    } else if (
      !Array.isArray(subValue2) &&
      typeof subValue2 !== 'string' &&
      (subValue === ElementSubValues.CoverageAmount ||
        subValue === ElementSubValues.Product ||
        subValue === ElementSubValues.ProductPoints)
    ) {
      return (
        <AppSelect
          key={`${subValue}-${index}`}
          isMultiSelect
          selectAll
          withSelectableGroups
          disabled={disabled}
          onChange={(values: { value: number; categoryId: number }[]) =>
            onChangeSecondSubProductsValue(values)
          }
          groupProps={{
            onSelectGroup: onChangeSecondSubValueGroup,
            selectedGroups: subValue2?.productCategories,
          }}
          listProps={{
            onSelectList,
            isListEnabled: subValue2?.isListEnabled,
          }}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={productOptions}
          value={subValue2?.products}
          label="Product"
        />
      );
    } else if (
      value === ElementValues.Campaign &&
      (typeof subValue === 'number' || typeof subValue === 'string')
    ) {
      return (
        <AppSelect
          key={ElementSubValues.CampaignElement}
          disabled={disabled}
          onChange={(values: { value: string }) => onChangeSecondSubValue(values.value)}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={campaignElementsOptions}
          value={subValue2}
          label="Campaign element"
        />
      );
    } else if (
      subValue === ElementSubValues.ForEachCampaign &&
      !Array.isArray(subValue2) &&
      typeof subValue2 !== 'string'
    ) {
      return (
        <AppSelect
          key={`${subValue}-${index}`}
          isMultiSelect
          selectAll
          withSelectableGroups
          disabled={disabled}
          onChange={(values: { value: number; categoryId: number }[]) =>
            onChangeSecondSubProductsValue(values, campaignId)
          }
          groupProps={{
            onSelectGroup: onChangeSecondSubValueGroup,
            selectedGroups: subValue2?.productCategories,
          }}
          listProps={{
            onSelectList,
            isListEnabled: subValue2?.isListEnabled,
          }}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={productOptions}
          value={subValue2?.products}
          label="Product"
        />
      );
    } else {
      return null;
    }
  }, [
    campaignId,
    subValue,
    subValue2,
    value,
    disabled,
    statusLevelOptions,
    onChangeSecondSubValue,
    thresholdOptions,
    index,
    onChangeSecondSubValueGroup,
    onSelectList,
    productOptions,
    onChangeSecondSubProductsValue,
    campaignElementsOptions,
  ]);

  return (
    <div className={styles.container}>
      <AppSelect
        disabled={disabled}
        className={cn(styles.select, {
          [styles.secondOptionOpened]: !isScoreSelected && value,
        })}
        onChange={onChange}
        options={elementOptions}
        value={value}
        label="Element"
      />
      {FirstAdditionalComponent}
      {SecondAdditionalComponent}
    </div>
  );
};
