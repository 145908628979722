import { ChangeEvent, useEffect, useState } from 'react';
import { ArrangeListType } from '../../../features/table/utils/constants';

type Props = Array<{ value: string; label: string }>;

export const useEnumArrange = (allOptions: Props = []) => {
  const [options, setOptions] = useState(allOptions);
  const onChangeSearchField = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value) {
      const regex = new RegExp(value, 'i');
      const filteredOptions = allOptions?.filter((item: { label: string }) =>
        item.label.match(regex),
      );
      setOptions(filteredOptions);
    } else {
      setOptions(allOptions);
    }
  };

  return {
    filtering: {
      options,
      setOptions,
      onChangeSearchField,
      type: ArrangeListType.ENUM,
    },
  };
};
