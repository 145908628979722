import { RuleConfiguratorHeader } from '../RuleConfiguratorHeader';
import { RuleConfiguratorBody } from '../RuleConfiguratorBody';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { RuleBuilderFormPayload, ConditionsArray } from '../../types';
import { nanoid } from '@reduxjs/toolkit';
import { MessageInstance } from 'antd/es/message/interface';
import {
  useCreateRuleMutation,
  useGetPublicDictionaryQuery,
  useGetRuleByIdQuery,
  useGetRuleTypesListQuery,
  useUpdateRuleMutation,
} from '../../../../lib/store/api/ruleBuilder';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import {
  setCurrentRule,
  setDictionaries,
} from '../../../../lib/store/slices/ruleBuilder';
import { FormItemName } from '../../../../lib/models/Form';
import { CreateRulePayloadT } from '../../../../lib/types/ruleBuilder';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLoader } from '../../../../components/PageLoader';
import { mapConditionAndAddId } from '../../helpers';
import { useGetElementOperatorOptions } from '../../hooks/useGetElementOperatorOptions';

const messages = 'pages.ruleBuilder.messages';

const initialQuery: ConditionsArray = [
  {
    conditionName: '1 Condition',
    rules: [],
    id: nanoid(),
  },
];

type Props = {
  messageApi: MessageInstance;
};

export const RuleConfigurator: FC<Props> = ({ messageApi }) => {
  const [form] = Form.useForm();
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { id: tenantId, modelId } = useParams();

  const { selectedRule } = useAppSelector(({ ruleBuilder }) => ruleBuilder);

  const { data: ruleData, isLoading: isRuleLoading } = useGetRuleByIdQuery(
    {
      ruleId: String(selectedRule?.id),
      tenantId: String(tenantId),
    },
    { skip: !selectedRule, refetchOnMountOrArgChange: true },
  );

  const { data, isLoading: isDictionaryLoading } = useGetPublicDictionaryQuery();
  const { data: ruleTypesList, isLoading: isRuleTypesListLoading } =
    useGetRuleTypesListQuery({
      page: 0,
      tenantId: String(tenantId),
      modelId: String(modelId),
    });
  const { isLoading: isOptionsLoading } = useGetElementOperatorOptions();

  const [createRule] = useCreateRuleMutation();
  const [updateRule] = useUpdateRuleMutation();

  const ruleTypeOptions =
    ruleTypesList?.content.map((item) => ({
      value: item.id,
      title: item.name,
    })) || [];

  const enableEditMode = () => {
    setIsEditModeEnabled(true);
  };

  const disableEditMode = () => {
    setIsEditModeEnabled(false);
  };
  const onFinish = async (values: RuleBuilderFormPayload) => {
    const rulePayload: CreateRulePayloadT = {
      name: values[FormItemName.RULE_NAME],
      ruleTypeId: values[FormItemName.RULE_TYPE].value,
      rule: query,
      projectId: Number(modelId),
      tenantId: String(tenantId),
      ruleId: selectedRule?.id || undefined,
      id: selectedRule?.id || undefined,
      activated: selectedRule?.activated,
    };

    try {
      if (selectedRule) {
        await updateRule(rulePayload).unwrap();
        messageApi.success(t(`${messages}.edit.success`));
      } else {
        const response = await createRule(rulePayload).unwrap();

        const modifiedResponse = {
          ...response,
          rule: mapConditionAndAddId(response.rule),
        };

        dispatch(setCurrentRule(modifiedResponse));

        messageApi.success(t(`${messages}.creation.success`));
      }

      disableEditMode();
    } catch (error) {
      if (selectedRule) {
        messageApi.error(t(`${messages}.edit.error`));
      } else {
        messageApi.error(t(`${messages}.creation.error`));
      }
    }
  };

  const onAddNewCondition = () => {
    setQuery((prevState) => [
      ...prevState,
      {
        conditionName: `${prevState.length + 1} Condition`,
        rules: [],
        id: nanoid(),
      },
    ]);
  };

  useEffect(() => {
    if (data) {
      dispatch(setDictionaries(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (ruleData) {
      const modifiedRules = mapConditionAndAddId(ruleData.rule);

      setQuery(modifiedRules);

      form.setFieldValue(FormItemName.RULE_NAME, ruleData.name);
      form.setFieldValue(FormItemName.RULE_TYPE, { value: ruleData.ruleTypeId });
    }
  }, [form, ruleData]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentRule(null));
    };
  }, [dispatch]);

  if (
    isRuleLoading ||
    isRuleTypesListLoading ||
    isDictionaryLoading ||
    isOptionsLoading
  ) {
    return (
      <div style={{ flex: 1 }}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', paddingBottom: 16 }}>
      <RuleConfiguratorHeader
        ruleTypeOptions={ruleTypeOptions}
        enableEditMode={enableEditMode}
        isEditModeEnabled={isEditModeEnabled}
        form={form}
        onFinish={onFinish}
        onAddNewCondition={onAddNewCondition}
      />
      <RuleConfiguratorBody
        query={query}
        setQuery={setQuery}
        isEditModeEnabled={isEditModeEnabled}
      />
    </div>
  );
};
