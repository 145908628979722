import { EndUserHouseModelMobile } from '../EndUserHouseModelMobile';
import { EndUserHouseModelDesktop } from '../EndUserHouseModelDesktop';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { ApplyCampaignBadgeT } from '../../../../lib/hooks/endUserHooks/useApplyCampaignHouseBadge';
import { FC } from 'react';

type Props = {
  applyCampaignHouseBadge: ApplyCampaignBadgeT;
};

export const EndUserHouseModel: FC<Props> = ({ applyCampaignHouseBadge }) => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const { isMobile } = useContainerQuery();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4) || [];

  return (
    <>
      {isMobile ? (
        <EndUserHouseModelMobile
          applyCampaignHouseBadge={applyCampaignHouseBadge}
          content={currentSectionContent}
          demandAreas={slicedDemandAreas}
        />
      ) : (
        <EndUserHouseModelDesktop
          applyCampaignHouseBadge={applyCampaignHouseBadge}
          content={currentSectionContent}
          demandAreas={slicedDemandAreas}
        />
      )}
    </>
  );
};
