import { FC } from 'react';
import { DemandArea } from '../../../../lib/types/endUser';
import { useAppSelector } from '../../../../lib/store/hooks';
import styles from './../../../../components/HouseDesktop/index.module.scss';
import { HouseDesktop } from '../../../../components/HouseDesktop';
import { NewHouseModelDesktop } from '../../../endUserHouseModelNew/components/NewHouseModelDesktop';

type Props = {
  demandAreas?: DemandArea[];
  buttonColor?: string;
  buttonText?: string;
};

export const EndUserHouse: FC<Props> = ({ demandAreas, buttonColor, buttonText }) => {
  const { demandArea, tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const showButton = demandArea?.tenant.name !== 'EnergieSchwaben' && buttonText;
  const applyNewHouse =
    (tenantAndProject?.domain === 'dev.hvm.impltech.online' &&
      tenantAndProject?.id === 11486) ||
    (tenantAndProject?.subDomain === 'energie-schwaben' &&
      tenantAndProject?.tenantId === 11002) ||
    (tenantAndProject?.domain === 'preprod.hausversorger.de' &&
      tenantAndProject?.tenantId === 11005);

  const handleCategoryClick = () => {
    //
  };

  const handleKeyDown = () => {
    //
  };

  const handleAreaClick = () => {
    //
  };

  return (
    <div style={{ width: 690 }} className={styles.mainContainer}>
      {applyNewHouse ? (
        <NewHouseModelDesktop
          demandAreas={demandAreas}
          buttonColor={buttonColor}
          buttonText={buttonText}
          showButton={!!showButton}
          handleCategoryClick={handleCategoryClick}
          handleKeyDown={handleKeyDown}
          handleAreaClick={handleAreaClick}
          fillAllCategories={true}
        />
      ) : (
        <HouseDesktop
          demandAreas={demandAreas}
          buttonColor={buttonColor}
          buttonText={buttonText}
          showButton={!!showButton}
          handleCategoryClick={handleCategoryClick}
          handleKeyDown={handleKeyDown}
          handleAreaClick={handleAreaClick}
          fillAllCategories={true}
        />
      )}
    </div>
  );
};
