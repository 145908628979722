import { useTranslation } from 'react-i18next';
import { Status, StatusType } from '../../../../features/table/components/Status';
import { CAMPAIGN_STATUSES } from '../../helpers/consts';

const activated = 'shared.status.activated';
const deactivated = 'shared.status.deactivated';
const upcoming = 'shared.status.upcoming';

type Props = {
  activatedStatus?: CAMPAIGN_STATUSES;
};

export function CampaignStatusBodyCell({ activatedStatus }: Props) {
  const { t } = useTranslation();

  switch (activatedStatus) {
    case CAMPAIGN_STATUSES.ACTIVE:
      return <Status textValue={t(activated)} type={StatusType.GREEN} />;
    case CAMPAIGN_STATUSES.DEACTIVATED:
      return <Status textValue={t(deactivated)} type={StatusType.GRAY} />;
    case CAMPAIGN_STATUSES.UPCOMING:
      return <Status textValue={t(upcoming)} type={StatusType.ORANGE} />;

    default:
      return null;
  }
}
