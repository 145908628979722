import { useGetActiveCampaignsQuery } from '../../store/api/endUser';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';
import { setActiveCampaign } from '../../store/slices/endUserSlice';

export const useEndUserGetActiveCampaigns = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const tenantId = tenantAndProject?.tenantId;
  const modelId = tenantAndProject?.projectId;

  const { data: activeCampaignsData } = useGetActiveCampaignsQuery(
    {
      tenantId: String(tenantId),
      projectId: Number(modelId),
    },
    {
      skip: isGuestMode,
    },
  );

  useEffect(() => {
    if (activeCampaignsData) {
      dispatch(setActiveCampaign(activeCampaignsData?.[0] || null));
    }
  }, [activeCampaignsData, dispatch]);
};
