import React, { FC, useCallback, useMemo } from 'react';
import { FoundElementT } from '../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';

import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark-white.svg';

import styles from './index.module.scss';
import cn from 'classnames';
import { useFindCampaignElementForUserMutation } from '../../lib/store/api/endUser';
import { useAppSelector } from '../../lib/store/hooks';

import confetti from 'canvas-confetti';

type Props = {
  campaignObject: FoundElementT;
  imageClassName?: string;
  containerClassName?: string;
  completedIconClassName?: string;
};
export const CampaignObjectImage: FC<Props> = ({
  campaignObject,
  imageClassName,
  containerClassName,
  completedIconClassName,
}) => {
  const { s3Url, uuid } = campaignObject;

  const { activeCampaign, tenantAndProject, completedCampaignElements } = useAppSelector(
    ({ endUser }) => endUser,
  );

  const [findCampaignElement] = useFindCampaignElementForUserMutation();

  const isCompleted = useMemo(
    () => completedCampaignElements?.find((el) => el.uuid === uuid),
    [completedCampaignElements, uuid],
  );

  const handleClickObject = useCallback(
    async (e: React.MouseEvent<HTMLImageElement>) => {
      e.stopPropagation();

      if (isCompleted) return;

      try {
        findCampaignElement({
          campaignId: Number(activeCampaign?.id),
          tenantId: String(tenantAndProject?.tenantId),
          uuid: uuid,
        }).unwrap();

        confetti({
          particleCount: 250,
          spread: 150,
          origin: { y: 0.6 },
          zIndex: 999999,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [
      activeCampaign?.id,
      findCampaignElement,
      isCompleted,
      tenantAndProject?.tenantId,
      uuid,
    ],
  );

  const CompletedIcon = useMemo(() => {
    return (
      <div
        className={cn(
          styles.completedContainer,
          !isCompleted && styles.isNotCompleted,
          completedIconClassName,
        )}
      >
        <CheckmarkIcon />
      </div>
    );
  }, [completedIconClassName, isCompleted]);

  return (
    <span
      className={cn(styles.container, containerClassName)}
      role="presentation"
      onClick={handleClickObject}
    >
      {CompletedIcon}
      <img className={cn(styles.img, imageClassName)} src={s3Url} alt="campaign object" />
    </span>
  );
};
