import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { FormItem } from '../../../../../../components/FormItem';
import { FormItemName } from '../../../../../../lib/models/Form';
import { Form, FormInstance, Switch, Typography } from 'antd';

import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import { FormSection } from '../../../../../../components/FormSection';
import { CampaignButtonColorCard } from '../../../CampaignButtonColorCard';
import { CampaignTeaserButton } from '../CampaignTeaserButton';
import { PictureFormItem } from '../../../../../productConfigurator/components/PictureFormItem';
import { GradientFormPicker } from '../../../GradientFormPicker';

const { Text } = Typography;

const translationTitle = 'pages.campaigns.campaignDetails.titles';
const translationLabels = 'pages.campaigns.campaignDetails.form.labels';

type Props = {
  form: FormInstance;
};

export const CampaignTeaserBox: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const switchState = Form.useWatch(
    [FormItemName.CAMPAIGN_GENERAL_DETAILS, FormItemName.CAMPAIGN_TEASER_ENABLED],
    form,
  );
  const isDisabled = !switchState;

  return (
    <div>
      <div className={styles.switchContainer}>
        <FormItem
          hasFeedback
          name={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_ENABLED,
          ]}
          valuePropName="checked"
          initialValue={false}
          className={styles.switchFormItem}
        >
          <Switch size="small" />
        </FormItem>
        <Text className={styles.title}>{t(`${translationTitle}.teaser`)}</Text>
      </div>

      <AppCardBasic className={styles.card}>
        <Text className={styles.subTitle}>{t(`${translationTitle}.leftSide`)}</Text>

        <div>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: [
                FormItemName.CAMPAIGN_GENERAL_DETAILS,
                FormItemName.CAMPAIGN_TEASER_HEADLINE,
              ],
              rules: [{ required: !isDisabled }],
            }}
            textFieldProps={{
              label: t(`${translationLabels}.headline`),
              disabled: isDisabled,
            }}
          />
        </div>
        <div>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: [
                FormItemName.CAMPAIGN_GENERAL_DETAILS,
                FormItemName.CAMPAIGN_TEASER_SUBLINE,
              ],
              rules: [{ required: !isDisabled }],
            }}
            textFieldProps={{
              label: t(`${translationLabels}.subline`),
              disabled: isDisabled,
            }}
          />
        </div>

        <div className={styles.row}>
          <GradientFormPicker
            form={form}
            label={t(`${translationLabels}.backgroundColor`)}
            formName={[
              FormItemName.CAMPAIGN_GENERAL_DETAILS,
              FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR,
            ]}
          />

          <CampaignButtonColorCard
            formName={[
              FormItemName.CAMPAIGN_GENERAL_DETAILS,
              FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR,
            ]}
            label={t(`${translationLabels}.headlineSublineColor`)}
            disabled={isDisabled}
          />
        </div>

        <CampaignTeaserButton
          isTeaserDisabled={isDisabled}
          switchFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_ENABLED,
          ]}
          buttonTextFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT,
          ]}
          buttonOperationFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_OPERATION,
          ]}
          buttonDestinationFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_DESTINATION,
          ]}
          buttonTextColorFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR,
          ]}
          buttonBackgroundFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR,
          ]}
          form={form}
          switchText={t(`${translationLabels}.primaryButton`)}
        />

        <CampaignTeaserButton
          isTeaserDisabled={isDisabled}
          switchFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_ENABLED,
          ]}
          buttonTextFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT,
          ]}
          buttonOperationFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_OPERATION,
          ]}
          buttonDestinationFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_DESTINATION,
          ]}
          buttonTextColorFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR,
          ]}
          buttonBackgroundFormName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR,
          ]}
          form={form}
          switchText={t(`${translationLabels}.secondaryButton`)}
        />

        <div className={styles.rightSideImageContainer}>
          <Text className={styles.subTitle}>{t(`${translationTitle}.rightSide`)}</Text>
          <PictureFormItem
            disabled={isDisabled}
            form={form}
            formName={[
              FormItemName.CAMPAIGN_GENERAL_DETAILS,
              FormItemName.CAMPAIGN_TEASER_RIGHT_SIDE_IMAGE,
            ]}
          />
        </div>
      </AppCardBasic>
    </div>
  );
};
