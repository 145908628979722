import React, { FC, useState } from 'react';
import { Header as AntHeader } from 'antd/es/layout/layout';
import { HeaderConfigT } from '../../../../modules/designSettings/types';
import styles from './index.module.scss';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import cx from 'classnames';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Avatar, Button, Collapse, Drawer, Tooltip } from 'antd';
import { ReactComponent as IconBurger } from '../../../../assets/icons/list.svg';
import { ReactComponent as IconCross } from '../../../../assets/icons/CrossIcon.svg';
import { getCoveredCategories } from '../../../../lib/utils/end-user-helpers';
import { ButtonLink } from '../../../../components/ButtonLink';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import {
  setGuestMode,
  setMatomoTrackingForGuest,
} from '../../../../lib/store/slices/endUserSlice';
import {
  openLinkAndTrack,
  sendDataToAnalytics,
} from '../../../../lib/utils/sendDataToAnalytics';
import { IconComponent } from '../../../../components/IconComponent';
import { appLogout, parseJSON } from '../../../../lib/utils/helpers';
import { ReactComponent as PersonIcon } from '../../../../assets/icons/person.svg';
import { EndUserDeleteProfileModal } from '../../../../components/EndUserDeleteProfileModal';
import { appDayJs } from '../../../../lib/models/Time';
import { useTranslation } from 'react-i18next';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { useIsEnergieScwabenTenant } from '../../../../lib/hooks/endUserHooks/useIsEnergieScwabenTenant';
import { useHardcodedRedirectToCategory } from '../../../../lib/hooks/endUserHooks/useHardcodedRedirectToCategory';
import { useGetCurrentStatusLevel } from '../../../../lib/hooks/endUserHooks/useGetCurrentStatusLevel';
import { IconButton } from '../../../../components/IconButton';
import { useIsEviTenant } from '../../../../lib/hooks/endUserHooks/useIsEviTenant';

import { ReactComponent as QuestionIcon } from '../../../../assets/icons/question.svg';
import { useGetTenantsFaq } from '../../../../lib/hooks/useGetTenantsFaq';
import { EndUserCampaignCounter } from '../../../../components/EndUserCampaignCounter';

const translation = 'endUser.shared';
const reportDateText = 'endUser.shared.reportDate';

type Props = {
  headerConfig: HeaderConfigT;
};

export const MobileTabletHeader: FC<Props> = ({ headerConfig }) => {
  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isMobile } = useContainerQuery();
  const dispatch = useAppDispatch();
  const {
    demandArea: endUserData,
    isGuestMode,
    brandKit,
    wishlistProducts,
  } = useAppSelector(({ endUser }) => endUser);

  const { currentStatusLevel } = useGetCurrentStatusLevel();

  const isFrenchTenant = endUserData?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;
  const isEviTenant = useIsEviTenant();

  const isHouseModelPath = useMatch(
    `${isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel}/:id`,
  );

  const { role, user } = useAppSelector(({ auth }) => auth);

  const reportDate = endUserData?.project?.reportTime;

  const { isBackgroundShadow, backgroundColor, logo, navigation } = headerConfig;
  const { isEnergieSchwaben } = useIsEnergieScwabenTenant();
  const { hardcodedRedirect } = useHardcodedRedirectToCategory();
  const { energieScwabenFaqLink, eviFaqLink } = useGetTenantsFaq();

  const handleCloseDrawer = (e: any) => {
    e?.stopPropagation();
    setDrawerOpened(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpened(true);
  };

  const handleFaqClick = () => {
    let link;
    if (isEviTenant) {
      link = eviFaqLink;
    } else if (isEnergieSchwaben) {
      link = energieScwabenFaqLink;
    }

    openLinkAndTrack(link);
  };

  const coveredCategories = endUserData?.listDemandArea?.reduce((acc, item) => {
    const coveredCategoriesLength = getCoveredCategories(item?.categories).length;

    return {
      ...acc,
      [item.name]: coveredCategoriesLength,
    };
  }, {});

  const handleLogoutClick = () => {
    if (isGuestMode) {
      sendDataToAnalytics('Logout', 'Guest');
      dispatch(setMatomoTrackingForGuest({ trackingEnabled: false }));
      dispatch(setGuestMode(false));

      appLogout(dispatch);
    } else {
      sendDataToAnalytics('Logout', 'User');

      appLogout(dispatch);
    }

    if (window._paq) {
      window._paq.push(['forgetConsentGiven']);
    }
  };

  const onClickHome = (e: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User kehrt zu Hausmodell zurück');

    handleCloseDrawer(e);
  };

  const onClickBonuses = (e: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User ruft Bonusübersicht auf');

    handleCloseDrawer(e);
  };

  const onClickWishlist = (e: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User ruft Merkliste auf');

    handleCloseDrawer(e);
  };

  const onClickPointsOverview = (e: React.MouseEvent<HTMLAnchorElement>) => {
    sendDataToAnalytics(
      'Navigation',
      'Seitenwechsel',
      'User ruft persönliches Profil auf',
    );

    handleCloseDrawer(e);
  };

  const onClickDemandArea = (
    e: React.MouseEvent<HTMLAnchorElement>,
    demandArea: string,
  ) => {
    sendDataToAnalytics('Navigation', 'DemandArea', demandArea);

    if (demandArea === 'Energie' && isEnergieSchwaben) {
      hardcodedRedirect();
    }

    handleCloseDrawer(e);
  };

  const onClickLogo = () => {
    sendDataToAnalytics('Navigation', 'Wechsel zur Website', 'User wechselt zur Website');

    navigate('/home');
  };

  const isPreviewMode = role !== 'ROLE_END_USER';
  const toggleDeleteModal = () => setIsOpenDeleteModal(!isOpenDeleteModal);

  const renderFooter = (
    <div className={styles.user}>
      <div className={styles.user_info}>
        <div className={styles.profileWrapper}>
          <Avatar
            className={styles.user_avatar}
            style={{ backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
            size={40}
          >
            <PersonIcon fill="#fff" />
          </Avatar>
          {(isEnergieSchwaben || isEviTenant) && (
            <Tooltip
              color="#191919"
              title="FAQ"
              placement="bottom"
              overlayInnerStyle={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px 16px',
                fontSize: 16,
              }}
            >
              <span className={styles.container}>
                <IconButton
                  onClick={handleFaqClick}
                  className={styles.faqButton}
                  style={
                    {
                      '--hoverColor': brandKit?.colors[ColorVariants.PRIMARY_COLOR],
                    } as any
                  }
                  icon={<QuestionIcon />}
                />
              </span>
            </Tooltip>
          )}
        </div>

        <div className={styles.profile_user_info}>
          <EndUserTypography
            component="Text"
            type="headlineSixth"
            style={{ margin: 0, flex: 1 }}
          >
            {user?.email}
          </EndUserTypography>
        </div>
      </div>
      <ButtonLink onClick={toggleDeleteModal}>
        <EndUserTypography component="Text" type="headlineSeventh">
          {t(`${translation}.removeAccount`)}
        </EndUserTypography>
      </ButtonLink>
    </div>
  );

  return (
    <>
      {isOpenDeleteModal && (
        <EndUserDeleteProfileModal
          close={toggleDeleteModal}
          isModalOpen={isOpenDeleteModal}
        />
      )}
      <AntHeader
        className={cx(styles.header, {
          [styles.withShadow]: isBackgroundShadow,
          [styles.headerVisible]: isDrawerOpened,
        })}
        style={{ backgroundColor: brandKit?.colors[backgroundColor] }}
      >
        <div className={styles.header_wrap}>
          <div
            onClick={onClickLogo}
            role="presentation"
            className={styles.header_logo_wrap}
          >
            <img src={logo?.s3Url} alt="logo" className={styles.header_logo_image} />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EndUserCampaignCounter />

            <Button
              icon={<IconBurger />}
              size="large"
              className={styles.burgerBtn}
              style={{ backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              onClick={isDrawerOpened ? handleCloseDrawer : handleOpenDrawer}
            />
          </div>

          <Drawer
            width={isMobile ? 320 : 400}
            open={isDrawerOpened}
            rootClassName={cx(
              styles.drawer,
              isPreviewMode ? styles.drawerForPreview : styles.drawerForEndUser,
            )}
            onClose={handleCloseDrawer}
            getContainer={'#container'}
            footer={renderFooter}
          >
            <IconCross
              className={styles.crossIcon}
              style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              onClick={handleCloseDrawer}
            />
            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.homeFr : ROUTER_PATHS.home}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              onClick={onClickHome}
            >
              <span
                className={styles.activeIcon}
                style={{ backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              />
              <EndUserTypography type="headlineFifth" component="Text">
                {navigation?.landingPage}
              </EndUserTypography>
            </NavLink>
            <Collapse
              ghost
              defaultActiveKey={['1']}
              expandIconPosition="end"
              className={styles.collapse}
              items={[
                {
                  key: '1',
                  label: (
                    <EndUserTypography type="headlineFifth" component="Text">
                      {isHouseModelPath && (
                        <span
                          className={styles.activeIcon}
                          style={{
                            backgroundColor:
                              brandKit?.colors[ColorVariants.PRIMARY_COLOR],
                          }}
                        />
                      )}

                      {navigation?.demandAreas}
                    </EndUserTypography>
                  ),
                  children: (
                    <>
                      {endUserData?.listDemandArea?.map(
                        ({ id, name, color, categories, icon }) => {
                          const demandAreaColor = parseJSON(color);

                          return (
                            <NavLink
                              key={id}
                              to={`${isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel}/${id}`}
                              className={styles.link}
                              onClick={(e) => onClickDemandArea(e, name)}
                            >
                              <span
                                className={styles.demandAreaIcon}
                                style={{
                                  background: `${demandAreaColor?.value}`,
                                }}
                              >
                                <IconComponent
                                  className={styles.demandAreaIcon_content}
                                  iconData={icon}
                                  color="#fff"
                                />
                              </span>
                              <EndUserTypography type="headlineSeventh" component="Text">
                                {name}
                              </EndUserTypography>

                              <EndUserTypography
                                type="headlineEight"
                                component="Text"
                                className={styles.categories}
                              >
                                {
                                  coveredCategories?.[
                                    name as keyof typeof coveredCategories
                                  ]
                                }{' '}
                                / {categories.length}
                              </EndUserTypography>
                            </NavLink>
                          );
                        },
                      )}
                    </>
                  ),
                },
              ]}
            />
            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.bonusesFr : ROUTER_PATHS.bonuses}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              onClick={onClickBonuses}
            >
              <span
                className={styles.activeIcon}
                style={{ backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              />
              <EndUserTypography type="headlineFifth" component="Text">
                {navigation?.bonuses}
              </EndUserTypography>
            </NavLink>
            {!isGuestMode && (
              <NavLink
                to={ROUTER_PATHS.pointsOverview}
                className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
                onClick={onClickPointsOverview}
              >
                <span
                  className={styles.activeIcon}
                  style={{
                    backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR],
                  }}
                />
                <EndUserTypography type="headlineFifth" component="Text">
                  {navigation?.pointOverview}
                  <span style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}>
                    {' '}
                    {currentStatusLevel?.name}
                  </span>
                </EndUserTypography>
              </NavLink>
            )}

            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.wishlistFr : ROUTER_PATHS.wishlist}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              onClick={onClickWishlist}
            >
              <span
                className={styles.activeIcon}
                style={{ backgroundColor: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              />
              <EndUserTypography type="headlineFifth" component="Text">
                {navigation?.wishlist}{' '}
                {wishlistProducts?.length ? `(${wishlistProducts?.length})` : null}
              </EndUserTypography>
            </NavLink>
            <ButtonLink onClick={handleLogoutClick} className={styles.link}>
              <EndUserTypography type="headlineFifth" component="Text">
                {isGuestMode ? t(`${translation}.login`) : t(`${translation}.logout`)}
              </EndUserTypography>
            </ButtonLink>
            <div className={styles.lastUpdateRow}>
              <span className={styles.lastUpdate}>
                {t(reportDateText)} {appDayJs(reportDate).utc().format('DD.MM.YYYY')}
              </span>
            </div>
          </Drawer>
        </div>
      </AntHeader>
    </>
  );
};
