import { FC } from 'react';

import styles from './index.module.scss';
import { CampaignTeaserSettings } from '../../../../lib/hooks/endUserHooks/useApplyCampaignTeaser';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserButton } from '../../../../components/EndUserButton';
import {
  openLinkAndTrack,
  sendDataToAnalytics,
} from '../../../../lib/utils/sendDataToAnalytics';
import { CAMPAIGN_SETTINGS_OPERATIONS } from '../../../campaignDetails/types/types';
import {
  setGuestMode,
  setMatomoTrackingForGuest,
} from '../../../../lib/store/slices/endUserSlice';
import { appLogout } from '../../../../lib/utils/helpers';
import { useAppDispatch } from '../../../../lib/store/hooks';

type Props = {
  campaignTeaserSettings: CampaignTeaserSettings | null;
  isGuestMode: boolean;
};

export const CampaignTeaser: FC<Props> = ({ campaignTeaserSettings, isGuestMode }) => {
  const dispatch = useAppDispatch();

  if (!campaignTeaserSettings) {
    return null;
  }

  const {
    campaignTeaserBackgroundColor = '',
    campaignTeaserRightSideImage = '',
    campaignTeaserHeadline = '',
    campaignTeaserHeadlineSubLineColor,
    campaignTeaserSubLine,
    campaignTeaserEnabled,
    campaignTeaserPrimaryButtonText,
    campaignTeaserPrimaryButtonEnabled,
    campaignTeaserPrimaryButtonDestination,
    campaignTeaserPrimaryButtonOperation,
    campaignTeaserPrimaryButtonTextColor,
    campaignTeaserPrimaryButtonBackgroundColor,

    campaignTeaserSecondaryButtonBackgroundColor,
    campaignTeaserSecondaryButtonDestination,
    campaignTeaserSecondaryButtonEnabled,
    campaignTeaserSecondaryButtonOperation,
    campaignTeaserSecondaryButtonText,
    campaignTeaserSecondaryButtonTextColor,
  } = campaignTeaserSettings;

  const handleRedirectToLogin = () => {
    dispatch(setGuestMode(false));
    dispatch(setMatomoTrackingForGuest({ trackingEnabled: false }));
    appLogout(dispatch);

    if (window._paq) {
      window._paq.push(['forgetConsentGiven']);
    }
  };

  const handlePrimaryButtonClick = () => {
    if (isGuestMode) {
      handleRedirectToLogin();
      return;
    }

    if (
      campaignTeaserPrimaryButtonOperation === CAMPAIGN_SETTINGS_OPERATIONS.LINK_TO &&
      campaignTeaserPrimaryButtonDestination
    ) {
      openLinkAndTrack(campaignTeaserPrimaryButtonDestination);
    }
  };

  const handleSecondaryButtonClick = () => {
    if (isGuestMode) {
      handleRedirectToLogin();
      return;
    }

    if (
      campaignTeaserSecondaryButtonOperation === CAMPAIGN_SETTINGS_OPERATIONS.LINK_TO &&
      campaignTeaserSecondaryButtonDestination
    ) {
      openLinkAndTrack(campaignTeaserSecondaryButtonDestination);
    }
  };

  return campaignTeaserEnabled ? (
    <div id="teaser" className={styles.container}>
      <div
        className={styles.leftContainer}
        style={{ background: campaignTeaserBackgroundColor }}
      >
        <EndUserTypography
          type="headlineSecond"
          className={styles.headlineOverride}
          style={{ color: campaignTeaserHeadlineSubLineColor }}
        >
          {campaignTeaserHeadline}
        </EndUserTypography>

        <EndUserTypography
          type="headlineSecond"
          className={styles.sublineOverride}
          style={{ color: campaignTeaserHeadlineSubLineColor }}
        >
          {campaignTeaserSubLine}
        </EndUserTypography>

        <div className={styles.buttonsRow}>
          {campaignTeaserPrimaryButtonEnabled && (
            <EndUserButton
              onClick={handlePrimaryButtonClick}
              type="primary"
              additionalStyles={{
                color: campaignTeaserPrimaryButtonTextColor,
                background: campaignTeaserPrimaryButtonBackgroundColor,
              }}
              dynamicBackgroundColor={campaignTeaserPrimaryButtonBackgroundColor}
            >
              {campaignTeaserPrimaryButtonText}
            </EndUserButton>
          )}
          {campaignTeaserSecondaryButtonEnabled && (
            <EndUserButton
              onClick={handleSecondaryButtonClick}
              type="secondary"
              additionalStyles={{
                color: campaignTeaserSecondaryButtonTextColor,
                background: campaignTeaserSecondaryButtonBackgroundColor,
              }}
              dynamicBackgroundColor={campaignTeaserSecondaryButtonBackgroundColor}
            >
              {campaignTeaserSecondaryButtonText}
            </EndUserButton>
          )}
        </div>
      </div>

      <div
        className={styles.rightContainer}
        style={{ backgroundImage: `url(${campaignTeaserRightSideImage})` }}
      />
    </div>
  ) : null;
};
