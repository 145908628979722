import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CMS_TENANT_GLOBAL_ROUTER_PATHS,
  CMS_TENANT_SECTION_ROUTES,
  ROUTER_PATHS,
} from '../../../../lib/utils/router-paths';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SquareIcon } from '../../../../assets/icons/sidebar-square.svg';
import { ReactComponent as DesignIcon } from '../../../../assets/icons/sidebar-design.svg';
import { ReactComponent as GeneralIcon } from '../../../../assets/icons/sidebar-general.svg';
import { ReactComponent as ProductsIcon } from '../../../../assets/icons/sidebar-products.svg';
import { ReactComponent as RuleBuilderIcon } from '../../../../assets/icons/sidebar-rule-builder.svg';
import { ReactComponent as StatusIcon } from '../../../../assets/icons/sidebar-status.svg';
import { ReactComponent as MegaphoneIcon } from '../../../../assets/icons/megaphone.svg';

import { MenuProps } from 'antd';
import { useAppSelector } from '../../../../lib/store/hooks';
import { checkIsUser } from '../../../../lib/utils/roles';
import { Tabs } from '../../../../lib/types/queryParams';

const sidebar = 'platform.tenantSidebar';
const tenantSidebarLabels = 'platform.tenantSidebarLabels';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const useTenantMenuOptions = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id, modelId } = useParams();
  const { role } = useAppSelector(({ auth }) => auth);
  const isUser = checkIsUser(role);

  const navigate = useNavigate();

  const getFullLink = (path: string) => {
    return `tenants/${id}/models/${modelId}/${path}`;
  };

  const isTenantRoutesAvailable = useMemo(() => {
    const globalRoutes = Object.values(CMS_TENANT_GLOBAL_ROUTER_PATHS);
    const sectionRoutes = Object.values(CMS_TENANT_SECTION_ROUTES);

    return [...globalRoutes, ...sectionRoutes].some((route) => {
      const currentLocation = location.pathname.split('/').slice(-1).join('');

      return currentLocation === route;
    });
  }, [location]);

  const globalItemsWithoutAdminsRoutes = [
    {
      label: (
        <Link
          onClick={(event) => {
            event.preventDefault();

            navigate(getFullLink(ROUTER_PATHS.designSettings) + `?tab=${Tabs.BRAND_KIT}`);
          }}
          to={getFullLink(ROUTER_PATHS.designSettings)}
        >
          {t(`${sidebar}.designSettings`)}
        </Link>
      ),
      icon: <DesignIcon />,
      key: ROUTER_PATHS.designSettings,
    },
    {
      label: (
        <Link
          onClick={(event) => {
            event.preventDefault();

            navigate(getFullLink(ROUTER_PATHS.products) + `?tab=${Tabs.DEMAND_AREAS}`);
          }}
          to={getFullLink(ROUTER_PATHS.products)}
        >
          {t(`${sidebar}.products`)}
        </Link>
      ),
      icon: <ProductsIcon />,
      key: ROUTER_PATHS.products,
    },
    {
      label: (
        <Link
          onClick={(event) => {
            event.preventDefault();

            navigate(getFullLink(ROUTER_PATHS.statusLevel) + `?tab=${Tabs.STATUS_LEVEL}`);
          }}
          to={getFullLink(ROUTER_PATHS.statusLevel)}
        >
          {t(`${sidebar}.statusLevel`)}
        </Link>
      ),

      icon: <StatusIcon />,
      key: ROUTER_PATHS.statusLevel,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.ruleBuilder) + `?tab=${Tabs.RULES}`}>
          {t(`${sidebar}.ruleBuilder`)}
        </Link>
      ),
      icon: <RuleBuilderIcon />,
      key: ROUTER_PATHS.ruleBuilder,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.campaigns)}>{t(`${sidebar}.campaigns`)}</Link>
      ),
      icon: <MegaphoneIcon width={16} height={16} />,
      key: ROUTER_PATHS.campaigns,
    },
  ];

  const globalItems = [
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.generalSettings)}>
          {t(`${sidebar}.generalSettings`)}
        </Link>
      ),
      icon: <GeneralIcon />,
      key: ROUTER_PATHS.generalSettings,
    },
    ...globalItemsWithoutAdminsRoutes,
  ];

  const sectionItems = [
    {
      label: <Link to={getFullLink(ROUTER_PATHS.header)}>{t(`${sidebar}.header`)}</Link>,
      icon: <SquareIcon />,
      key: ROUTER_PATHS.header,
    },
    {
      label: <Link to={getFullLink(ROUTER_PATHS.footer)}>{t(`${sidebar}.footer`)}</Link>,
      icon: <SquareIcon />,
      key: ROUTER_PATHS.footer,
    },
    {
      label: <Link to={getFullLink(ROUTER_PATHS.model)}>{t(`${sidebar}.model`)}</Link>,
      icon: <SquareIcon />,
      key: ROUTER_PATHS.model,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.productCategories)}>
          {t(`${sidebar}.productCategories`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.productCategories,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.productDetails)}>
          {t(`${sidebar}.productDetails`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.productDetails,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.statusBar)}>{t(`${sidebar}.statusBar`)}</Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.statusBar,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.statusOffers)}>
          {t(`${sidebar}.statusOffers`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.statusOffers,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.companyBenefits)}>
          {t(`${sidebar}.companyBenefits`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.companyBenefits,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.regionalOffers)}>
          {t(`${sidebar}.regionalOffers`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.regionalOffers,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.productRecommended)}>
          {t(`${sidebar}.productRecommend`)}
        </Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.productRecommended,
    },
    {
      label: (
        <Link to={getFullLink(ROUTER_PATHS.friends)}>{t(`${sidebar}.friends`)}</Link>
      ),
      icon: <SquareIcon />,
      key: ROUTER_PATHS.friends,
    },
  ];

  const filteredGlobalItems = isUser ? globalItemsWithoutAdminsRoutes : globalItems;

  const items: MenuProps['items'] = [
    getItem(t(`${tenantSidebarLabels}.global`), 'g1', null, filteredGlobalItems, 'group'),
    // getItem(t(tenantSidebarLabels.section), 'g2', null, sectionItems, 'group'),
  ];

  const linkPathExtractor = (
    array: {
      label: JSX.Element | string;
      icon: JSX.Element;
      key: ROUTER_PATHS;
    }[],
  ) => {
    return array
      .filter((item) => location.pathname.split('/').slice(-1).includes(item.key))
      .map((linkObject) => linkObject.key);
  };

  const selectedItems = linkPathExtractor([...globalItems, ...sectionItems]);

  return { isTenantRoutesAvailable, items, selectedItems };
};
