import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import {
  CampaignObjectItem,
  CampaignT,
  CreateCampaignPayloadT,
  GetCampaignObjectsListPayload,
  GetCampaignRequest,
  GetCampaignRulesResponse,
  GetCampaignsPayloadT,
  SaveCampaignObjectsPayload,
  UpdateCampaignPayloadT,
} from '../../types/campaigns';

export const campaignsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCampaigns: build.query<ResultWithPagination<CampaignT[]>, GetCampaignsPayloadT>({
      query: ({
        page,
        filterFieldName,
        sortFieldName,
        filter,
        projectId,
        sortOrder,
        tenantId,
        size = 10,
      }) => {
        const url = `campaign/${projectId}/get-campaigns?size=${size}&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`;

        return {
          url: url,
          method: 'POST',
          body: Array.isArray(filter)
            ? {
                [filterFieldName as string]: filter,
              }
            : filter,
          headers: {
            'X-TENANT': tenantId,
          },
        };
      },
      providesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    getCampaignsForFiltering: build.query<
      ResultWithPagination<CampaignT[]>,
      { searchFieldName: string; search: string; tenantId: string; projectId: string }
    >({
      query: ({ searchFieldName, search, tenantId, projectId }) => ({
        url: `campaign/${projectId}/get-campaigns?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName]: search,
        },
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      providesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    createCampaign: build.mutation<{ id: number }, CreateCampaignPayloadT>({
      query: (body) => ({
        url: `campaign`,
        method: 'POST',
        headers: {
          'X-TENANT': body.tenantId,
        },
        body,
      }),
      invalidatesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    deleteCampaign: build.mutation<void, { campaignId: number; tenantId: string }>({
      query: ({ campaignId, tenantId }) => ({
        url: `campaign/delete/${campaignId}`,
        method: 'DELETE',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    updateCampaign: build.mutation<void, UpdateCampaignPayloadT>({
      query: (body) => ({
        url: `campaign`,
        method: 'PUT',
        body,
        headers: {
          'X-TENANT': body.tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.CAMPAIGNS],
    }),
    getObjectsList: build.query<CampaignObjectItem[], GetCampaignObjectsListPayload>({
      query: ({ tenantId, campaignId }) => ({
        url: `campaign/${campaignId}/get-elements`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
    }),
    saveObjects: build.mutation<
      void,
      {
        body: SaveCampaignObjectsPayload;
        params: { tenantId: string; campaignId: number };
      }
    >({
      query: ({ body, params }) => ({
        url: `campaign/${params.campaignId}/save-elements`,
        method: 'POST',
        body,
        headers: {
          'X-TENANT': params.tenantId,
        },
      }),
    }),
    getCampaignRules: build.query<GetCampaignRulesResponse, GetCampaignRequest>({
      query: ({ tenantId, campaignId }) => ({
        url: `campaign/${campaignId}/get-rules`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useLazyGetCampaignsForFilteringQuery,
  useCreateCampaignMutation,
  useDeleteCampaignMutation,
  useUpdateCampaignMutation,
  useGetObjectsListQuery,
  useSaveObjectsMutation,
  useGetCampaignRulesQuery,
} = campaignsApi;
