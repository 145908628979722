import { CAMPAIGN_STATUSES } from '../../campaignTable/helpers/consts';
import { UploadResponseT } from '../../../lib/types/entities';
import { FormItemName } from '../../../lib/models/Form';

export enum CAMPAIGN_SETTINGS_OPERATIONS {
  LINK_TO = 'LinkTo',
}

export type CampaignGeneralSettingsT = {
  generalDetails: CampaignGeneralDetailsBoxT;
  badge: CampaignBadgeBoxT;
  teaser: CampaignTeaserBoxT;
};

export type CampaignGeneralDetailsBoxT = {
  name: string;
  campaignType: string;
  status: CAMPAIGN_STATUSES;
  startDate: string;
  endDate: string;
  description: string;
};

export type CampaignBadgeBoxT = {
  isBadgeEnabled: boolean;
  image: UploadResponseT;
  operation: CAMPAIGN_SETTINGS_OPERATIONS;
  destination: string;
};

export type CampaignTeaserBoxT = {
  enabled: boolean;
  headline: string;
  subLine: string;
  backgroundColor: string;
  headerSubLineColor: string;
  primaryButton: CampaignTeaserButton;
  secondaryButton: CampaignTeaserButton;
  rightImage: UploadResponseT;
};

export type Image = {
  s3Url: string;
  originalFileName: string;
  cdnUrl: string;
  id: number;
  targetId: number;
  targetType: string;
};

export type CampaignTeaserButton = {
  enabled: boolean;
  buttonText: string;
  operation: CAMPAIGN_SETTINGS_OPERATIONS;
  destination: string;
};

export type CampaignFromValuesT = {
  [FormItemName.CAMPAIGN_GENERAL_DETAILS]: {
    [FormItemName.CAMPAIGN_NAME]: string;
    [FormItemName.CAMPAIGN_TYPE]: string;
    [FormItemName.CAMPAIGN_STATUS]: {
      value: string;
      title: string;
    };
    [FormItemName.CAMPAIGN_START_DATE]: string;
    [FormItemName.CAMPAIGN_END_DATE]: string;
    [FormItemName.CAMPAIGN_DESCRIPTION]: string;

    //badge

    [FormItemName.CAMPAIGN_BADGE_ENABLED]: boolean;
    [FormItemName.CAMPAIGN_BADGE_IMAGE]: Image;
    [FormItemName.CAMPAIGN_BADGE_OPERATION]: {
      title: string;
      value: string;
    };
    [FormItemName.CAMPAIGN_BADGE_DESTINATION]: {
      title: string;
      value: string;
    };

    // teaser

    [FormItemName.CAMPAIGN_TEASER_ENABLED]: boolean;
    [FormItemName.CAMPAIGN_TEASER_HEADLINE]: string;
    [FormItemName.CAMPAIGN_TEASER_SUBLINE]: string;
    [FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR]: {
      value: string;
    };
    [FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR]: {
      value: string;
    };

    // teaserButton primary

    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_ENABLED]: boolean;
    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT]: string;
    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_OPERATION]: {
      value: string;
      title: string;
    };
    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_DESTINATION]: string;
    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR]: {
      value: string;
    };
    [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR]: {
      value: string;
    };

    // teaserButton secondary

    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_ENABLED]: boolean;
    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT]: string;
    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_OPERATION]: {
      value: string;
      title: string;
    };
    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_DESTINATION]: string;
    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR]: {
      value: string;
    };
    [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR]: {
      value: string;
    };

    // right side image

    [FormItemName.CAMPAIGN_TEASER_RIGHT_SIDE_IMAGE]: Image;
  };

  // type specific

  [FormItemName.CAMPAIGN_TYPE_SPECIFIC]: {
    [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE]: string;
    [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_BG_COLOR]: string;
    [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR]: {
      value: string;
    };
    [FormItemName.CAMPAIGN_TS_COUNTER_BG_OPTION]: {
      value: string;
      title: string;
    };
    [FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE]: Image;
    [FormItemName.CAMPAIGN_TS_COUNTER_EMPTY_IMAGE]: Image;
    [FormItemName.CAMPAIGN_TS_COUNTER_OPERATION]: {
      value: string;
      title: string;
    };
    [FormItemName.CAMPAIGN_TS_COUNTER_URL]: string;
    [FormItemName.CAMPAIGN_TS_OBJECT_LIST]: Array<{
      [FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME]: string;
      [FormItemName.CAMPAIGN_TS_OBJECT_IMAGE]: Image;
      [FormItemName.CAMPAIGN_TS_OBJECT_UUID]: number;
    }>;
  };
};

export enum CounterBackgroundSegmentOption {
  IMAGE = 'image',
  COLOR = 'color',
}
