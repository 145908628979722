import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../lib/store/hooks';
import { Form } from 'antd';
import {
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../lib/utils/arranges-helpers';
import { useEffect, useState } from 'react';
import { useEnumArrange } from '../../tenants/hooks/useEnumArrange';
import { useStatusColumn } from '../../../features/table/helpers/useStatusColumn';
import { CAMPAIGN_COLUMNS, CAMPAIGN_STATUS, CAMPAIGN_STATUSES } from '../helpers/consts';
import { useCampaignArrangeRequest } from './useCampaignArrangeRequest';
import { CampaignStatusBodyCell } from '../components/CampaignStatusBodyCell';

const activated = 'pages.campaigns.campaignTable.statuses.active';
const deactivated = 'pages.campaigns.campaignTable.statuses.deactivated';
const upcoming = 'pages.campaigns.campaignTable.statuses.upcoming';

export const useCampaignStatusColumn = () => {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ campaigns }) => campaigns);
  const { appLang } = useAppSelector(({ auth }) => auth);

  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === CAMPAIGN_COLUMNS.STATUS;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);

  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = t(activated);
  const deactivatedLabel = t(deactivated);
  const upcomingLabel = t(upcoming);

  const allOptions = [
    createOptionItem(activatedLabel, CAMPAIGN_STATUS.ACTIVATED),
    createOptionItem(deactivatedLabel, CAMPAIGN_STATUS.DEACTIVATED),
    createOptionItem(upcomingLabel, CAMPAIGN_STATUS.UPCOMING),
  ];

  const { filtering } = useEnumArrange(allOptions);

  useEffect(() => {
    filtering.setOptions(allOptions);
  }, [appLang]);

  const { applyArranges, removeFilter, removeFilterValue } = useCampaignArrangeRequest({
    arrangedId: CAMPAIGN_COLUMNS.STATUS,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ statuses: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'includeStatus',
    isStatusColumn: true,
    // isParsedByValue: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId: CAMPAIGN_COLUMNS.STATUS,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    disableSorting: true,
    columnProps: {
      // width: 134,
      render: (status: CAMPAIGN_STATUSES) => {
        return <CampaignStatusBodyCell activatedStatus={status} />;
      },
    },
  });
};
