import React, { FC } from 'react';
import { DemandArea } from '../../../../lib/types/endUser';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import cn from 'classnames';

import { parseJSON } from '../../../../lib/utils/helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { getCoveredCategories } from '../../../../lib/utils/end-user-helpers';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

const translation = 'endUser.shared';

type Props = {
  demandAreas?: DemandArea[];
  handleAreaClick: (areaId: number, areaName: string) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLDivElement>,
    areaId: number,
    areaName: string,
  ) => void;
};
export const NewHouseModelMobile: FC<Props> = ({
  demandAreas,
  handleAreaClick,
  handleKeyDown,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.houseTop}>
          {demandAreas?.slice(0, 2).map((area, index) => {
            const isLeft = index === 0;
            const areaColor = parseJSON(area.color);

            const totalCategories = area?.categories.length;
            const coveredCategoriesAmount = getCoveredCategories(
              area?.categories,
            )?.length;

            return (
              <div
                key={area.id}
                role="presentation"
                onClick={() => handleAreaClick(area.id, area.name)}
                onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                className={cn(isLeft ? styles.houseTopLeft : styles.houseTopRight)}
                style={{ backgroundColor: areaColor.value }}
              >
                <div
                  className={styles.iconWrapper}
                  style={{
                    backgroundColor: `color-mix(in srgb, ${areaColor.value}, rgba(255, 255, 255, 0.6) 30%)`,
                  }}
                >
                  {area.campaignObject ? (
                    <CampaignObjectImage
                      campaignObject={area.campaignObject}
                      imageClassName={styles.campaignObject}
                    />
                  ) : (
                    <IconComponent
                      className={styles.icon}
                      color="#fff"
                      iconData={area?.icon}
                    />
                  )}
                </div>

                <EndUserTypography
                  type="headlineSixth"
                  component="Title"
                  className={styles.title}
                  style={{ marginBottom: 0 }}
                >
                  {area.name}
                </EndUserTypography>
                <EndUserTypography
                  type="subText"
                  component="Text"
                  style={{ color: '#fff', textAlign: 'center' }}
                  className={styles.subTitle}
                >
                  {t(`${translation}.coveredCategoriesAmount`, {
                    coveredCategoriesAmount,
                    totalCategories,
                  })}
                </EndUserTypography>
              </div>
            );
          })}
        </div>

        <div className={styles.houseBottom}>
          {demandAreas?.slice(2).map((area, index) => {
            const isLeft = index === 0;
            const areaColor = parseJSON(area.color);
            const totalCategories = area?.categories.length;
            const coveredCategoriesAmount = getCoveredCategories(
              area?.categories,
            )?.length;

            return (
              <div
                key={area.id}
                role="presentation"
                onClick={() => handleAreaClick(area.id, area.name)}
                onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                className={cn(isLeft ? styles.houseBottomLeft : styles.houseBottomRight)}
                style={{ backgroundColor: areaColor.value }}
              >
                <div
                  className={styles.iconWrapper}
                  style={{
                    backgroundColor: `color-mix(in srgb, ${areaColor.value}, rgba(255, 255, 255, 0.6) 30%)`,
                  }}
                >
                  {area.campaignObject ? (
                    <CampaignObjectImage
                      campaignObject={area.campaignObject}
                      imageClassName={styles.campaignObject}
                    />
                  ) : (
                    <IconComponent
                      className={styles.icon}
                      color="#fff"
                      iconData={area?.icon}
                    />
                  )}
                </div>

                <EndUserTypography
                  type="headlineSixth"
                  component="Title"
                  className={styles.title}
                  style={{ marginBottom: 0 }}
                >
                  {area.name}
                </EndUserTypography>
                <EndUserTypography
                  type="subText"
                  component="Text"
                  style={{ color: '#fff', textAlign: 'center' }}
                  className={styles.subTitle}
                >
                  {t(`${translation}.coveredCategoriesAmount`, {
                    coveredCategoriesAmount,
                    totalCategories,
                  })}
                </EndUserTypography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
