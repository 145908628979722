import { FC, useMemo } from 'react';
import { ProgressBar } from '../ProgressBar';
import { IconComponent } from '../../../../components/IconComponent';
import styles from './index.module.scss';
import TopLeftItem from './components/TopLeftItem';
import TopRightItem from './components/TopRightItem';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { parseJSON } from '../../../../lib/utils/helpers';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { SmallDemandAreaHouseNew } from '../SmallDemandAreaHouseNew';
import BigHouseItem from './components/BigHouseItem';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import cn from 'classnames';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

type Props = {
  handleTabClick: (index: number) => void;
  activeTab?: number;
  currentDemandArea: any;
  demandAreas: DemandArea[];
  onClickCategory: (category: Category) => void;
};

const sortCategoriesByPriority = (categories: any[]) => {
  return categories.slice().sort((a, b) => a.priority.number - b.priority.number);
};

export const SquareShadowHouseNew: FC<Props> = ({
  handleTabClick,
  activeTab,
  currentDemandArea,
  demandAreas,
  onClickCategory,
}) => {
  const { isOnlyTablet } = useContainerQuery();
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleTabClick(index);
    }
  };

  const handleKeyDownCategory = (
    event: React.KeyboardEvent<HTMLDivElement>,
    category: Category,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickCategory(category);
    }
  };

  const bigSquareStyle = {
    transform: isOnlyTablet
      ? `translate(-${Number(activeTab) % 2 ? 25 : 15}%, -${Math.floor(Number(activeTab) / 2) * 50 + (Number(activeTab) === 1 || Number(activeTab) === 0 ? 12 : 0)}%) scale(0.8)`
      : `translate(-${(Number(activeTab) % 2) * 30 + (Number(activeTab) === 1 || Number(activeTab) === 3 ? 20 : 40)}%, -${Math.floor(Number(activeTab) / 2) * 50}%)`,
  };

  const demandAreaColor = useMemo(() => {
    return parseJSON(currentDemandArea.color);
  }, [currentDemandArea]);

  const [areaTopLeft, areaTopRight, areaBottomLeft, areaBottomRight] = demandAreas;

  const areaColorTopLeft = parseJSON(areaTopLeft?.color);
  const areaColorTopRight = parseJSON(areaTopRight?.color);
  const areaColorBottomLeft = parseJSON(areaBottomLeft?.color);
  const areaColorBottomRight = parseJSON(areaBottomRight?.color);

  const sortedCategoriesTopLeft = sortCategoriesByPriority(areaTopLeft.categories);
  const sortedCategoriesTopRight = sortCategoriesByPriority(areaTopRight.categories);
  const sortedCategoriesBottomLeft = sortCategoriesByPriority(areaBottomLeft.categories);
  const sortedCategoriesBottomRight = sortCategoriesByPriority(
    areaBottomRight.categories,
  );

  const getBackgroundColor = (
    currentDemandAreaId: number,
    areaTopItemId: number,
    hasUserProducts: boolean,
  ) => {
    if (currentDemandAreaId === areaTopItemId) {
      if (hasUserProducts) {
        return `color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 20%)`;
      }
      return `linear-gradient(180deg, #FFF 4.5%, rgba(255, 255, 255, 0.90) 25%, rgba(255, 255, 255, 0.80) 50%, rgba(255, 255, 255, 0.50) 100%), color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 20%)`;
    }
    return `color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 90%)`;
  };

  return (
    <div className={styles.squareSectionContainer}>
      <div
        className={styles.squareContainer}
        style={{ backgroundColor: demandAreaColor.value }}
      >
        <SmallDemandAreaHouseNew
          onAreaClick={handleTabClick}
          onClickCategory={onClickCategory}
          demandAreaColor={demandAreaColor.value}
          demandAreas={demandAreas}
          currentDemandArea={currentDemandArea}
        />
        <div className={styles.container} style={bigSquareStyle}>
          <div className={styles.bigHouseIcon}>
            <BigHouseItem
              fill={`color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 92%)`}
            />
          </div>
          <div className={styles.topContainer}>
            <div
              className={styles.topLeftLine}
              style={{
                background: `color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 92%)`,
              }}
            />
            <div
              className={styles.topRightLine}
              style={{
                background: `color-mix(in srgb, ${demandAreaColor.value}, #FFFFFF 92%)`,
              }}
            />
            <div className={styles.topLeftIcon}>
              <TopLeftItem
                fill={
                  currentDemandArea.id === areaTopLeft.id ? demandAreaColor.value : '#fff'
                }
              />
            </div>
            <div className={styles.topRightIcon}>
              <TopRightItem
                fill={
                  currentDemandArea.id === areaTopRight.id
                    ? demandAreaColor.value
                    : '#fff'
                }
              />
            </div>
            <div
              tabIndex={0}
              role="button"
              className={styles.topLeft}
              onKeyDown={(event) => handleKeyDown(event, 0)}
            >
              {sortedCategoriesTopLeft.map((category: any) => {
                const hasUserProducts = checkBoughtProductsInCategory(category);
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={category.id}
                    className={styles.topLeftItem}
                    style={{
                      background: getBackgroundColor(
                        currentDemandArea.id,
                        areaTopLeft.id,
                        hasUserProducts,
                      ),
                      cursor:
                        currentDemandArea.id === areaTopLeft.id ? 'pointer' : 'initial',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickCategory(category);
                    }}
                    onKeyDown={(event) => handleKeyDownCategory(event, category)}
                  >
                    {currentDemandArea.id === areaTopLeft.id && (
                      <>
                        {category.campaignObject ? (
                          <div className={cn(styles.icon, styles.icon_top)}>
                            <CampaignObjectImage
                              campaignObject={category.campaignObject}
                            />
                          </div>
                        ) : (
                          <IconComponent
                            className={cn(styles.icon, styles.icon_top)}
                            iconData={category?.icon}
                            color={hasUserProducts ? '#fff' : areaColorTopLeft.value}
                          />
                        )}
                        <p
                          className={styles.title}
                          style={{
                            color: hasUserProducts ? '#fff' : areaColorTopLeft.value,
                          }}
                        >
                          {category.name}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div
              className={styles.topRight}
              tabIndex={0}
              role="button"
              onKeyDown={(event) => handleKeyDown(event, 1)}
            >
              {sortedCategoriesTopRight.map((category: any) => {
                const hasUserProducts = checkBoughtProductsInCategory(category);
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={category.id}
                    className={styles.topRightItem}
                    style={{
                      background: getBackgroundColor(
                        currentDemandArea.id,
                        areaTopRight.id,
                        hasUserProducts,
                      ),
                      cursor:
                        currentDemandArea.id === areaTopRight.id ? 'pointer' : 'initial',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickCategory(category);
                    }}
                    onKeyDown={(event) => handleKeyDownCategory(event, category)}
                  >
                    {currentDemandArea.id === areaTopRight.id && (
                      <>
                        <IconComponent
                          className={cn(styles.icon, styles.icon_top)}
                          iconData={category?.icon}
                          color={hasUserProducts ? '#fff' : areaColorTopRight.value}
                        />
                        <p
                          className={styles.title}
                          style={{
                            color: hasUserProducts ? '#fff' : areaColorTopRight.value,
                          }}
                        >
                          {category.name}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div
              className={styles.bottomLeft}
              tabIndex={0}
              role="button"
              style={{
                background:
                  currentDemandArea.id === areaBottomLeft.id && areaColorBottomLeft.value,
              }}
              onKeyDown={(event) => handleKeyDown(event, 2)}
            >
              {sortedCategoriesBottomLeft.map((category: any) => {
                const hasUserProducts = checkBoughtProductsInCategory(category);
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={category.id}
                    className={styles.bottomLeftItem}
                    style={{
                      background: getBackgroundColor(
                        currentDemandArea.id,
                        areaBottomLeft.id,
                        hasUserProducts,
                      ),
                      cursor:
                        currentDemandArea.id === areaBottomLeft.id
                          ? 'pointer'
                          : 'initial',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickCategory(category);
                    }}
                    onKeyDown={(event) => handleKeyDownCategory(event, category)}
                  >
                    {currentDemandArea.id === areaBottomLeft.id && (
                      <>
                        <IconComponent
                          className={styles.icon}
                          iconData={category?.icon}
                          color={hasUserProducts ? '#fff' : areaColorBottomLeft.value}
                        />
                        <p
                          className={styles.title}
                          style={{
                            color: hasUserProducts ? '#fff' : areaColorBottomLeft.value,
                          }}
                        >
                          {category.name}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              tabIndex={0}
              role="button"
              className={styles.bottomRight}
              style={{
                background:
                  currentDemandArea.id === areaBottomRight.id &&
                  areaColorBottomRight.value,
              }}
              onKeyDown={(event) => handleKeyDown(event, 3)}
            >
              {sortedCategoriesBottomRight.map((category: any) => {
                const hasUserProducts = checkBoughtProductsInCategory(category);
                return (
                  <div
                    role="presentation"
                    key={category.id}
                    className={styles.bottomRightItem}
                    style={{
                      background: getBackgroundColor(
                        currentDemandArea.id,
                        areaBottomRight.id,
                        hasUserProducts,
                      ),
                      cursor:
                        currentDemandArea.id === areaBottomRight.id
                          ? 'pointer'
                          : 'initial',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickCategory(category);
                    }}
                    onKeyDown={(event) => handleKeyDownCategory(event, category)}
                  >
                    {currentDemandArea.id === areaBottomRight.id && (
                      <>
                        <IconComponent
                          className={styles.icon}
                          iconData={category?.icon}
                          color={hasUserProducts ? '#fff' : areaColorBottomRight.value}
                        />
                        <p
                          className={styles.title}
                          style={{
                            color: hasUserProducts ? '#fff' : areaColorBottomRight.value,
                          }}
                        >
                          {category.name}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ProgressBar currentDemandArea={currentDemandArea} />
    </div>
  );
};
