import { FC, useCallback, useMemo, useState } from 'react';
import { BonusesList } from '../BonusesList';
import { BonusesTabs } from '../BonusesTabs';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useNavigate } from 'react-router-dom';
import { HeaderSection } from '../../../endUserSectionHeader/components/HeaderSection';
import { EndUserButton } from '../../../../components/EndUserButton';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { combineBonusesFromStatusLevel } from '../../../../lib/utils/end-user-helpers';
import { BonusT } from '../../../../lib/types/statusLevel';

import styles from './index.module.scss';

import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import {
  setGuestMode,
  setMatomoTrackingForGuest,
} from '../../../../lib/store/slices/endUserSlice';
import { appLogout } from '../../../../lib/utils/helpers';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
} from '../../../campaignDetails/hooks/useGetPositionOption';

export const BonusOverviewSection: FC = () => {
  const { sections, demandArea, statusLevels, userStatusLevel, isGuestMode } =
    useAppSelector(({ endUser }) => endUser);

  const { foundObjects } = useGetCampaignObjectPositions({
    page: CAMPAIGN_POSITION_PAGES_OPTIONS.BONUS_PAGE,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.BONUSES],
    elements: [CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_NAVIGATION],
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const currentSectionContent = sections?.[SectionName.BONUS_PAGE];

  const userPoints = userStatusLevel.currentPoints;

  const subLevelsWithStatusLevel = useMemo(() => {
    return statusLevels
      .flatMap((statusLevel: any) => {
        const foundCampaignStatusLevel = foundObjects.find(
          (obj) => obj.statusLevelId === statusLevel.id,
        );

        const firstSubLevel = statusLevel.subLevels[0];
        return firstSubLevel
          ? {
              ...firstSubLevel,
              statusLevelName: statusLevel.name,
              statusLevelId: statusLevel.id,
              campaignObject: foundCampaignStatusLevel ? foundCampaignStatusLevel : null,
            }
          : null;
      })
      .filter(Boolean);
  }, [foundObjects, statusLevels]);

  const [combinedBonuses, setCombinedBonuses] = useState<BonusT[]>([]);

  const handleTabChange = useCallback(
    (selectedTab: any) => {
      const foundStatusLevel = statusLevels?.find((level: any) =>
        level.subLevels.some((subLevel: any) => subLevel.id === selectedTab.id),
      );

      const combinedBonuses = combineBonusesFromStatusLevel(foundStatusLevel);

      setCombinedBonuses(combinedBonuses);

      if (selectedTab) {
        sendDataToAnalytics(
          'Bonus Overview Section,',
          'Status level change,',
          `Status level name: ${selectedTab.name}, Status level id: ${selectedTab.id}`,
        );
      }
    },
    [statusLevels],
  );

  const handleClickMainButton = () => {
    if (isGuestMode) {
      dispatch(setGuestMode(false));
      dispatch(setMatomoTrackingForGuest({ trackingEnabled: false }));
      appLogout(dispatch);

      if (window._paq) {
        window._paq.push(['forgetConsentGiven']);
      }
    } else {
      navigate(`/${ROUTER_PATHS.pointsOverview}`);
    }

    sendDataToAnalytics(
      'Bonus Overview Section,',
      'Primary button,',
      `Redirect to ${currentSectionContent?.buttonRedirectTo}`,
    );
  };

  return (
    <>
      <HeaderSection
        header={currentSectionContent?.headline1}
        title={currentSectionContent?.subline}
        image={currentSectionContent?.backgroundImage}
      />
      <div className={styles.wrapper}>
        <EndUserTypography
          type="headlineSecond"
          component="Title"
          className={styles.title}
        >
          {currentSectionContent?.headline2}
        </EndUserTypography>

        <BonusesTabs
          icon={demandArea?.project?.pointsIcon}
          sortedSubLevels={subLevelsWithStatusLevel}
          userPoints={userPoints}
          onTabChange={handleTabChange}
        />
        <BonusesList bonuses={combinedBonuses} />

        <EndUserButton
          type="primary"
          className={styles.button}
          onClick={handleClickMainButton}
        >
          {currentSectionContent?.buttonText}
        </EndUserButton>
      </div>
    </>
  );
};
