export enum ROUTER_PATHS {
  logIn = 'anmeldung',
  logInFr = 'log-in',
  signUp = '/registrierung',
  signUpFr = '/sing-up',
  setUpPassword = 'set-up-password',
  passwordReset = '/password-reset',
  getResetPasswordLink = '/get-link-for-reset-password',
  tenants = 'tenants',
  tenant = 'tenant',
  users = 'users',
  themes = 'themes',
  templates = 'templates',
  generalSettings = 'general-settings',
  projects = 'models',
  // Tenant Global Routes
  designSettings = 'design-settings',
  products = 'products',
  statusLevel = 'status-level',
  ruleBuilder = 'rule-builder',
  campaigns = 'campaigns',
  forms = 'forms',
  // Tenant Section routes
  header = 'header',
  footer = 'footer',
  model = 'model',
  productCategories = 'product-categories',
  productDetails = 'product-details',
  statusBar = 'status-bar',
  statusOffers = 'status-offers',
  companyBenefits = 'company-benefits',
  regionalOffers = 'regional-offers',
  productRecommended = 'product-recommended',
  friends = 'friends',
  // Templates
  intro = 'templates/intro',
  chapter = 'templates/chapter',
  content = 'templates/content',
  question = 'templates/question',
  product = 'templates/product',
  result = 'templates/result',
  contact = 'templates/contact',
  // End User Flow
  home = '/haus',
  homeFr = '/home',
  bonuses = 'Bonusübersicht',
  bonusesFr = 'bonuses',
  houseModel = 'Produktwelt',
  houseModelFr = 'house-model',
  wishlist = 'Merkliste',
  wishlistFr = 'wishlist',
  pointsOverview = 'status',
  notFound = 'not-found',
  terms = 'terms',
  noMatch = '*',
}

export enum CMS_TENANT_GLOBAL_ROUTER_PATHS {
  generalSettings = ROUTER_PATHS.generalSettings,
  designSettings = ROUTER_PATHS.designSettings,
  products = ROUTER_PATHS.products,
  statusLevel = ROUTER_PATHS.statusLevel,
  ruleBuilder = ROUTER_PATHS.ruleBuilder,
  campaigns = ROUTER_PATHS.campaigns,
  forms = ROUTER_PATHS.forms,
}

export enum CMS_TENANT_SECTION_ROUTES {
  header = ROUTER_PATHS.header,
  footer = ROUTER_PATHS.footer,
  model = ROUTER_PATHS.model,
  productCategories = ROUTER_PATHS.productCategories,
  productDetails = ROUTER_PATHS.productDetails,
  statusBar = ROUTER_PATHS.statusBar,
  statusOffers = ROUTER_PATHS.statusOffers,
  companyBenefits = ROUTER_PATHS.companyBenefits,
  regionalOffers = ROUTER_PATHS.regionalOffers,
  productRecommended = ROUTER_PATHS.productRecommended,
  friends = ROUTER_PATHS.friends,
}
