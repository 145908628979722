import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { CAMPAIGN_COLUMNS } from '../helpers/consts';
import { useCampaignSearchDataColumn } from './useCampaignSearchDataColumn';
import { CampaignT } from '../../../lib/types/campaigns';

const title = 'pages.campaigns.campaignTable.columns.campaignName';

export const useCampaignNameColumn = () => {
  const { t } = useTranslation();

  return useCampaignSearchDataColumn({
    title: t(title),
    arrangedId: CAMPAIGN_COLUMNS.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 320,
      render: (name: string) => {
        return (
          <span style={{ cursor: 'pointer' }}>
            <Typography.Text>{name}</Typography.Text>
          </span>
        );
      },
    },
  });
};
