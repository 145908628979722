import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Dropdown, Typography } from 'antd';
import cx from 'classnames';
import { UploadMediaResponseT } from '../../lib/types/media';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronUpIcon } from '../../assets/icons/ChevronUpIcon.svg';
import { ReactComponent as ImagesIcon } from '../../assets/icons/images-icon.svg';
import { ImageOption } from './ImageOption';
import { FormSection } from '../../components/FormSection';
import { UploadResponseT } from '../../lib/types/entities';
import styles from './index.module.scss';

const inputPlaceholdersByTypes = 'shared.arranges.filter.inputPlaceholdersByTypes.list';
const label = 'unique.formItems.selectPicture.label';

const { Text } = Typography;

type Props = {
  value?: UploadMediaResponseT;
  onChange: (arg: UploadMediaResponseT) => void;
  options?: UploadMediaResponseT[];
  disabled?: boolean;
  pictureClassName?: string;
  imageWrapperClassName?: string;
};

export const ImageSelect: FC<Props> = ({
  value: selectedValue,
  onChange,
  options: mediaOptions,
  disabled,
  pictureClassName,
  imageWrapperClassName,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectWidth, setSelectWidth] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState(mediaOptions);
  const selectRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const filteredOptions = mediaOptions?.filter(
        (mediaObj) =>
          mediaObj.originalFileName &&
          mediaObj.originalFileName.includes(event.target.value),
      );
      setOptions(filteredOptions);
    } else {
      setOptions(mediaOptions);
    }
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (mediaOptions) {
      setOptions(mediaOptions);
    }
  }, [mediaOptions]);

  useEffect(() => {
    if (selectRef?.current) {
      const { width } = selectRef.current.getBoundingClientRect();
      setSelectWidth(width);
    }
  }, [visible]);

  const handleVisibleChange = (flag: boolean) => setVisible(flag);

  const handleOnChange = (val: UploadResponseT) => {
    onChange(val);
    setVisible(false);
  };

  const checkMedia = (value?: number) =>
    value && mediaOptions?.some((media) => media.id === value);

  const backgroundUrl = checkMedia(selectedValue?.id)
    ? mediaOptions?.find((media) => media.id === selectedValue?.id)?.s3Url
    : null;

  const isMedia = checkMedia(selectedValue?.id);

  return (
    <>
      <Dropdown
        disabled={disabled}
        open={visible}
        trigger={['click']}
        placement="bottom"
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        onOpenChange={handleVisibleChange}
        dropdownRender={() => (
          <div className={styles.content} style={{ width: Number(selectWidth) }}>
            <FormSection
              size="small"
              className={styles.formSection}
              formItemProps={{ hasFeedback: false, style: { minHeight: 'unset' } }}
              textFieldProps={{
                label: t(inputPlaceholdersByTypes),
                onChange: handleSearch,
                value: searchValue,
              }}
            />
            <div className={styles.optionsWrapper}>
              {options?.map((imageObj) => (
                <ImageOption
                  key={imageObj.id}
                  media={imageObj}
                  isSelected={selectedValue?.id === imageObj.id}
                  onSelect={handleOnChange}
                />
              ))}
            </div>
          </div>
        )}
      >
        <div
          ref={selectRef}
          className={cx(
            styles.imageSelectWrapper,
            {
              [styles.closed]: !visible,
            },
            imageWrapperClassName,
          )}
        >
          <div
            className={cx(
              styles.picture,
              { [styles.pictureNotSelected]: !isMedia },
              pictureClassName,
            )}
            style={
              isMedia
                ? {
                    backgroundImage: `url('${backgroundUrl}')`,
                  }
                : {}
            }
          >
            {!isMedia && <ImagesIcon />}
          </div>

          {isMedia && (
            <Text ellipsis className={styles.text}>
              {
                mediaOptions?.find((mediaObj) => mediaObj.id === selectedValue?.id)
                  ?.originalFileName
              }
            </Text>
          )}

          <Text
            ellipsis={!isMedia}
            className={isMedia ? styles.labelSelected : styles.label}
          >
            {t(label)}
          </Text>

          <ChevronUpIcon
            className={visible ? styles.arrowIcon : styles.revertArrowIcon}
          />
        </div>
      </Dropdown>
    </>
  );
};
