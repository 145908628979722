import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetCompletedCampaignElementsQuery } from '../../store/api/endUser';
import { useEffect } from 'react';
import { setCompletedCampaignElements } from '../../store/slices/endUserSlice';

export const useGetCompletedCampaignElements = () => {
  const dispatch = useAppDispatch();

  const { tenantAndProject, activeCampaign, isGuestMode } = useAppSelector(
    ({ endUser }) => endUser,
  );

  const tenantId = tenantAndProject?.tenantId;

  const { data } = useGetCompletedCampaignElementsQuery(
    {
      tenantId: Number(tenantId),
      campaignId: Number(activeCampaign?.id),
    },
    {
      skip: !activeCampaign || isGuestMode,
    },
  );

  useEffect(() => {
    if (data?.length) {
      const completedElements = data.flatMap((item) => item.campaignElement);

      dispatch(setCompletedCampaignElements(completedElements));
    }
  }, [data, dispatch]);
};
