import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';
import { CAMPAIGN_COLUMNS } from '../../../modules/campaignTable/helpers/consts';
import { CampaignT } from '../../types/campaigns';
import { TableSortOrder } from '../../utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
  page: number;
  arrangeId: CAMPAIGN_COLUMNS | null;
  sorting: Sort;
  filtering: Filter;
  selectedCampaign: CampaignT | null;
};

const initialState: SliceState = {
  page: 1,
  selectedCampaign: null,
  arrangeId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  filtering: {
    filterFieldName: null,
    filter: [],
  },
};

export type CampaignFiltersPayload = FiltersPayload & {
  arrangeId: CAMPAIGN_COLUMNS;
};

const campaignsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setSelectedCampaign: (state, action: PayloadAction<CampaignT | null>) => {
      state.selectedCampaign = action.payload;
    },
    setCampaignsTableFilters: (state, action: PayloadAction<CampaignFiltersPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
    setCampaignsTablePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetCampaignsTable: () => initialState,
  },
});

export const {
  setSelectedCampaign,
  setCampaignsTableFilters,
  setCampaignsTablePage,
  resetCampaignsTable,
} = campaignsSlice.actions;
export const campaigns = campaignsSlice.reducer;
