import React, { FC, useEffect, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { Form, FormInstance } from 'antd';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';

import { AppModal } from '../../../../components/AppModal';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  form: FormInstance;
  formName: string | string[];
  label: string;
};
export const GradientFormPicker: FC<Props> = ({ form, formName, label }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [color, setColor] = useState('');

  const { t } = useTranslation();

  const onClose = () => {
    setModalVisible(false);
  };

  const onOpen = () => {
    setModalVisible(true);
  };

  const onChangeColor = (value: string) => {
    form.setFieldValue(formName, value);
  };

  const onConfirm = () => {
    onChangeColor(color);
    onClose();
  };

  const value = Form.useWatch(formName, form);

  useEffect(() => {
    if (modalVisible) {
      setColor(value);
    }
  }, [modalVisible]);

  return (
    <div className={styles.container}>
      <div className={styles.colorCircle} style={{ background: value }} />

      <FormItem
        name={formName}
        help={false}
        hasFeedback={true}
        rules={[{ required: false }]}
      >
        <FormInput className={styles.input} onClick={onOpen} label={label} />
      </FormItem>

      <AppModal
        open={modalVisible}
        onCancel={onClose}
        onOk={onConfirm}
        okText={t('generic.save')}
        modalWidth={412}
      >
        <div className={styles.pickerWrapper}>
          <ColorPicker value={color} onChange={setColor} width={340} />;
        </div>
      </AppModal>
    </div>
  );
};
