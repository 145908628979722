import { CampaignTable } from '../../modules/campaignTable/components/CampaignTable';
import styles from './index.module.scss';
import { Button } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { CampaignDetailsTabs } from '../../modules/campaignDetails/components/CampaignDetailsTabs';
import { useSearchParams } from 'react-router-dom';
import { CampaignTabs, QueryParams } from '../../lib/types/queryParams';
import { useAppDispatch } from '../../lib/store/hooks';
import { setSelectedCampaign } from '../../lib/store/slices/campaignsSlice';
import { message } from 'antd';

const campaignsTranslate = 'pages.campaigns.campaignTable';

export const CampaignPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const queryTab = searchParams.get(QueryParams.TAB);

  const onCreateNewCampaign = () => {
    setSearchParams({ [QueryParams.TAB]: CampaignTabs.GENERAL });
  };

  const onBackClick = () => {
    setSearchParams('');
    dispatch(setSelectedCampaign(null));
    setSearchParams('');
  };

  const handleClickCampaign = () => {
    setSearchParams({ [QueryParams.TAB]: CampaignTabs.GENERAL });
  };

  return (
    <>
      {contextHolder}
      {queryTab === CampaignTabs.BONUS_RULES ||
      queryTab === CampaignTabs.TYPE_SPECIFIC ||
      queryTab === CampaignTabs.GENERAL ? (
        <CampaignDetailsTabs onBackClick={onBackClick} messageApi={messageApi} />
      ) : (
        <div className={styles.container}>
          <div className={styles.btnContainer}>
            <Button onClick={onCreateNewCampaign} className={styles.btn}>
              {t(`${campaignsTranslate}.buttons.new`)}
            </Button>
          </div>
          <CampaignTable handleClickCampaign={handleClickCampaign} />
        </div>
      )}
    </>
  );
};
