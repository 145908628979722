import styles from './index.module.scss';
import { ConditionArea } from '../ConditionArea';
import React, { FC } from 'react';
import { ConditionsArray } from '../../types';
import { CustomRuleBuilder } from '../CustomRuleBuilder';

type Props = {
  query: ConditionsArray;
  setQuery: React.Dispatch<React.SetStateAction<ConditionsArray>>;
  isEditModeEnabled: boolean;
};

export const RuleConfiguratorBody: FC<Props> = ({
  query,
  setQuery,
  isEditModeEnabled,
}) => {
  return (
    <div className={styles.container}>
      <ConditionArea setQuery={setQuery} isEditModeEnabled={isEditModeEnabled} />
      <CustomRuleBuilder
        query={query}
        setQuery={setQuery}
        isEditModeEnabled={isEditModeEnabled}
      />
    </div>
  );
};
