import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../lib/store/hooks';
import {
  useCreateCampaignMutation,
  useGetObjectsListQuery,
  useSaveObjectsMutation,
  useUpdateCampaignMutation,
} from '../../../lib/store/api/campaigns';
import { useEffect } from 'react';
import { FormInstance } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { getCampaignFromFieldsData } from '../helpers/getCampaignFromFieldsData';
import { trimForm } from '../../../lib/utils/helpers';
import { CampaignFromValuesT } from '../types/types';
import { prepareCampaignFormData } from '../helpers/prepareCampaignFormData';
import { CreateCampaignPayloadT } from '../../../lib/types/campaigns';

const successMessages = 'pages.campaigns.campaignDetails.successMessages';
const errorMessages = 'pages.campaigns.campaignDetails.errorMessages';

type Props = {
  form: FormInstance;
  messageApi: MessageInstance;
  onBackClick: () => void;
};

export const useCampaignDetailsForm = ({ form, messageApi, onBackClick }: Props) => {
  const { t } = useTranslation();
  const { id: tenantId, modelId: projectId } = useParams();

  const selectedCampaign = useAppSelector(({ campaigns }) => campaigns.selectedCampaign);

  const [createCampaign, { isLoading }] = useCreateCampaignMutation();
  const [updateCampaign] = useUpdateCampaignMutation();
  const [saveObjects] = useSaveObjectsMutation();

  const { data: campaignObjectsList } = useGetObjectsListQuery(
    {
      tenantId: String(tenantId),
      campaignId: Number(selectedCampaign?.id),
    },
    {
      skip: !selectedCampaign,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (selectedCampaign && campaignObjectsList) {
      const data = getCampaignFromFieldsData(selectedCampaign, campaignObjectsList);

      form.setFieldsValue(data);
    }
  }, [form, selectedCampaign, campaignObjectsList]);

  const handleSubmit = async (values: CampaignFromValuesT) => {
    const trimmedForm = trimForm(values);
    const { preparedData, campaignObjectsList } = prepareCampaignFormData(trimmedForm);

    const data: CreateCampaignPayloadT = {
      ...preparedData,
      projectId: Number(projectId),
      tenantId,
    };

    let campaignId = 0;

    try {
      if (selectedCampaign) {
        const updateCampaignPayload = {
          ...data,
          id: selectedCampaign.id,
        };

        await updateCampaign(updateCampaignPayload).unwrap();

        campaignId = selectedCampaign.id;
      } else {
        const response = await createCampaign(data).unwrap();

        campaignId = response.id;
      }

      const preparedObjectsBody = campaignObjectsList.map((item) => ({
        ...item,
        campaignId: campaignId,
      }));

      await saveObjects({
        body: preparedObjectsBody,
        params: {
          tenantId: String(tenantId),
          campaignId,
        },
      });

      const text = selectedCampaign
        ? `${successMessages}.campaignEditing`
        : `${successMessages}.campaignCreation`;
      messageApi.success(t(text));
      onBackClick();
    } catch (err: any) {
      const text = selectedCampaign
        ? `${errorMessages}.campaignEditing`
        : `${errorMessages}.campaignCreation`;
      messageApi.error(t(text));
    }
  };

  return {
    onFinish: handleSubmit,
    isLoading,
    selectedCampaign,
  };
};
