import { ProductT, Visibility } from '../../../../lib/types/product';
import { FoundElementT } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';

interface CategoryGroup {
  categoryId: number;
  categoryPriority: number; // category.priority.number
  products: ProductT[];
}

interface DemandAreaGroup {
  demandAreaId: number;
  categories: CategoryGroup[];
}

export const isHidden = (product: ProductT): boolean => {
  if (product.visibility === Visibility.NEVER) {
    return true;
  }
  if (
    product.visibility === Visibility.ONLY &&
    (product.countUserProducts ?? 0) <= 0 &&
    (product.countSpecialProducts ?? 0) <= 0
  ) {
    return true;
  }
  return false;
};

export const isRelevant = (product: ProductT): boolean => {
  return product.irrelevantSurveyReason == null;
};

export const isHiddenOrNotRelevant = (product: ProductT): boolean => {
  return isHidden(product) || !isRelevant(product);
};

function groupProductsByDemandAreaAndCategory(products: ProductT[]): DemandAreaGroup[] {
  const demandAreaMap = new Map<number, Map<number, ProductT[]>>();

  for (const p of products) {
    if (!demandAreaMap.has(Number(p.demandAreaId))) {
      demandAreaMap.set(Number(p.demandAreaId), new Map());
    }
    const categoryMap = demandAreaMap.get(Number(p.demandAreaId))!;

    if (!categoryMap.has(p.category.id)) {
      categoryMap.set(p.category.id, []);
    }
    categoryMap.get(p.category.id)!.push(p);
  }

  const demandAreaGroups: DemandAreaGroup[] = [];

  const sortedDemandAreaIds = Array.from(demandAreaMap.keys()).sort((a, b) => a - b);

  for (const daId of sortedDemandAreaIds) {
    const categoryMap = demandAreaMap.get(daId)!;
    const categoryGroups: CategoryGroup[] = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const catId of categoryMap.keys()) {
      let catProducts = categoryMap.get(catId)!;

      catProducts = catProducts
        .slice()
        .sort((a, b) => (a.priority || 0) - (b.priority || 0));

      const categoryPriority = catProducts[0].category.priority.number;

      categoryGroups.push({
        categoryId: catId,
        categoryPriority,
        products: catProducts,
      });
    }

    categoryGroups.sort((a, b) => a.categoryPriority - b.categoryPriority);

    demandAreaGroups.push({
      demandAreaId: daId,
      categories: categoryGroups,
    });
  }

  return demandAreaGroups;
}

function findReplacementProduct(
  demandAreaGroups: DemandAreaGroup[],
  productToSkip: ProductT,
): ProductT | undefined {
  const currentDAIndex = demandAreaGroups.findIndex(
    (da) => da.demandAreaId === productToSkip.demandAreaId,
  );
  if (currentDAIndex < 0) {
    return undefined;
  }

  const currentDemandArea = demandAreaGroups[currentDAIndex];

  const currentCatIndex = currentDemandArea.categories.findIndex(
    (cat) => cat.categoryId === productToSkip.category.id,
  );
  if (currentCatIndex < 0) {
    return undefined;
  }

  const currentCat = currentDemandArea.categories[currentCatIndex];
  const candidateInSameCat = currentCat.products.find((p) => {
    if (p.id === productToSkip.id) return false;
    if (isHiddenOrNotRelevant(p)) return false;
    if (p.campaignObject) return false;
    return true;
  });
  if (candidateInSameCat) {
    return candidateInSameCat;
  }

  for (let catIdx = 0; catIdx < currentDemandArea.categories.length; catIdx++) {
    if (catIdx === currentCatIndex) continue;
    const cat = currentDemandArea.categories[catIdx];
    const candidate = cat.products.find((p) => {
      if (isHiddenOrNotRelevant(p)) return false;
      if (p.campaignObject) return false;
      return true;
    });
    if (candidate) {
      return candidate;
    }
  }

  for (let daIdx = currentDAIndex + 1; daIdx < demandAreaGroups.length; daIdx++) {
    const daGroup = demandAreaGroups[daIdx];
    for (const cat of daGroup.categories) {
      const candidate = cat.products.find((p) => {
        if (isHiddenOrNotRelevant(p)) return false;
        if (p.campaignObject) return false;
        return true;
      });
      if (candidate) {
        return candidate;
      }
    }
  }

  return undefined;
}

export const sortByIrrelevantSurveyReason = (arr: ProductT[]) => {
  return arr.sort((a, b) => {
    const aHasReason =
      a.irrelevantSurveyReason !== null && a.irrelevantSurveyReason !== undefined;
    const bHasReason =
      b.irrelevantSurveyReason !== null && b.irrelevantSurveyReason !== undefined;

    if (!aHasReason && bHasReason) {
      return -1;
    }
    if (aHasReason && !bHasReason) {
      return 1;
    }
    return 0;
  });
};

export function prepareProducts(
  productWithoutIrrelevant: ProductT[],
  irrelevantProducts: ProductT[],
  foundObjects: FoundElementT[],
): ProductT[] {
  let allProducts: any = [...productWithoutIrrelevant, ...irrelevantProducts];

  allProducts = allProducts.map((product: ProductT) => {
    const foundObj = foundObjects.find((fo) => fo.productId === product.id);
    return {
      ...product,
      campaignObject: foundObj ?? null,
    };
  });

  const demandAreaGroups = groupProductsByDemandAreaAndCategory(allProducts);

  for (const daGroup of demandAreaGroups) {
    for (const categoryGroup of daGroup.categories) {
      for (const product of categoryGroup.products) {
        if (product.campaignObject && isHiddenOrNotRelevant(product)) {
          const savedObj = product.campaignObject;
          product.campaignObject = null;
          const replacement = findReplacementProduct(demandAreaGroups, product);
          if (replacement) {
            replacement.campaignObject = savedObj;
          }
        }
      }
    }
  }

  const finalProducts = demandAreaGroups.flatMap((da) =>
    da.categories.flatMap((cat) => cat.products),
  );

  const preparedProducts = finalProducts.filter((p) => !isHidden(p));

  return preparedProducts;
}
