import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import { useParams } from 'react-router-dom';
import { MessageInstance } from 'antd/es/message/interface';
import { CampaignT } from '../../../../lib/types/campaigns';
import { useDeleteCampaignMutation } from '../../../../lib/store/api/campaigns';

const { Paragraph } = Typography;

const translation = 'pages.campaigns.campaignDetails.modals.deleteCampaign';

const successMsg = 'pages.campaigns.campaignDetails.successMessages.campaignDeleting';
const errorMsg = 'pages.campaigns.campaignDetails.errorMessages.campaignDeleting';

type Props = {
  isOpen: boolean;
  messageApi: MessageInstance;
  selectedCampaign: CampaignT | null;
  close: () => void;
  onGoBack: () => void;
};
export const DeleteCampaignModal: FC<Props> = ({
  isOpen,
  messageApi,
  selectedCampaign,
  close,
  onGoBack,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [deleteCampaign] = useDeleteCampaignMutation();

  const { id: tenantId } = useParams();

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    try {
      await deleteCampaign({
        tenantId: String(tenantId),
        campaignId: Number(selectedCampaign?.id),
      }).unwrap();
      messageApi.success(t(successMsg));
      close();
      onGoBack();
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(errorMsg));
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={close}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(`${translation}.title`)}
    >
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            <Trans
              i18nKey={`${translation}.questionText`}
              components={{ strong: <strong /> }}
              values={{ campaignName: selectedCampaign?.name }}
            />
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
