import { useGetDesignSettingsQuery } from '../../../lib/store/api/designSettings';
import { useParams } from 'react-router-dom';

export const useDesignSettingsColors = () => {
  const { id: tenantId, modelId } = useParams();

  const { data: designSettingsData } = useGetDesignSettingsQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId },
  );

  const parsedDesignSettings = designSettingsData?.[0].settings
    ? JSON.parse(designSettingsData[0].settings)
    : null;

  return parsedDesignSettings?.additionalColors || null;
};
