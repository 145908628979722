import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { useGetCampaignRulesQuery } from '../../../../lib/store/api/campaigns';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignRule, CampaignT } from '../../../../lib/types/campaigns';
import { Button } from '../../../../components/Button';

import styles from './index.module.scss';
import cn from 'classnames';
import { mapConditionAndAddId } from '../../../ruleConfigurator/helpers';
import { FormItemName } from '../../../../lib/models/Form';
import {
  useGetPublicDictionaryQuery,
  useGetRuleByIdQuery,
  useGetRuleTypesListQuery,
} from '../../../../lib/store/api/ruleBuilder';
import { ConditionsArray } from '../../../ruleConfigurator/types';
import { nanoid } from '@reduxjs/toolkit';
import { RuleConfiguratorBody } from '../../../ruleConfigurator/components/RuleConfiguratorBody';
import { useAppDispatch } from '../../../../lib/store/hooks';
import {
  setCurrentRule,
  setDictionaries,
} from '../../../../lib/store/slices/ruleBuilder';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { AppSelect } from '../../../../components/AppSelect';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkOutlineIcon } from '../../../../assets/icons/link-outline.svg';
import { Tabs } from '../../../../lib/types/queryParams';

const initialQuery: ConditionsArray = [
  {
    conditionName: '1 Condition',
    rules: [],
    id: nanoid(),
  },
];

const translation = 'pages.campaigns.bonusRules.buttons';

type Props = {
  form: FormInstance;
  selectedCampaign: CampaignT | null;
};

export const CampaignBonusRules: FC<Props> = ({ form, selectedCampaign }) => {
  const { id: tenantId, modelId } = useParams();
  const [query, setQuery] = useState(initialQuery);
  const [selectedCampaignRule, setSelectedCampaignRule] = useState<CampaignRule | null>(
    null,
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { data } = useGetPublicDictionaryQuery();

  const { data: rulesList } = useGetCampaignRulesQuery(
    {
      tenantId: String(tenantId),
      campaignId: Number(selectedCampaign?.id),
    },
    {
      skip: !selectedCampaign,
      refetchOnFocus: true,
    },
  );

  const { data: ruleData } = useGetRuleByIdQuery(
    {
      ruleId: String(selectedCampaignRule?.id),
      tenantId: String(tenantId),
    },
    { skip: !selectedCampaignRule, refetchOnMountOrArgChange: true },
  );

  const { data: ruleTypesList } = useGetRuleTypesListQuery({
    page: 0,
    tenantId: String(tenantId),
    modelId: String(modelId),
  });

  const onSelectCampaignRule = (rule: CampaignRule | null) => {
    setSelectedCampaignRule(rule);
  };

  useEffect(() => {
    if (ruleData) {
      const modifiedRules = mapConditionAndAddId(ruleData.rule);

      setQuery(modifiedRules);

      form.setFieldValue(FormItemName.RULE_NAME, ruleData.name);
      form.setFieldValue(FormItemName.RULE_TYPE, { value: ruleData.ruleTypeId });
    }
  }, [form, ruleData]);

  useEffect(() => {
    if (rulesList?.length) {
      setSelectedCampaignRule(rulesList[0]);
    }
  }, [rulesList]);

  useEffect(() => {
    if (data) {
      dispatch(setDictionaries(data));
    }
  }, [data, dispatch]);

  const ruleTypeOptions =
    ruleTypesList?.content.map((item) => ({
      value: item.id,
      title: item.name,
    })) || [];

  const onClickEditSelectedRule = useCallback(() => {
    //eslint-disable-next-line
    dispatch(setCurrentRule(ruleData as any));
    navigate(
      `/tenants/${tenantId}/models/${modelId}/rule-builder?tab=${Tabs.RULE_CONFIGURATOR}`,
    );
  }, [dispatch, modelId, navigate, ruleData, tenantId]);

  return (
    <div className={styles.container}>
      <div className={styles.rulesListContainer}>
        {rulesList?.map((rule) => (
          <Button
            className={cn(
              styles.btn,
              selectedCampaignRule?.id === rule.id && styles.btnSelected,
            )}
            key={rule.id}
            onClick={() => onSelectCampaignRule(rule)}
          >
            {rule.name}
          </Button>
        ))}
      </div>

      {selectedCampaignRule && (
        <div className={styles.selectedRuleContainer}>
          <div className={styles.row}>
            <div className={styles.ruleNameType}>
              <FormItem
                style={{ width: 300 }}
                hasFeedback={true}
                name={FormItemName.RULE_NAME}
              >
                <FormInput disabled={true} label={t('formItems.ruleBuilder.ruleName')} />
              </FormItem>

              <FormItem
                style={{ width: 300 }}
                hasFeedback={true}
                name={FormItemName.RULE_TYPE}
              >
                <AppSelect
                  disabled={true}
                  label={t('formItems.ruleBuilder.ruleType')}
                  options={ruleTypeOptions}
                />
              </FormItem>
            </div>

            <Button
              type="link"
              className={styles.editRuleButton}
              onClick={onClickEditSelectedRule}
            >
              <LinkOutlineIcon />
              {t(`${translation}.editRule`)}
            </Button>
          </div>

          <RuleConfiguratorBody
            query={query}
            setQuery={setQuery}
            isEditModeEnabled={false}
          />
        </div>
      )}
    </div>
  );
};
