import React, { FC, useEffect, useMemo } from 'react';
import { useAppSelector } from '../../../../lib/store/hooks';
import { Form, FormInstance } from 'antd';
import { FormItem } from '../../../../components/FormItem';
import styles from './index.module.scss';
import { DatePicker } from '../../../../components/DatePicker';
import cn from 'classnames';
import { parseISO } from 'date-fns';
import { FormItemName } from '../../../../lib/models/Form';

type Props = {
  form: FormInstance;
  formName: string | string[];
  className?: string;
  label: string;
};

export const CampaignDatePicker: FC<Props> = ({ form, formName, className, label }) => {
  const { appLang } = useAppSelector(({ auth }) => auth);

  const date = Form.useWatch(formName, form);
  const onDateChange = (date: Date) => {
    form.setFieldValue(formName, date);
  };

  const minDate = useMemo(() => new Date(), []);

  return (
    <FormItem
      hasFeedback
      name={formName}
      className={cn(styles.datePickerFormItem, className)}
    >
      <DatePicker
        isClearable={false}
        size={'small'}
        minDate={minDate}
        selected={date ? new Date(date) : new Date()}
        locale={appLang}
        onChange={onDateChange}
        style={{ overflow: 'visible', height: '100%' }}
        label={label}
        hasFeedback={false}
        showCalendarIcon
        iconClassName={styles.calendarIcon}
        inputLabelClassName={styles.inputLabel}
        inputClassName={styles.inputInner}
      />
    </FormItem>
  );
};
