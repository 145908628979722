import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { FontColorTag } from '../../../../features/form-items/FontColorFormItem/FontColorTag';
import { AppSelect } from '../../../../components/AppSelect';
import { useGetBrandKitColors } from '../../../designSettings/hooks/useGetBrandKitColors';
import { useDesignSettingsColors } from '../../hooks/useDesignSettingsColors';
import { AdditionalColorsT } from '../../../designSettings/types';
import styles from '../CampaignGeneralSettings/components/CampaignGeneralDetailsBox/index.module.scss';

import { FormItem } from '../../../../components/FormItem';
import cn from 'classnames';
import { Form, FormInstance } from 'antd';
import { FormItemName } from '../../../../lib/models/Form';

const colorsTranslation = 'shared.colors';

type Props = {
  formName: string | string[];
  label: string;
  disabled?: boolean;
  className?: string;
};

export const CampaignButtonColorCard: FC<Props> = ({
  formName,
  label,
  disabled,
  className,
}) => {
  const additionalColors: AdditionalColorsT = useDesignSettingsColors();
  const colors = useGetBrandKitColors();

  const { t } = useTranslation();

  const defaultAdditionalColorsOptions = [
    {
      value: ColorVariants.BLACK,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.black`)}
          color={colors?.black || ''}
        />
      ),
    },
    {
      value: ColorVariants.WHITE,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.white`)}
          color={colors?.white || ''}
        />
      ),
    },
  ];

  const uniqueAdditionColors = defaultAdditionalColorsOptions.filter((item) =>
    colors.hasOwnProperty(item.value),
  );

  const getColorVariants = (colorValue: string) => {
    switch (colorValue) {
      case ColorVariants.first:
        return ColorVariants.first;
      case ColorVariants.second:
        return ColorVariants.second;
      case ColorVariants.third:
        return ColorVariants.third;
      case ColorVariants.fourth:
        return ColorVariants.fourth;
      case ColorVariants.fifth:
        return ColorVariants.fifth;
    }
  };

  const additionalColorsList = additionalColors
    ? Object.entries(additionalColors).map((color) => ({
        title: (
          <FontColorTag
            text={t(`${colorsTranslation}.${color[0]}`)}
            color={color[1] || ''}
          />
        ),
        value: getColorVariants(color[0]),
      }))
    : [];

  const fontColorOptions = [
    {
      value: ColorVariants.PRIMARY_COLOR,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.primary`)}
          color={colors.primaryColor}
        />
      ),
    },
    {
      value: ColorVariants.SECONDARY_FIRST,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.secondary1`)}
          color={colors.secondaryFirst}
        />
      ),
    },
    {
      value: ColorVariants.SECONDARY_SECOND,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.secondary2`)}
          color={colors.secondarySecond}
        />
      ),
    },
    {
      value: ColorVariants.BACKGROUND_COLOR,
      title: (
        <FontColorTag
          text={t(`${colorsTranslation}.background`)}
          color={colors.backgroundColor}
        />
      ),
    },
    ...uniqueAdditionColors,
    ...additionalColorsList,
  ];

  return (
    <FormItem className={cn(styles.col, className)} hasFeedback={true} name={formName}>
      <AppSelect disabled={disabled} options={fontColorOptions} label={label} />
    </FormItem>
  );
};
