import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { NewHouseModelDesktopWrapper } from '../NewHouseModelDesktopWrapper';
import { NewHouseModelMobileWrapper } from '../NewHouseModelMobileWrapper';
import { FC } from 'react';
import { ApplyCampaignBadgeT } from '../../../../lib/hooks/endUserHooks/useApplyCampaignHouseBadge';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
  SUB_ELEMENT_TYPE,
} from '../../../campaignDetails/hooks/useGetPositionOption';

type Props = {
  applyCampaignHouseBadge: ApplyCampaignBadgeT;
};

export const NewHouseModel: FC<Props> = ({ applyCampaignHouseBadge }) => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const { isMobile } = useContainerQuery();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4) || [];

  const { foundObjects } = useGetCampaignObjectPositions({
    page: CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE],
    elements: [
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE,
    ],
  });

  const updatedWithCampaignSlicedDemandAreas = slicedDemandAreas.map((demandArea) => {
    const campaignObjectsForDemandAreas = foundObjects.filter(
      (obj) => obj.type === SUB_ELEMENT_TYPE.DA_HEADLINE,
    );

    const shouldAddCampaignDemandAreaObject = campaignObjectsForDemandAreas.find(
      (obj) => obj.demandAreaId === demandArea.id,
    );

    const updatedCategories = demandArea.categories.map((category) => {
      const campaignObjectsForCategory = foundObjects.filter(
        (obj) => obj.type === SUB_ELEMENT_TYPE.CATEGORY,
      );

      const shouldAddCampaignCategoryObject = campaignObjectsForCategory.find(
        (obj) => obj.categoryId === category.id,
      );

      return {
        ...category,
        campaignObject: shouldAddCampaignCategoryObject
          ? shouldAddCampaignCategoryObject
          : null,
      };
    });

    return {
      ...demandArea,
      campaignObject: shouldAddCampaignDemandAreaObject
        ? shouldAddCampaignDemandAreaObject
        : null,
      categories: updatedCategories,
    };
  });

  return (
    <>
      {isMobile ? (
        <NewHouseModelMobileWrapper
          applyCampaignHouseBadge={applyCampaignHouseBadge}
          demandAreas={updatedWithCampaignSlicedDemandAreas}
          content={currentSectionContent}
        />
      ) : (
        <NewHouseModelDesktopWrapper
          applyCampaignHouseBadge={applyCampaignHouseBadge}
          demandAreas={updatedWithCampaignSlicedDemandAreas}
          content={currentSectionContent}
        />
      )}
    </>
  );
};
