import { FC } from 'react';
import { FormInstance, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FormItemName } from '../../../../../../lib/models/Form';
import { AppSelect } from '../../../../../../components/AppSelect';

import { ReactComponent as BasketIcon } from '../../../../../../assets/icons/bakset-icon.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy.svg';

import styles from './index.module.scss';
import {
  SUB_ELEMENT_TYPE,
  useGetPositionOption,
} from '../../../../hooks/useGetPositionOption';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../../../../components/IconButton';
import { StoreValue } from 'rc-field-form/lib/interface';
import { nanoid } from '@reduxjs/toolkit';

const translationLabels = 'pages.campaigns.campaignTypeSpecific.form.labels';

const { Text } = Typography;

type Props = {
  remove: (number: number) => void;
  add: (defaultValue?: StoreValue, insertIndex?: number) => void;
  form: FormInstance;
  formName: number;
};

export const CampaignObjectPositionFormItem: FC<Props> = ({
  remove,
  form,
  formName,
  add,
}) => {
  const { t } = useTranslation();
  const onRemove = () => {
    remove(formName);
  };

  const {
    objectsOptions,
    pagesOptions,
    formSectionOptions,
    formElementOptions,
    formSubElementOptions,
  } = useGetPositionOption({ form, formName });

  const onCopy = async () => {
    const foundItem = form.getFieldValue([
      FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
      formName,
    ]);

    add({
      ...foundItem,
      [(FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ID)]: nanoid(),
    });
  };

  const cleanFieldsOnChange = (
    currentItem:
      | FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT
      | FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION
      | FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE,
  ) => {
    const list = form.getFieldValue([
      FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
    ]);

    const updatedList = list.map((item: any, idx: number) => {
      if (idx === formName) {
        if (currentItem === FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT) {
          return {
            ...item,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SUB_ELEMENT]: null,
          };
        } else if (currentItem === FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION) {
          return {
            ...item,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SUB_ELEMENT]: null,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT]: null,
          };
        } else if (currentItem === FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE) {
          return {
            ...item,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SUB_ELEMENT]: null,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT]: null,
            [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION]: null,
          };
        }
      }
      return item;
    });

    form.setFieldsValue({
      [FormItemName.CAMPAIGN_TYPE_SPECIFIC]: {
        [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST]: updatedList,
      },
    });
  };

  return (
    <div className={styles.container}>
      <Text className={styles.number}>{formName + 1}</Text>
      <FormItem
        className={styles.col}
        hasFeedback={true}
        name={[formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_NAME]}
      >
        <AppSelect
          options={objectsOptions}
          label={t(`${translationLabels}.objectName`)}
        />
      </FormItem>

      <FormItem
        className={styles.col}
        hasFeedback={true}
        name={[formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE]}
      >
        <AppSelect
          options={pagesOptions}
          label={t(`${translationLabels}.page`)}
          onChange={(selected: { title: string; value: number | string }) => {
            form.setFieldValue(
              [formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE],
              selected,
            );

            cleanFieldsOnChange(FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE);
          }}
        />
      </FormItem>

      <FormItem
        className={styles.col}
        hasFeedback={true}
        name={[formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION]}
      >
        <AppSelect
          options={formSectionOptions}
          label={t(`${translationLabels}.section`)}
          onChange={(selected: { title: string; value: number | string }) => {
            form.setFieldValue(
              [formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION],
              selected,
            );

            cleanFieldsOnChange(FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION);
          }}
        />
      </FormItem>

      <FormItem
        className={styles.col}
        hasFeedback={true}
        name={[formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT]}
      >
        <AppSelect
          options={formElementOptions}
          label={t(`${translationLabels}.element`)}
          onChange={(selected: { title: string; value: number | string }) => {
            form.setFieldValue(
              [formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT],
              selected,
            );

            cleanFieldsOnChange(FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT);
          }}
        />
      </FormItem>

      <FormItem
        className={styles.col}
        hasFeedback={true}
        name={[formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SUB_ELEMENT]}
      >
        <AppSelect
          onChange={(selected: {
            title: string;
            type: SUB_ELEMENT_TYPE;
            value: number | string;
          }) => {
            form.setFieldValue(
              [formName, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SUB_ELEMENT],
              selected,
            );
          }}
          options={formSubElementOptions}
          label={t(`${translationLabels}.subElement`)}
        />
      </FormItem>

      <IconButton
        onClick={onRemove}
        className={styles.removeButton}
        icon={<BasketIcon />}
      />

      <IconButton onClick={onCopy} className={styles.copyButton} icon={<CopyIcon />} />
    </div>
  );
};
