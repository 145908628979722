import { useMemo } from 'react';
import { ROUTER_PATHS } from '../../utils/router-paths';
import { CAMPAIGN_POSITION_PAGES_OPTIONS } from '../../../modules/campaignDetails/hooks/useGetPositionOption';
import { useLocation } from 'react-router-dom';

export const useGetCurrentPageName = () => {
  const location = useLocation();

  const currentPage = useMemo(() => {
    if (location) {
      const page = location.pathname.split('/')[1];

      if (page === ROUTER_PATHS.home.split('/').join('')) {
        return CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE;
      } else if (page === ROUTER_PATHS.houseModel.split('/').join('')) {
        return CAMPAIGN_POSITION_PAGES_OPTIONS.PRODUCT_PAGE;
      } else if (page === 'Bonus%C3%BCbersicht') {
        return CAMPAIGN_POSITION_PAGES_OPTIONS.BONUS_PAGE;
      }
    }

    return CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE;
  }, [location]);

  return { currentPage };
};
