import { FC } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { ImageSelect } from '../../../../components/ImageSelect';
import { FormItemName } from '../../../../lib/models/Form';
import { useGetPictureOptions } from '../../hooks/useGetPictureOptions';
import cn from 'classnames';

type Props = {
  form: FormInstance;
  formName?: string | string[];
  disabled?: boolean;
  className?: string;
  pictureClassName?: string;
  imageWrapperClassName?: string;
};

export const PictureFormItem: FC<Props> = ({
  form,
  formName,
  disabled,
  className,
  pictureClassName,
  imageWrapperClassName,
}) => {
  const picture = Form.useWatch(formName ? formName : FormItemName.PICTURE, form);

  const { options } = useGetPictureOptions();

  const handleSelectImage = (value: UploadMediaResponseT) => {
    form.setFieldValue(formName ? formName : [FormItemName.PICTURE], value);
  };

  return (
    <div className={cn(className)}>
      <Form.Item hidden name={formName || FormItemName.PICTURE} noStyle>
        <Input disabled={disabled} type="text" />
      </Form.Item>
      <ImageSelect
        disabled={disabled}
        value={picture}
        onChange={handleSelectImage}
        options={options}
        pictureClassName={pictureClassName}
        imageWrapperClassName={imageWrapperClassName}
      />
    </div>
  );
};
