import { FormItemName } from '../../../lib/models/Form';
import { AdditionalInfoT, Operation, ProductT } from '../../../lib/types/product';
import { parseJSON } from '../../../lib/utils/helpers';
import {
  AgeRangeT,
  RegionT,
  PointsOperator,
  ProductConfiguratorFormValuesT,
  ProductStatus,
  DistrictT,
} from '../types';

export const operatorOptions = [
  { title: PointsOperator.MORE_EQUAL, value: PointsOperator.MORE_EQUAL },
  { title: PointsOperator.EQUALS, value: PointsOperator.EQUALS },
];

export const DEFAULT_RECURRING_POINTS = {
  [FormItemName.OPERATOR]: operatorOptions[0],
  [FormItemName.COVERAGE_AMOUNT]: 1,
  [FormItemName.POINTS]: 100,
};

const getProductJSONData = ({
  primaryButton,
  secondaryButton,
  thirdButton,
  picture,
  bulletPoints,
}: Pick<
  ProductConfiguratorFormValuesT,
  | FormItemName.PRIMARY_BUTTON
  | FormItemName.SECONDARY_BUTTON
  | FormItemName.THIRD_BUTTON
  | FormItemName.PICTURE
  | FormItemName.BULLET_POINTS
>) => {
  const formattedPrimaryButton = {
    ...primaryButton,
    form: primaryButton?.form?.value,
    operation: primaryButton?.operation?.value || Operation.IFRAME,
  };
  const formattedSecondaryButton = {
    ...secondaryButton,
    form: secondaryButton?.form?.value,
    operation: secondaryButton?.operation?.value || Operation.IFRAME,
  };

  const formattedThirdButton = {
    ...thirdButton,
    form: thirdButton?.form?.value,
    operation: thirdButton?.operation?.value || Operation.IFRAME,
  };

  const additionalInfo = JSON.stringify({
    primaryButton: formattedPrimaryButton,
    secondaryButton: formattedSecondaryButton,
    thirdButton: formattedThirdButton,
    picture,
    bulletPoints: bulletPoints?.map((item) => item?.trim()).filter((item) => item),
  });

  return additionalInfo;
};

export const prepareProductFormData = (values: ProductConfiguratorFormValuesT) => {
  const {
    priority,
    primaryButton,
    secondaryButton,
    thirdButton,
    category,
    activated,
    price,
    picture,
    bulletPoints,
    recurringPoints,
    ageRange,
    region,
    district,
    ...rest
  } = values;

  const additionalInfo = getProductJSONData({
    primaryButton,
    secondaryButton,
    thirdButton,
    picture,
    bulletPoints,
  });

  return {
    ...rest,
    additionalInfo,
    price: {
      ...price,
      amount: price.amount ? String(price.amount) : undefined,
      currency: price.currency?.value,
    },
    ageRange: ageRange?.ranges?.length ? JSON.stringify(ageRange) : undefined,
    region: region?.regions?.length ? JSON.stringify(region) : undefined,
    district: district?.districts?.length ? JSON.stringify(district) : undefined,
    recurringPoints: recurringPoints?.map((item) => ({
      ...item,
      operator: item?.operator?.value,
    })),
    activated: activated.value === ProductStatus.ACTIVATED,
    priority: priority?.value,
    categoryId: category.value,
  };
};

export const getFormFieldsData = (product: ProductT) => {
  const parsedData: AdditionalInfoT = parseJSON(product.additionalInfo);
  const region: RegionT = parseJSON(product.region);
  const ageRange: AgeRangeT = parseJSON(product.ageRange);
  const district: DistrictT = parseJSON(product.district);

  return {
    [FormItemName.PRODUCT_ID]: product.productId,
    [FormItemName.BADGE_ID]: product?.badgeId,
    [FormItemName.ASSOCIATED_PRODUCT]: product?.associatedProduct,
    [FormItemName.ACTIVATED]: {
      value: product.activated ? ProductStatus.ACTIVATED : ProductStatus.DEACTIVATED,
    },
    [FormItemName.STATUS_COMMENT]: product.statusComment,
    [FormItemName.CATEGORY]: { value: product.categoryId },
    [FormItemName.PRIORITY]: product.priority ? { value: product.priority } : undefined,
    [FormItemName.EMAIL]: product.email,
    [FormItemName.LINK]: product.link,
    [FormItemName.VISIBILITY]: product.visibility,
    [FormItemName.NAME]: product.name,
    [FormItemName.SUBLINE]: product.subline,
    [FormItemName.DESCRIPTION]: product.description,
    [FormItemName.SCORING_RANGES]: product.scoringRanges,
    [FormItemName.POINTS]: product.points,
    [FormItemName.RECURRING_POINTS]: product?.recurringPoints?.map((item) => ({
      ...item,
      operator: { title: item.operator, value: item.operator },
    })),
    [FormItemName.PRICE]: {
      [FormItemName.AMOUNT]: product.price?.amount,
      [FormItemName.CURRENCY]: { value: product.price?.currency },
      [FormItemName.PRICE_DESCRIPTION]: product.price?.priceDescription,
    },
    [FormItemName.AGE_RANGE]: ageRange,
    [FormItemName.REGION]: region,
    [FormItemName.DISTRICT]: district,
    [FormItemName.PICTURE]: parsedData?.picture,
    [FormItemName.PRIMARY_BUTTON]: {
      ...parsedData?.primaryButton,
      [FormItemName.OPERATION]: { value: parsedData.primaryButton.operation },
    },
    [FormItemName.SECONDARY_BUTTON]: {
      ...parsedData?.secondaryButton,
      [FormItemName.OPERATION]: { value: parsedData.secondaryButton.operation },
    },
    [FormItemName.THIRD_BUTTON]: {
      ...parsedData?.thirdButton,
      [FormItemName.OPERATION]: { value: parsedData.thirdButton?.operation },
    },
    [FormItemName.BULLET_POINTS]: parsedData?.bulletPoints,
  };
};
