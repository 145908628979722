import { useTranslation } from 'react-i18next';
import { useCampaignDateColumn } from './useCampaignDateColumn';
import { CAMPAIGN_COLUMNS } from '../helpers/consts';

const title = 'pages.campaigns.campaignTable.columns.endDate';

export function useEndDateColumn() {
  const { t } = useTranslation();

  return useCampaignDateColumn({
    arrangedId: CAMPAIGN_COLUMNS.END_DATE,
    title: t(title),
    filterFromName: 'endDateFrom',
    filterToName: 'endDateTo',
    columnProps: {
      width: 240,
    },
  });
}
