import { EndUserWishlistHouseModelMobile } from '../EndUserWishlistHouseModelMobile';
import { EndUserWishlistHouseModelDesktop } from '../EndUserWishlistHouseModelDesktop';
import { FC, useMemo } from 'react';
import { WishlistPageT } from '../../../../lib/types/sections';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
} from '../../../campaignDetails/hooks/useGetPositionOption';

type Props = {
  content?: WishlistPageT;
};

export const EndUserWishlistHouseModel: FC<Props> = ({ content }) => {
  const { wishlistProducts, demandArea } = useAppSelector(({ endUser }) => endUser);
  const { isMobile } = useContainerQuery();

  const { foundObjects } = useGetCampaignObjectPositions({
    page: CAMPAIGN_POSITION_PAGES_OPTIONS.WISHLIST_PAGE,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE],
    elements: [CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE],
  });

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);

  const updatedSlicedDemandAreasWithCampaignObjects = useMemo(() => {
    if (foundObjects.length && slicedDemandAreas) {
      return slicedDemandAreas.map((demandArea) => {
        const foundCampaignObject = foundObjects.find(
          (obj) => obj.demandAreaId === demandArea.id,
        );

        return {
          ...demandArea,
          campaignObject: foundCampaignObject ? foundCampaignObject : null,
        };
      });
    } else {
      return slicedDemandAreas;
    }
  }, [foundObjects, slicedDemandAreas]);

  return (
    <>
      {isMobile ? (
        <EndUserWishlistHouseModelMobile
          content={content}
          demandAreas={updatedSlicedDemandAreasWithCampaignObjects}
        />
      ) : (
        <EndUserWishlistHouseModelDesktop
          content={content}
          wishlistData={wishlistProducts}
          demandAreas={updatedSlicedDemandAreasWithCampaignObjects}
        />
      )}
    </>
  );
};
