import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

import { IconButton } from '../../../components/IconButton';
import { ReactComponent as ChevronRight } from './../../../assets/icons/chevron-right-empty-fill.svg';

import { useAppDispatch } from '../../../lib/store/hooks';
import { setSelectedCampaign } from '../../../lib/store/slices/campaignsSlice';
import { CampaignT } from '../../../lib/types/campaigns';
import { useTranslation } from 'react-i18next';

const title = 'pages.campaigns.campaignTable.columns.edit';

export const useCampaignArrowButtonColumn = (handleClickCampaign: () => void) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickChevron = (row: CampaignT) => {
    dispatch(setSelectedCampaign(row));
    handleClickCampaign();
  };

  return {
    title: <TableHeaderCell withArrange={false} title={t(title)} />,
    // width: 100,
    render: (_: unknown, row: CampaignT) => {
      return (
        <div>
          <IconButton
            icon={<ChevronRight fill="red" />}
            onClick={() => onClickChevron(row)}
          />
        </div>
      );
    },
  };
};
