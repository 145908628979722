import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppLocalStorage, appLogout } from '../../utils/helpers';
import { ACCESS_TOKEN_NAME, END_USER_HARDCODED_DOMAIN, REFRESH_TOKEN_NAME } from '../../utils/constants';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { store } from '..';

const BASE_URL = 'https://dev.hvm.impltech.online';
// const BASE_URL = 'https://backend.preprod.hausversorger.de';
// const BASE_URL = 'https://backend.hausversorger.de';

const PRIVATE_LOCAL_API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST || BASE_URL}/api`;

export const AuthURI = {
  LOG_IN: 'authv2/authenticateV2',
  END_USER_LOG_IN: 'end-user/authv2/authenticateV2',
  END_USER_UPDATE_ACCESS_TOKEN: 'end-user/authv2/authenticateV2/refreshtoken',
  UPDATE_ACCESS_TOKEN: 'authv2/authenticateV2/refreshtoken',
  RESET_PASSWORD_INITIATING: 'reset-password-request',
  RESET_PASSWORD: 'reset-password',
  RESTORE_PASSWORD: 'end-user/public/restore-password',
  CONFIRM_RESET_PASSWORD: 'end-user/public/confirm-reset-password',
  END_USER_SIGN_UP: 'end-user/public/registration',
  RESEND_MAIL: 'end-user/public/resend-registration-email',
  EMAIL_APPROVE: 'end-user/public/email-approve',
  ACCOUNT: 'account',
  SET_PASSWORD: 'account/set-password',
};

export const TAGS_KEY = {
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  TENANTS: 'TENANTS',
  BRAND_KIT: 'BRAND_KIT',
  GLOBAL_SETTINGS: 'GLOBAL_SETTINGS',
  BRAND_KIT_FONTS: 'BRAND_KIT_FONTS',
  DESIGN_SETTINGS: 'DESIGN_SETTINGS',
  PROJECTS: 'PROJECTS',
  PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
  DEMAND_AREA: 'DEMAND_AREA',
  PRODUCTS: 'PRODUCTS',
  BONUS_CATALOG: 'BONUS_CATALOG',
  STATUS_LEVEL: 'STATUS_LEVEL',
  END_USER_WISHLIST: 'END_USER_WISHLIST',
  END_USER_DEMAND_AREAS: 'END_USER_DEMAND_AREAS',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  RULE: 'RULE',
  RULE_TYPE: 'RULE_TYPE',
  BADGES: 'BADGES',
  FILES: 'FILES',
  CAMPAIGNS: 'CAMPAIGNS',
};

const TagsArray = Object.values(TAGS_KEY);

const checkShouldUpdateAccessToken = (error: FetchBaseQueryError | undefined, args: string | FetchArgs) => {
  const skippedUrls = Object.values(AuthURI);

  if (typeof args != 'string' && 'url' in args) {
    const isAllowedUrl = !skippedUrls.includes(args.url);

    return error?.status === 401 && isAllowedUrl;
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: PRIVATE_LOCAL_API_BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = AppLocalStorage.getItem({ key: ACCESS_TOKEN_NAME });

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const shouldUpdateAccessToken = checkShouldUpdateAccessToken(result?.error, args);

  if (shouldUpdateAccessToken) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const rootStore = store.getState();

      const { hostname } = window.location;
      const domains = rootStore.endUser.domains;
      const isEndUserFlow =
        domains?.length === 0
          ? false
          : hostname === END_USER_HARDCODED_DOMAIN || domains?.every((item) => item.name !== hostname);

      const refreshToken = AppLocalStorage.getItem({ key: REFRESH_TOKEN_NAME });

      try {
        const { data }: any = await baseQuery(
          {
            method: 'POST',
            url: isEndUserFlow ? AuthURI.END_USER_UPDATE_ACCESS_TOKEN : AuthURI.UPDATE_ACCESS_TOKEN,
            body: { refreshToken },
            headers: isEndUserFlow
              ? {
                  'X-TENANT': String(rootStore.endUser.tenantAndProject?.tenantId),
                }
              : {},
          },
          api,
          extraOptions,
        );

        if (data && 'accessToken' in data) {
          AppLocalStorage.setItem(ACCESS_TOKEN_NAME, data?.accessToken);

          result = await baseQuery(args, api, extraOptions);
        } else {
          appLogout(api.dispatch);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: TagsArray,
});
