import { useAppSelector } from '../../store/hooks';

export type ApplyCampaignBadgeT = {
  isBadgeEnabled: boolean;
  badgeImageUrl: string;
  onScrollToTeaser: () => void;
};

export const useApplyCampaignHouseBadge = (): ApplyCampaignBadgeT => {
  const { campaignSettings } = useAppSelector(({ endUser }) => endUser);

  const onScrollToTeaser = () => {
    const element = document.querySelector('#teaser');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return {
    isBadgeEnabled: !!campaignSettings?.isBadgeEnabled,
    badgeImageUrl: campaignSettings?.campaignBadgeImage.s3Url || '',
    onScrollToTeaser,
  };
};
