import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as IconCheck } from '../../../../assets/icons/checkmark-thin.svg';
import { IconT } from '../../../designSettings/types';

import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';
import { useIsEviTenant } from '../../../../lib/hooks/endUserHooks/useIsEviTenant';
import { FoundElementT } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

type Props = {
  name: string;
  icon: IconT;
  pointUnits?: string;
  active?: boolean;
  points?: number;
  categoryColor?: string;
  cardWidth?: number;
  onClick: () => void;
  campaignObject?: FoundElementT | null;
};

export const CategoryCard: FC<Props> = ({
  active,
  name,
  icon,
  points,
  pointUnits,
  categoryColor,
  cardWidth,
  onClick,
  campaignObject,
}) => {
  const isEviTenant = useIsEviTenant();

  return (
    <div
      role="presentation"
      onClick={onClick}
      className={cx(styles.wrapper, { [styles.active]: active })}
      style={{ color: categoryColor, width: cardWidth }}
    >
      {active ? (
        <IconCheck className={styles.iconCheck} stroke={categoryColor} />
      ) : (
        <EndUserTypography
          component="Text"
          type="headlineFourth"
          className={styles.points}
        >
          {isEviTenant ? '' : '+'} {points} {pointUnits?.[0]}
        </EndUserTypography>
      )}
      {campaignObject ? (
        <CampaignObjectImage
          campaignObject={campaignObject}
          imageClassName={styles.campaignImage}
          completedIconClassName={styles.campaignImageCompletedIcon}
        />
      ) : (
        <IconComponent
          className={styles.iconCategory}
          iconData={icon}
          color="currentColor"
        />
      )}

      <EndUserTypography component="Text" type="headlineFifth" className={styles.name}>
        {name}
      </EndUserTypography>
    </div>
  );
};
