import { FormInstance } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';
import {
  clearDateFilters,
  clearListFilters,
  getParsedSelectedItems,
  LIST_VALUE_PROP_NAME,
} from '../../../lib/utils/arranges-helpers';
import { useMemo } from 'react';
import { ArrangeFormItem } from '../../../lib/models/Arrange';
import { TableSortOrder } from '../../../lib/utils/constants';
import {
  setCampaignsTableFilters,
  setCampaignsTablePage,
  CampaignFiltersPayload,
} from '../../../lib/store/slices/campaignsSlice';
import { isArrayNotEmpty, isObjectEmpty } from '../../../lib/utils/helpers';
import { closeModalAfterApply } from '../../../features/table/helpers/closeModalAfterApply';
import { CAMPAIGN_COLUMNS, CAMPAIGN_STATUSES } from '../helpers/consts';

type Props = {
  arrangedId: string;
  form: FormInstance<any>;
  setFilterLabel?: any;
  getFilters?: any;
  getFilterLabel?: any;
  filterFieldName?: string;
  isParsedByValue?: boolean;
  isDate?: boolean;
  isStatusColumn?: boolean;
};

export const useCampaignArrangeRequest = ({
  arrangedId,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  filterFieldName,
  isParsedByValue = false,
  isDate = false,
  isStatusColumn,
}: Props) => {
  const dispatch = useAppDispatch();
  const sortingAndFiltersData = useAppSelector(({ campaigns }) => campaigns);
  const appLang = useAppSelector(({ auth }) => auth.appLang);

  const clearFormFilters = () => {
    isDate ? clearDateFilters(form) : clearListFilters(form);
  };

  const sortFieldName = useMemo(() => {
    return arrangedId;
  }, [arrangedId]);

  const applyArranges = async () => {
    const values = form.getFieldsValue();
    const sort = values[ArrangeFormItem.SORT_ORDER];
    const filters = getFilters?.();

    const parsedItems = getParsedSelectedItems(form);

    let searchItems = parsedItems.map((item: { value: string; label: string }) => {
      if (isParsedByValue) {
        return item.value;
      } else {
        return item.label;
      }
    });

    if (appLang === 'de' && isStatusColumn) {
      searchItems = searchItems.map((item: string) => {
        if (item === 'Aktiv') {
          return CAMPAIGN_STATUSES.ACTIVE;
        } else if (item === 'Deaktiviert') {
          return CAMPAIGN_STATUSES.DEACTIVATED;
        } else if (item === 'Bevorstehend') {
          return CAMPAIGN_STATUSES.UPCOMING;
        }
      });
    }

    const resultedFilters = {
      arrangeId: arrangedId as CAMPAIGN_COLUMNS,
      sorting: {
        sortOrder: sort as TableSortOrder,
        sortFieldName,
        withSorting: true,
      },
      filtering: {
        filterFieldName,
        filter: isDate
          ? filters
          : isStatusColumn
            ? searchItems.map((item: string) => item.toUpperCase())
            : searchItems,
        filtersWithLabels: isParsedByValue ? parsedItems : [],
      },
    } as any;

    const isFilterChanged =
      isArrayNotEmpty(resultedFilters.filtering.filter) ||
      !isObjectEmpty(resultedFilters.filtering.filter as any);

    if (isFilterChanged) {
      dispatch(setCampaignsTablePage(1));
    }

    dispatch(setCampaignsTableFilters(resultedFilters));
    setFilterLabel?.(getFilterLabel());
    closeModalAfterApply(arrangedId);
  };

  const removeFilter = async () => {
    dispatch(
      setCampaignsTableFilters({
        arrangeId: arrangedId,
        sorting: { ...sortingAndFiltersData.sorting },
        filtering: { filterFieldName: '', filter: [], filtersWithLabels: [] },
      } as CampaignFiltersPayload),
    );
    setFilterLabel?.(null);
    clearFormFilters();
  };

  const removeFilterValue = async (value: any) => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(
      (item: { [x: string]: any }) => item[LIST_VALUE_PROP_NAME] !== value,
    );

    const updatedFiltersData: CampaignFiltersPayload = {
      ...sortingAndFiltersData,
      arrangedId: sortingAndFiltersData.arrangeId,
      filtering: {
        filterFieldName: sortingAndFiltersData.filtering.filterFieldName,
        filter: filteredItems.map((item: { value: string; label: string }) => {
          if (isParsedByValue) {
            return item.value;
          } else {
            return item.label;
          }
        }),
      },
    } as CampaignFiltersPayload;

    dispatch(setCampaignsTableFilters(updatedFiltersData));

    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    const filterLabel = getFilterLabel();
    setFilterLabel?.(filterLabel);

    if (!filterLabel) {
      clearFormFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
};
