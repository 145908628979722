import { FC } from 'react';
import { FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { CampaignCounterSettingsBox } from './components/CampaignCounterSettingsBox';
import { CampaignObjectSettings } from './components/CampaignObjectSettings';
import { CampaignObjectPosition } from './components/CampaignObjectPosition';

const { Text } = Typography;

const translationTitles = 'pages.campaigns.campaignTypeSpecific.titles';

type Props = {
  form: FormInstance;
};

export const CampaignTypeSpecificSettings: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Text className={styles.title}>
        {t(`${translationTitles}.configuratorCounter`)}
      </Text>

      <CampaignCounterSettingsBox form={form} />
      <CampaignObjectSettings form={form} />
      <CampaignObjectPosition form={form} />
    </div>
  );
};
