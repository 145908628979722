import { useTranslation } from 'react-i18next';
import { Status, StatusType } from '../Status';

const activated = 'shared.status.activated';
const deactivated = 'shared.status.deactivated';

type Props = {
  activatedStatus?: boolean;
};

export function ActivatedStatusBodyCell({ activatedStatus }: Props) {
  const { t } = useTranslation();

  switch (activatedStatus) {
    case true:
      return <Status textValue={t(activated)} type={StatusType.GREEN} />;

    case false:
      return <Status textValue={t(deactivated)} type={StatusType.GRAY} />;

    default:
      return null;
  }
}
