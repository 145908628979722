import { CampaignObjectItem, CampaignT } from '../../../lib/types/campaigns';
import { parseJSON } from '../../../lib/utils/helpers';
import { FormItemName } from '../../../lib/models/Form';

export const getCampaignFromFieldsData = (
  selectedCampaign: CampaignT,
  campaignObjectsList: CampaignObjectItem[],
) => {
  const parsedJsonData = parseJSON(selectedCampaign.campaignSettings);

  return {
    [FormItemName.CAMPAIGN_GENERAL_DETAILS]: {
      [FormItemName.CAMPAIGN_NAME]: selectedCampaign.name,
      [FormItemName.CAMPAIGN_TYPE]: selectedCampaign.type,
      [FormItemName.CAMPAIGN_STATUS]: selectedCampaign.status,
      [FormItemName.CAMPAIGN_START_DATE]: selectedCampaign.startDate,
      [FormItemName.CAMPAIGN_END_DATE]: selectedCampaign.endDate,

      // json data

      [FormItemName.CAMPAIGN_DESCRIPTION]:
        parsedJsonData[FormItemName.CAMPAIGN_DESCRIPTION] || '',

      // badge

      [FormItemName.CAMPAIGN_BADGE_ENABLED]:
        parsedJsonData[FormItemName.CAMPAIGN_BADGE_ENABLED],
      [FormItemName.CAMPAIGN_BADGE_IMAGE]:
        parsedJsonData[FormItemName.CAMPAIGN_BADGE_IMAGE],
      [FormItemName.CAMPAIGN_BADGE_OPERATION]:
        parsedJsonData[FormItemName.CAMPAIGN_BADGE_OPERATION],
      [FormItemName.CAMPAIGN_BADGE_DESTINATION]:
        parsedJsonData[FormItemName.CAMPAIGN_BADGE_DESTINATION],

      // teaser

      [FormItemName.CAMPAIGN_TEASER_ENABLED]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_ENABLED],
      [FormItemName.CAMPAIGN_TEASER_HEADLINE]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_HEADLINE],
      [FormItemName.CAMPAIGN_TEASER_SUBLINE]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_SUBLINE],
      [FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR],
      [FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR
      ]
        ? {
            value: parsedJsonData[FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR],
          }
        : undefined,

      // teaser primary button

      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_ENABLED]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_ENABLED],
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT],
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_OPERATION]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_OPERATION],
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_DESTINATION]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_DESTINATION],
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR
      ]
        ? {
            value: parsedJsonData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR],
          }
        : undefined,
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR
      ]
        ? {
            value:
              parsedJsonData[
                FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR
              ],
          }
        : undefined,

      // teaser secondary button

      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_ENABLED]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_ENABLED],
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT],
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_OPERATION]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_OPERATION],
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_DESTINATION]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_DESTINATION],
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR
      ]
        ? {
            value:
              parsedJsonData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR],
          }
        : undefined,
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR
      ]
        ? {
            value:
              parsedJsonData[
                FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR
              ],
          }
        : undefined,

      // right side image

      [FormItemName.CAMPAIGN_TEASER_RIGHT_SIDE_IMAGE]:
        parsedJsonData[FormItemName.CAMPAIGN_TEASER_RIGHT_SIDE_IMAGE],
    },

    // type specific

    [FormItemName.CAMPAIGN_TYPE_SPECIFIC]: {
      [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE],
      [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_BG_COLOR]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BADGE_BG_COLOR],
      [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR]: parsedJsonData[
        FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR
      ]
        ? {
            value: parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR],
          }
        : undefined,
      [FormItemName.CAMPAIGN_TS_COUNTER_BG_OPTION]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BG_OPTION],
      [FormItemName.CAMPAIGN_TS_COUNTER_BG_COLOR]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BG_COLOR],
      [FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE],
      [FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE_MOBILE]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE_MOBILE],
      [FormItemName.CAMPAIGN_TS_COUNTER_EMPTY_IMAGE]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_EMPTY_IMAGE],
      [FormItemName.CAMPAIGN_TS_COUNTER_OPERATION]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_OPERATION],
      [FormItemName.CAMPAIGN_TS_COUNTER_URL]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_COUNTER_URL],
      [FormItemName.CAMPAIGN_TS_OBJECT_LIST]: campaignObjectsList.map((object) => ({
        [FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME]: object.name,
        [FormItemName.CAMPAIGN_TS_OBJECT_UUID]: object.uuid,
        [FormItemName.CAMPAIGN_TS_OBJECT_IMAGE]: object.image,
      })),
      [FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST]:
        parsedJsonData[FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST],
    },
  };
};
