import { FC } from 'react';
import { HeaderConfigT } from '../../../../modules/designSettings/types';
import { MobileTabletHeader } from '../MobileTabletHeader';
import { DesktopHeader } from '../DesktopHeader';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

type Props = {
  headerConfig: HeaderConfigT;
};

export const Header: FC<Props> = ({ headerConfig }) => {
  const { isDesktop: isTabletOrMobile } = useContainerQuery();

  return isTabletOrMobile ? (
    <MobileTabletHeader headerConfig={headerConfig} />
  ) : (
    <DesktopHeader headerConfig={headerConfig} />
  );
};
