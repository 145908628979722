export enum CAMPAIGN_COLUMNS {
  NAME = 'name',
  ID = 'campaignId',
  CAMPAIGN_TYPE = 'type',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  STATUS = 'status',
}

export enum CAMPAIGN_STATUSES {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  DEACTIVATED = 'DEACTIVATED',
}

export const CAMPAIGN_STATUS = {
  ACTIVATED: true,
  DEACTIVATED: false,
  UPCOMING: false,
};
