import { FC } from 'react';
import { FormInstance, Typography, Form } from 'antd';
import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { FormItemName } from '../../../../../../lib/models/Form';
import { CampaignObjectPositionFormItem } from '../CampaignObjectPositionFormItem';
import { Button } from '../../../../../../components/Button';
import { nanoid } from '@reduxjs/toolkit';

const OBJECT_LIMIT = 10;

const { Text } = Typography;

const translationTitles = 'pages.campaigns.campaignTypeSpecific.titles';

type Props = {
  form: FormInstance;
};

export const CampaignObjectPosition: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const elementsCount = Form.useWatch(
    [FormItemName.CAMPAIGN_TYPE_SPECIFIC, FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST],
    form,
  );

  const isThereAtLeastOneObject = elementsCount?.length > 0;
  const isAddButtonDisabled = elementsCount?.length === OBJECT_LIMIT;

  return (
    <div>
      <Text className={styles.title}>
        {t(`${translationTitles}.setUpPositionOfObjects`)}
      </Text>

      <AppCardBasic className={styles.card}>
        {isThereAtLeastOneObject && (
          <div className={styles.subTitleRow}>
            <Text className={styles.subTitle}>{t(`${translationTitles}.object`)}</Text>
            <Text className={styles.subTitle}>{t(`${translationTitles}.position`)}</Text>
          </div>
        )}

        <Form.List
          name={[
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              <div>
                {fields.map(({ key, name }) => (
                  <CampaignObjectPositionFormItem
                    formName={name}
                    remove={remove}
                    key={key}
                    form={form}
                    add={add}
                  />
                ))}
              </div>

              <Button
                onClick={() =>
                  add({
                    [(FormItemName.CAMPAIGN_TYPE_SPECIFIC,
                    FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ID)]: nanoid(),
                  })
                }
                type="link"
                className={styles.addPositionButton}
                disabled={isAddButtonDisabled}
              >
                {t(`${translationTitles}.addPosition`)}
              </Button>
            </>
          )}
        </Form.List>
      </AppCardBasic>
    </div>
  );
};
