import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { UsersManagementPage } from '../../../pages/UsersManagementPage';
import { TenantsPage } from '../../../pages/TenantsPage';
import { SettingsPage } from '../../../pages/SettingsPage';
import { PlatformLayout } from '../../layouts/PlatformLayout';
import { PlatformSubHeader } from '../../layouts/PlatformLayout/PlatformSubHeader';
import { DesignSettingsPage } from '../../../pages/DesignSettingsPage';
import { ProjectsPage } from '../../../pages/ProjectsPage';
import { ProductsPage } from '../../../pages/ProductsPage';
import { PlatformManagerSidebar } from '../../layouts/PlatformLayout/PlatformSidebar';
import { StatusLevelPage } from '../../../pages/StatusLevelPage';
import { EndUserDemandAreaPage } from '../../../pages/EndUserDemandAreaPage';
import { EndUserHomePage } from '../../../pages/EndUserHomePage';
import { EndUserBonusOverviewPage } from '../../../pages/EndUserBonusOverviewPage';
import { EndUserWishlistPage } from '../../../pages/EndUserWishlistPage';
import { EndUserStatusPage } from '../../../pages/EndUserStatusPage';
import { PreviewLayout } from '../../layouts/PreviewLayout';
import { RuleConfiguratorPage } from '../../../pages/RuleConfiguratorPage';
import { CampaignPage } from '../../../pages/CampaignPage';

export const AdminRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<PlatformLayout sidebar={<PlatformManagerSidebar />} />}>
        <Route
          path={ROUTER_PATHS.noMatch}
          element={<Navigate to={ROUTER_PATHS.tenants} />}
        />
        <Route path={ROUTER_PATHS.tenants} element={<TenantsPage />} />
        <Route path={ROUTER_PATHS.users} element={<UsersManagementPage />} />
        <Route element={<PlatformSubHeader />}>
          <Route
            path={`tenants/:id/${ROUTER_PATHS.projects}`}
            element={<ProjectsPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.generalSettings}`}
            element={<SettingsPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.designSettings}`}
            element={<DesignSettingsPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.products}`}
            element={<ProductsPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.statusLevel}`}
            element={<StatusLevelPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.ruleBuilder}`}
            element={<RuleConfiguratorPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.campaigns}`}
            element={<CampaignPage />}
          />
        </Route>
      </Route>
      <Route element={<PreviewLayout />}>
        <Route path={ROUTER_PATHS.home} element={<EndUserHomePage />} />

        <Route path={`${ROUTER_PATHS.houseModel}`} element={<EndUserDemandAreaPage />}>
          <Route path={`:id`} element={<EndUserDemandAreaPage />} />
        </Route>

        <Route path={ROUTER_PATHS.bonuses} element={<EndUserBonusOverviewPage />} />
        <Route path={ROUTER_PATHS.pointsOverview} element={<EndUserStatusPage />} />
        <Route path={ROUTER_PATHS.wishlist} element={<EndUserWishlistPage />} />
      </Route>
    </Routes>
  );
};
