import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { ProductT } from '../../../../lib/types/product';
import { EndUserWishlistProductsList } from '../../../../features/endUser/components/WishlistProductsList';
import { WishlistPageT } from '../../../../lib/types/sections';

import styles from './index.module.scss';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { HouseDesktop } from '../../../../components/HouseDesktop';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { NewHouseModelDesktop } from '../../../endUserHouseModelNew/components/NewHouseModelDesktop';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

type Props = {
  content?: WishlistPageT;
  demandAreas?: DemandArea[];
  wishlistData?: ProductT[];
};

export const EndUserWishlistHouseModelDesktop: FC<Props> = ({
  demandAreas,
  content,
  wishlistData,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    demandArea: modelData,
    brandKit,
    tenantAndProject,
  } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = modelData?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const modelName = modelData?.project.name;

  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Wishlist house model',
      `Open demand area`,
      `Demand Area Id: ${areaId}, Demand Area name: ${name}`,
    );

    //hardcode below
    const demandArea = demandAreas?.find((area) => area.id === areaId);

    const sortedCategories = sortCategoriesByPriority(demandArea?.categories || []);

    let category: Category | undefined = sortedCategories[0];

    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandArea?.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find(
          (category) => category.name === 'Strom',
        );

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered =
          stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    setTimeout(() => {
      dispatch(setCurrentCategory({ category }));
    }, 100);

    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);
  };
  const { isLargerDesktop } = useContainerQuery();

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    areaId: number,
    name: string,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  const handleCategoryClick = (areaId: number, category: Category) => {
    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);

    setTimeout(() => dispatch(setCurrentCategory({ category })), 100);
  };

  const updatedDemandAreas = demandAreas?.map((area: any) => {
    const updatedCategories = area.categories?.map((category: any) => {
      const wishlistCategory = wishlistData?.find(
        (wishlistCategory: any) => wishlistCategory?.category?.id === category?.id,
      );
      const isAddedToWishlist = wishlistCategory ? wishlistCategory.activated : false;

      const updatedProducts = category.products?.map((product: any) => {
        const wishlistProduct = wishlistData?.find(
          (wishlistProduct: any) => wishlistProduct.id === product.id,
        );
        const isAddedToWishlist = wishlistProduct ? wishlistProduct.activated : false;

        return { ...product, isAddedToWishlist };
      });

      return { ...category, products: updatedProducts, isAddedToWishlist };
    });

    return { ...area, categories: updatedCategories };
  });

  const isPreprodEnergieSwaben =
    tenantAndProject?.domain === 'preprod.hausversorger.de' &&
    tenantAndProject?.tenantId === 11005;

  const applyNewHouse =
    (tenantAndProject?.domain === 'dev.hvm.impltech.online' &&
      tenantAndProject?.id === 11486) ||
    (tenantAndProject?.subDomain === 'energie-schwaben' &&
      tenantAndProject?.tenantId === 11002) ||
    isPreprodEnergieSwaben;

  return (
    <div
      className={styles.section}
      style={{
        backgroundColor: brandKit?.colors.backgroundColor,
      }}
    >
      <div className={styles.mainContainer}>
        {applyNewHouse ? (
          <NewHouseModelDesktop
            handleKeyDown={handleKeyDown}
            handleAreaClick={handleAreaClick}
            demandAreas={updatedDemandAreas}
            handleCategoryClick={handleCategoryClick}
          />
        ) : (
          <HouseDesktop
            handleKeyDown={handleKeyDown}
            handleAreaClick={handleAreaClick}
            demandAreas={updatedDemandAreas}
            handleCategoryClick={handleCategoryClick}
          />
        )}
      </div>

      <div className={styles.headlineBlock}>
        {modelName !== 'mein PlusPortal' && !content?.withoutArrow ? (
          <div className={styles.arrowIcon}>
            <ArrowIcon />
          </div>
        ) : null}

        <div>
          <EndUserTypography
            type="headlineFirst"
            component="Title"
            style={{ marginBottom: '16px' }}
          >
            {content?.headline1}
          </EndUserTypography>
          <EndUserTypography type="headlineThird" component="Title">
            {content?.subline}
          </EndUserTypography>
        </div>
        {isLargerDesktop && (
          <EndUserWishlistProductsList
            wishlistData={wishlistData}
            demandAreas={demandAreas}
          />
        )}
      </div>
    </div>
  );
};
