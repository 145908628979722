import { FC, useMemo } from 'react';
import { CategoryOverviewSection } from '../../modules/endUserCategoryOverview/components/CategoryOverviewSection';
import { ProductRecommendationList } from '../../modules/endUserProductRecommendation/components/ProductRecommendationList';
import { StatusLevelSection } from '../../modules/endUserStatusLevel/components/StatusLevelSection';
import { BenefitsSection } from '../../modules/endUserBenefits/components/BenefitsSection';
import { EndUserHouseModel } from '../../modules/endUserHouseModel/components/EndUserHouseModel';
import { useAppSelector } from '../../lib/store/hooks';
import { NewHouseModel } from '../../modules/endUserHouseModelNew/components/NewHouseModel';
import { EndUserDemandAreaTiles } from '../../modules/EndUserDemandAreaTiles';
import { useApplyCampaignHouseBadge } from '../../lib/hooks/endUserHooks/useApplyCampaignHouseBadge';
import { useApplyCampaignTeaser } from '../../lib/hooks/endUserHooks/useApplyCampaignTeaser';
import { CampaignTeaser } from '../../modules/endUserCampaignTeaser/components/CampaignTeaser';

export const EndUserHomePage: FC = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const applyNewDesign =
    tenantAndProject?.subDomain === 'demo-company' &&
    tenantAndProject?.tenantId === 11289;

  const isDev =
    tenantAndProject?.domain === 'dev.hvm.impltech.online' &&
    tenantAndProject?.id === 11486;

  const isPreprodEnergieSwaben =
    tenantAndProject?.domain === 'preprod.hausversorger.de' &&
    tenantAndProject?.tenantId === 11005;

  const applyNewHouse =
    isDev ||
    (tenantAndProject?.subDomain === 'energie-schwaben' &&
      tenantAndProject?.tenantId === 11002) ||
    isPreprodEnergieSwaben;

  const applyCampaignHouseBadge = useApplyCampaignHouseBadge();
  const campaignTeaserSettings = useApplyCampaignTeaser();

  //hardcode for this tenants
  const node = useMemo(() => {
    if (
      tenantAndProject?.subDomain === 'energie-schwaben' ||
      isPreprodEnergieSwaben ||
      isDev
    ) {
      return (
        <>
          {applyNewHouse ? (
            <NewHouseModel applyCampaignHouseBadge={applyCampaignHouseBadge} />
          ) : (
            <EndUserHouseModel applyCampaignHouseBadge={applyCampaignHouseBadge} />
          )}
          <CampaignTeaser
            campaignTeaserSettings={campaignTeaserSettings}
            isGuestMode={isGuestMode}
          />
          <ProductRecommendationList />
          <CategoryOverviewSection />
          <BenefitsSection />
        </>
      );
    } else if (applyNewDesign) {
      return (
        <>
          <EndUserDemandAreaTiles />
          <StatusLevelSection />
          <CategoryOverviewSection />
          <ProductRecommendationList />
          <BenefitsSection />
        </>
      );
    } else {
      return (
        <>
          <EndUserHouseModel applyCampaignHouseBadge={applyCampaignHouseBadge} />
          <StatusLevelSection />
          <CategoryOverviewSection />
          <ProductRecommendationList />
          <BenefitsSection />
        </>
      );
    }
  }, [
    applyCampaignHouseBadge,
    applyNewDesign,
    applyNewHouse,
    campaignTeaserSettings,
    isDev,
    isPreprodEnergieSwaben,
    tenantAndProject?.subDomain,
  ]);

  return node;
};
