import { FC } from 'react';
import { FormInstance, Typography } from 'antd';
import { CampaignGeneralDetailsBox } from './components/CampaignGeneralDetailsBox';

import styles from './index.module.scss';
import { CampaignBadgeBox } from './components/CampaignBadgeBox';
import { useTranslation } from 'react-i18next';
import { CampaignTeaserBox } from './components/CampaignTeaserBox';

const { Text } = Typography;

const translationTitle = 'pages.campaigns.campaignDetails.titles';

type Props = {
  form: FormInstance;
};

export const CampaignGeneralSettings: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Text className={styles.title}>{t(`${translationTitle}.campaignDetail`)}</Text>
      <CampaignGeneralDetailsBox form={form} />
      <CampaignBadgeBox form={form} />
      <CampaignTeaserBox form={form} />
    </div>
  );
};
