import { FC } from 'react';

import styles from './index.module.scss';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import cn from 'classnames';
import { FoundElementT } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

const parseDescription = (text: string) => {
  const splitText = text.split('\n');

  return splitText.map((item) => {
    return (
      <>
        {item}
        <br />
        <br />
      </>
    );
  });
};

type Props = {
  image: UploadMediaResponseT;
  title: string;
  description: string;
  onClickOffer: () => void;
  isThereThirdOrMoreWidgets?: boolean;
  campaignObject?: FoundElementT | null;
};

export const RegionalOfferCard: FC<Props> = ({
  image,
  title,
  description,
  onClickOffer,
  isThereThirdOrMoreWidgets,
  campaignObject,
}) => {
  return (
    <div
      className={cn(
        styles.wrapper,
        isThereThirdOrMoreWidgets && styles.wrapperThirdOrMoreWidgets,
      )}
      onClick={onClickOffer}
      role="presentation"
    >
      <div
        className={cn(
          styles.pictureWrapper,
          isThereThirdOrMoreWidgets && styles.pictureWrapperThirdOrMoreWidgets,
        )}
      >
        <img src={image.s3Url} alt={image.originalFileName} />
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.titleContainer}>
          <EndUserTypography
            type="headlineFourth"
            component="Title"
            level={4}
            className={styles.title}
          >
            {title}
          </EndUserTypography>

          {campaignObject && (
            <CampaignObjectImage
              campaignObject={campaignObject}
              imageClassName={styles.objectImage}
            />
          )}
        </div>

        <EndUserTypography type="subText" component="Text" className={styles.description}>
          {parseDescription(description)}
        </EndUserTypography>
      </div>
    </div>
  );
};
