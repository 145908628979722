import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import {
  normalizePageForRequest,
  normalizePageForTable,
} from '../../../../lib/utils/helpers';
import { TablePagination } from '../../../../lib/types/pagination';
import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useCampaignNameColumn } from '../../hooks/useCampaignNameColumn';
import { GetCampaignsPayloadT } from '../../../../lib/types/campaigns';
import { useGetCampaignsQuery } from '../../../../lib/store/api/campaigns';
import { useCampaignTypeColumn } from '../../hooks/useCampaignTypeColumn';
import { useStartDateColumn } from '../../hooks/useStartDateColumn';
import { useEndDateColumn } from '../../hooks/useEndDateColumn';
import { useCampaignStatusColumn } from '../../hooks/useCampaignStatusColumn';
import { useCampaignArrowButtonColumn } from '../../hooks/useCampaingArrowButtonColumn';
import { setCampaignsTablePage } from '../../../../lib/store/slices/campaignsSlice';

type Props = {
  handleClickCampaign: () => void;
};

export const CampaignTable: FC<Props> = ({ handleClickCampaign }) => {
  const { id: tenantId, modelId } = useParams();

  const page = useAppSelector(({ campaigns }) => campaigns.page);

  const dispatch = useAppDispatch();

  const filters = useAppSelector(({ campaigns }) => {
    const arrangedId = campaigns.arrangeId;
    return { arrangedId, ...campaigns.filtering, ...campaigns.sorting };
  });

  const { data, isLoading, isFetching } = useGetCampaignsQuery(
    {
      tenantId: String(tenantId),
      projectId: modelId,
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetCampaignsPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const dataSource = data?.content || [];

  const columns = [
    useCampaignNameColumn(),
    useCampaignTypeColumn(),
    useStartDateColumn(),
    useEndDateColumn(),
    useCampaignStatusColumn(),
    useCampaignArrowButtonColumn(handleClickCampaign),
    //
  ];

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setCampaignsTablePage(page - 1));
    }
  }, [data?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setCampaignsTablePage(tablePagination.current as number));
      }}
    />
  );
};
