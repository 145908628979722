import { EndUserTypography } from '../../../../components/EndUserTypography';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import {
  useSetBonusPayoutTypeMutation,
  useSwitchActivationBonusesMutation,
} from '../../../../lib/store/api/endUser';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ReactComponent as CircleCheck } from '../../../../assets/icons/circle-check.svg';
import cn from 'classnames';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';

const translation = 'endUser.bonuses';

const TogglePayoutType = ({ bonus, handleChangePayout, isActivated, payoutType, paymentStatus }: any) => {
  const { t } = useTranslation();

  if (paymentStatus === 'DONATED') {
    return (
      <EndUserTypography component="Text" type="headlineEight">
        {t(`${translation}.donated`)}
      </EndUserTypography>
    );
  } else if (paymentStatus === 'EXPIRED') {
    return (
      <EndUserTypography
        className={cn(paymentStatus === 'EXPIRED' && styles.expired)}
        component="Text"
        type="headlineEight"
      >
        {t(`${translation}.expired`)}
      </EndUserTypography>
    );
  } else if (paymentStatus === 'PAID_OUT') {
    return (
      <EndUserTypography component="Text" type="headlineEight">
        gespendet
      </EndUserTypography>
    );
  }

  return (
    <>
      {payoutType === 'PAYOUT_AND_DONATION' ? (
        <div className={styles.radioWrapper}>
          <Radio.Group
            onChange={handleChangePayout}
            defaultValue={bonus.payoutType}
            buttonStyle="solid"
            size="small"
            disabled={!isActivated}
          >
            <Radio.Button value="PAYOUT">{t(`${translation}.payout`)}</Radio.Button>
            <Radio.Button value="DONATION">{t(`${translation}.donation`)}</Radio.Button>
          </Radio.Group>
        </div>
      ) : null}
    </>
  );
};

const ToggleActivation = ({ handleClickActivation, isActivated, isExpiredOrPaidBonus }: any) => {
  const { t } = useTranslation();

  if (isExpiredOrPaidBonus) {
    return (
      <div style={{ visibility: 'hidden' }} className={styles.buttonActivation}>
        {t(`${translation}.activateBonus`)}
      </div>
    );
  }

  return (
    <>
      {!isActivated ? (
        <Button className={styles.buttonActivation} onClick={handleClickActivation}>
          <EndUserTypography component="Text" type="headlineEight">
            {t(`${translation}.activateBonus`)}
          </EndUserTypography>
        </Button>
      ) : (
        <Button onClick={handleClickActivation} className={styles.buttonActivated} icon={<CircleCheck />}>
          <EndUserTypography component="Text" type="headlineEight">
            {t(`${translation}.activated`)}
          </EndUserTypography>
        </Button>
      )}
    </>
  );
};

export const EndUserReceivedBonusesCard = ({ bonus }: any) => {
  const { isMobile, isTablet } = useContainerQuery();
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const [isActivated, setIsActivated] = useState(false);

  const [setBonusPayoutType] = useSetBonusPayoutTypeMutation();
  const [switchActivationBonuses] = useSwitchActivationBonusesMutation();

  const handleClickActivation = async () => {
    setIsActivated(!isActivated);

    await switchActivationBonuses({
      status: !isActivated,
      bonuses: [bonus.id],
      tenantId: String(tenantAndProject?.tenantId),
      projectId: Number(tenantAndProject?.projectId),
    }).unwrap();

    sendDataToAnalytics(
      `${!isActivated ? 'Deactivate' : 'Activate'} bonus, bonusId: ${bonus.bonusCatalog.id}`,
    );
  };
  const handleChangePayout = async (e: any) => {
    await setBonusPayoutType({
      payoutType: e.target.value,
      bonusId: bonus.id,
      tenantId: String(tenantAndProject?.tenantId),
      projectId: Number(tenantAndProject?.projectId),
    }).unwrap();

    sendDataToAnalytics(`Payout bonus, bonusId: ${bonus.bonusCatalog.id}`);
  };

  useEffect(() => {
    setIsActivated(bonus.activated);
  }, [bonus]);

  const isBonusExpired = bonus.paymentStatus === 'EXPIRED';
  const isBonusPaidOut = bonus.paymentStatus === 'DONATED' || bonus.paymentStatus === 'PAID_OUT';

  return (
    <div className={styles.bonusItem}>
      <div className={styles.details}>
        <div className={styles.detailsLeftSide}>
          <div className={styles.imageContainer}>
            <img className={styles.image} alt="bonus" src={bonus.bonusCatalog.picture.s3Url} />
          </div>

          <div>
            <EndUserTypography
              className={cn(styles.bonusTitle, isBonusExpired && styles.expired)}
              component="Title"
              level={3}
              type="headlineFourth"
            >
              {bonus.bonusCatalog.title}
            </EndUserTypography>
            <EndUserTypography
              className={cn(styles.description, isBonusExpired && styles.expired)}
              component="Paragraph"
              type="subText"
            >
              {bonus.bonusCatalog.description}
            </EndUserTypography>
            {isTablet && !isMobile && (
              <div className={styles.mobileButtonsWrapper}>
                <ToggleActivation
                  handleClickActivation={handleClickActivation}
                  isActivated={isActivated}
                  isExpiredOrPaidBonus={isBonusExpired || isBonusPaidOut}
                />
                <TogglePayoutType
                  payoutType={bonus.bonusCatalog.payoutType}
                  bonus={bonus}
                  handleChangePayout={handleChangePayout}
                  isActivated={isActivated}
                  paymentStatus={bonus.paymentStatus}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.detailsRightSide}>
          {!isTablet && (
            <TogglePayoutType
              bonus={bonus}
              payoutType={bonus.bonusCatalog.payoutType}
              handleChangePayout={handleChangePayout}
              isActivated={isActivated}
              paymentStatus={bonus.paymentStatus}
            />
          )}

          <EndUserTypography
            component="Text"
            type="headlineFifth"
            className={cn(styles.bonusName, isBonusExpired && styles.expired)}
          >
            {bonus.bonusCatalog.name}
          </EndUserTypography>
          {!isTablet && (
            <ToggleActivation
              handleClickActivation={handleClickActivation}
              isActivated={isActivated}
              isExpiredOrPaidBonus={isBonusExpired || isBonusPaidOut}
            />
          )}
        </div>
      </div>
      {isMobile && (
        <div className={styles.mobileButtonsWrapper}>
          <ToggleActivation
            handleClickActivation={handleClickActivation}
            isActivated={isActivated}
            isExpiredOrPaidBonus={isBonusExpired || isBonusPaidOut}
          />

          <TogglePayoutType
            bonus={bonus}
            handleChangePayout={handleChangePayout}
            isActivated={isActivated}
            payoutType={bonus.bonusCatalog.payoutType}
            paymentStatus={bonus.paymentStatus}
          />
        </div>
      )}
    </div>
  );
};
