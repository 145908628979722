import { FC, useState } from 'react';
import { useGetEndUserCampaignCounterSettings } from '../../lib/hooks/endUserHooks/useGetEndUserCampaignCounterSettings';

import styles from './index.module.scss';

import { EndUserTypography } from '../EndUserTypography';
import { useContainerQuery } from '../../lib/hooks/endUserHooks/useContainerQuery';

import { Popover } from 'antd';
import { MobileTabletElementsList } from './components/MobileTabletElementsList';
import cn from 'classnames';
import { openLinkAndTrack } from '../../lib/utils/sendDataToAnalytics';
import { CounterBackgroundSegmentOption } from '../../modules/campaignDetails/types/types';

export const EndUserCampaignCounter: FC = () => {
  const { isDesktop: isTabletOrMobile, isMobile } = useContainerQuery();

  const campaignCounterSettings = useGetEndUserCampaignCounterSettings();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsMenuOpen(newOpen);
  };

  if (!campaignCounterSettings) {
    return null;
  }

  const {
    campaignTSCounterBgImage = '',
    campaignTSCounterBadgeHeadline = '',
    campaignTSCounterBadgeBgColor,
    campaignTSCounterBadgeHeadlineColor,
    campaignTSCounterBgImageMobile,
    campaignTSCounterUrl,
    campaignTSCounterEmptyImage,
    activeCampaign,
    completedCampaignElements,
    isGuestMode,
    campaignTSCounterBgOption,
    campaignTSCounterBgColor,
  } = campaignCounterSettings;

  const campaignBackground =
    campaignTSCounterBgOption === CounterBackgroundSegmentOption.COLOR
      ? {
          background: `${campaignTSCounterBgColor}`,
        }
      : {
          backgroundImage: `url(${campaignTSCounterBgImage})`,
        };

  const campaignBackgroundMobileTablet =
    campaignTSCounterBgOption === CounterBackgroundSegmentOption.COLOR
      ? {
          background: `${campaignTSCounterBgColor}`,
        }
      : {
          backgroundImage: `url(${campaignTSCounterBgImageMobile})`,
        };

  const campaignElements = [...(activeCampaign?.campaignElements || [])]
    .sort((a, b) => a.elementOrder - b.elementOrder)
    .map((el) => {
      const completedEl = completedCampaignElements?.find(
        (completedEl) => completedEl.uuid === el.uuid,
      );

      return {
        ...el,
        isCompleted: !!completedEl,
        completedImage: completedEl?.image || null,
      };
    });

  const totalElementsCount = campaignElements.length || 0;
  const completedElementsCount = completedCampaignElements.length;

  const handleClickBadge = () => {
    openLinkAndTrack(campaignTSCounterUrl);
  };

  if (isGuestMode) {
    return null;
  }

  if (isTabletOrMobile) {
    return (
      <>
        <div
          role="presentation"
          className={cn(styles.overlay, isMenuOpen && styles.overlayOpen)}
          onClick={() => setIsMenuOpen(false)}
        />
        <Popover
          content={
            <MobileTabletElementsList
              title={campaignTSCounterBadgeHeadline}
              campaignBackgroundMobileTablet={campaignBackgroundMobileTablet}
              campaignElements={campaignElements}
              emptyImageUrl={campaignTSCounterEmptyImage}
              handleClickBadge={handleClickBadge}
            />
          }
          trigger="click"
          open={isMenuOpen}
          onOpenChange={handleOpenChange}
          // destroyTooltipOnHide
          placement="bottomLeft"
          arrow={false}
          rootClassName="popoverRoot"
          getPopupContainer={() => document.getElementById('root') || document.body}
          overlayStyle={
            isMenuOpen
              ? {
                  width: isMobile ? '100vw' : 'max-content',
                  maxWidth: isMobile ? '100vw' : 'max-content',
                  left: isMobile ? 0 : 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }
              : undefined
          }
          overlayInnerStyle={
            isMenuOpen
              ? {
                  padding: 0,
                  background: 'white',
                  borderRadius: 16,
                  width: isMobile ? 'calc(100vw - 32px)' : 'max-content',
                  maxWidth: '600px',
                  margin: '0 auto',
                }
              : undefined
          }
        >
          <div
            role="presentation"
            className={styles.badgeMobileTablet}
            style={{
              background: campaignTSCounterBadgeBgColor,
            }}
          >
            <EndUserTypography
              type="headlineSecond"
              className={styles.badgeCounter}
              style={{ color: campaignTSCounterBadgeHeadlineColor }}
            >
              {completedElementsCount}{' '}
              <span style={{ color: `${campaignTSCounterBadgeHeadlineColor}66` }}>
                /{totalElementsCount}
              </span>
            </EndUserTypography>
          </div>
        </Popover>
      </>
    );
  }

  return (
    <div className={styles.container} style={campaignBackground}>
      <div className={styles.elementsContainer}>
        {campaignElements.map((element) => (
          <div
            className={cn(
              styles.elementItem,
              !element.isCompleted &&
                campaignTSCounterBgOption === CounterBackgroundSegmentOption.IMAGE &&
                styles.hiddenContainer,
            )}
            key={element.id}
          >
            {element.isCompleted ? (
              <img
                alt="found element"
                src={element.completedImage?.s3Url}
                className={styles.foundElement}
              />
            ) : (
              <img
                className={styles.emptyPlaceholder}
                src={campaignTSCounterEmptyImage}
                alt="empty palceholder"
              />
            )}
          </div>
        ))}
      </div>

      <div
        role="presentation"
        onClick={handleClickBadge}
        className={styles.badge}
        style={{
          background: campaignTSCounterBadgeBgColor,
        }}
      >
        <EndUserTypography
          type="headlineSecond"
          className={styles.badgeTitle}
          style={{ color: campaignTSCounterBadgeHeadlineColor }}
        >
          {campaignTSCounterBadgeHeadline}
        </EndUserTypography>

        <EndUserTypography
          type="headlineSecond"
          className={styles.badgeCounter}
          style={{ color: campaignTSCounterBadgeHeadlineColor }}
        >
          {completedElementsCount}{' '}
          <span style={{ color: `${campaignTSCounterBadgeHeadlineColor}66` }}>
            /{totalElementsCount}
          </span>
        </EndUserTypography>
      </div>
    </div>
  );
};
