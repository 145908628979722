import { useEffect, useMemo } from 'react';
import {
  useGetBrandKitSettingsForEndUserQuery,
  useGetDesignSettingsForEndUserQuery,
  useGetEndUserBonusCatalogQuery,
  useGetFinderRecommendationProductsQuery,
  useGetSectionQuery,
  useGetWishListQuery,
} from '../../store/api/endUser';
import { BrandKitThemeConfiguration } from '../../types/colors&fonts';
import {
  setBrandKit,
  setCurrentFinder,
  setDemandAreas,
  setDesignSettings,
  setFinderProducts,
  setInvisibleCategoryProducts,
  setSections,
  setWishlistProducts,
  updateModelStatus,
} from '../../store/slices/endUserSlice';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetEndUserDemandAreas } from './useGetEndUserDemandAreas';
import { useFetchAllProducts } from './useFetchAllProducts';
import { parseJSON } from '../../utils/helpers';
import {
  filterProductByAge,
  filterProductByDistrict,
  filterProductByPostalCode,
  getEndUserAge,
  mapSections,
} from '../../utils/end-user-helpers';
import { SectionsSliceT } from '../../types/sections';
import { useFetchStatusLevels } from './useFetchStatusLevels';
import { loadFonts } from '../../utils/load-fonts';
import { useGetFontsQuery } from '../../store/api/brandKit';
import { useCalculateWishListPoints } from './useCalculateWishListPoints';
import { ProductT } from '../../types/product';
import { useFetchBonusesFromWishlist } from './useFetchBonusesFromWishlist';
import { LANGUAGE } from '../../i18n/types';
import { useTranslation } from 'react-i18next';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../utils/constants';
import { useEndUserGetActiveCampaigns } from './useEndUserGetActiveCampaigns';
import { useGetCompletedCampaignElements } from './useGetCompletedElements';
import { useGetPublicActiveCampaigns } from './useGetPublicActiveCampaigns';

export const useGetEndUserData = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const { i18n } = useTranslation();

  const tenantId = tenantAndProject?.tenantId;
  const modelId = tenantAndProject?.projectId;

  const dispatch = useAppDispatch();

  useEndUserGetActiveCampaigns();
  useGetCompletedCampaignElements();
  useGetPublicActiveCampaigns();

  const { data: finderProductRecommendations } = useGetFinderRecommendationProductsQuery(
    {
      tenantId: String(tenantId),
    },
    {
      skip: isGuestMode,
    },
  );

  const { data: designSettingsData, isLoading: isLoadingDesignSettings } =
    useGetDesignSettingsForEndUserQuery({
      tenantId: tenantId,
      projectId: modelId,
    });

  useGetEndUserBonusCatalogQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
  });

  const { data: brandKitData, isLoading: isLoadingBrandKit } =
    useGetBrandKitSettingsForEndUserQuery({
      tenantId: tenantId,
      projectId: modelId,
    });

  const { data: endUserData, isLoading: isLoadingDemandAreas } =
    useGetEndUserDemandAreas();

  const { data: sectionsData, isLoading: isLoadingSections } = useGetSectionQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
  });

  const { data: wishListProductsData } = useGetWishListQuery(
    {
      tenantId: String(tenantId),
      projectId: String(modelId),
    },
    { skip: isGuestMode },
  );

  const { isLevelsLoading } = useFetchStatusLevels();

  const { data: fontsFromServer, isLoading: isLoadingFonts } = useGetFontsQuery(
    {
      tenantId: String(tenantId),
    },
    { skip: !tenantId },
  );

  const parsedDesignSettings = useMemo(() => {
    if (designSettingsData) {
      const designSettings = designSettingsData?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);

      return parsedData;
    }
  }, [designSettingsData]);

  const parsedDataBrandKit = useMemo(() => {
    if (brandKitData) {
      const brandKit = brandKitData?.[0];
      const brandKitParsedData: BrandKitThemeConfiguration = parseJSON(
        brandKit?.settings,
      );

      return brandKitParsedData;
    }
  }, [brandKitData]);

  useEffect(() => {
    dispatch(
      setFinderProducts({
        categoryId: finderProductRecommendations?.recommendedCategoryId,
        finderProducts: finderProductRecommendations?.recommendations || [],
      }),
    );

    dispatch(
      setCurrentFinder({
        categoryId: finderProductRecommendations?.recommendedCategoryId,
      }),
    );
  }, [
    dispatch,
    finderProductRecommendations?.recommendations,
    finderProductRecommendations?.recommendedCategoryId,
  ]);

  useFetchAllProducts();
  useCalculateWishListPoints();
  useFetchBonusesFromWishlist();

  useEffect(() => {
    if (parsedDesignSettings) {
      dispatch(setDesignSettings(parsedDesignSettings));
    }
  }, [dispatch, parsedDesignSettings]);

  useEffect(() => {
    if (parsedDataBrandKit) {
      dispatch(setBrandKit(parsedDataBrandKit));
    }
  }, [dispatch, parsedDataBrandKit]);

  useEffect(() => {
    if (sectionsData) {
      const sections = mapSections(sectionsData.content);
      dispatch(setSections(sections as SectionsSliceT));
    }
  }, [dispatch, sectionsData]);

  useEffect(() => {
    if (wishListProductsData) {
      dispatch(setWishlistProducts(wishListProductsData.content));
    }
  }, [dispatch, wishListProductsData]);

  useEffect(() => {
    if (endUserData) {
      const { listDemandArea, ...restUserInfo } = endUserData;
      const userAge = getEndUserAge(endUserData?.user?.birthDate);
      const userPostalCode = Number(endUserData?.user?.postalCode);
      const userDistrict = endUserData?.user?.district;

      const invisibleCategoryProducts: ProductT[] =
        endUserData?.invisibleCategories?.reduce((acc: any, category) => {
          const { products, ...categoryInfo } = category;

          const fullInfoProductsArray = products.map((item) => {
            return { ...item, category: categoryInfo };
          });

          return [...acc, ...fullInfoProductsArray];
        }, []) || [];

      const mappedListDemandArea = listDemandArea?.map((demandArea) => {
        const { categories, ...rest } = demandArea;

        const mappedCategories = categories?.map((category) => {
          const { products, ...rest } = category;

          const filteredProducts = products
            ?.filter((product) => filterProductByAge(userAge, product))
            .filter((product) => filterProductByPostalCode(userPostalCode, product))
            .filter((product) => filterProductByDistrict(userDistrict, product));

          return { ...rest, products: filteredProducts };
        });

        return {
          ...rest,
          categories: mappedCategories,
        };
      });

      if (endUserData?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME) {
        i18n.changeLanguage(LANGUAGE.FR);
      }

      dispatch(setDemandAreas({ ...restUserInfo, listDemandArea: mappedListDemandArea }));
      dispatch(setInvisibleCategoryProducts(invisibleCategoryProducts));
      dispatch(updateModelStatus(endUserData?.project?.activated));
    }
  }, [dispatch, endUserData, i18n]);

  useEffect(() => {
    if (fontsFromServer) {
      loadFonts(fontsFromServer);
    }
  }, [fontsFromServer]);

  return {
    isLoading:
      isLoadingDesignSettings ||
      isLoadingBrandKit ||
      isLoadingSections ||
      isLoadingDemandAreas ||
      isLevelsLoading ||
      isLoadingFonts,

    parsedData: parsedDesignSettings,
  };
};
