import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
  SUB_ELEMENT_TYPE,
} from '../../../modules/campaignDetails/hooks/useGetPositionOption';
import { CampaignObjectPositionElement } from '../../types/endUser';

export type FoundElementT = {
  uuid: string;
  s3Url: string;
  type: SUB_ELEMENT_TYPE | string;
  categoryId?: number | null;
  productId?: number | null;
  demandAreaId?: number | null;
  statusLevelId?: number | null;
};

type Props = {
  page: CAMPAIGN_POSITION_PAGES_OPTIONS;
  sections: CAMPAIGN_POSITION_SECTION_OPTIONS[];
  elements: CAMPAIGN_POSITION_ELEMENT_OPTIONS[];
};

export const useGetCampaignObjectPositions = ({ page, sections, elements }: Props) => {
  const { campaignSettings, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const [foundObjects, setFoundObjects] = useState<FoundElementT[]>([]);

  const getObjectType = useCallback((pos: CampaignObjectPositionElement) => {
    const subElem = pos.campaignTSObjectPositionSubElement;
    const subType = subElem?.type;
    const elemVal = pos.campaignTSObjectPositionElement.value;
    const sectionVal = pos.campaignTSObjectPositionSection.value;

    console.log(pos, 'pos');

    const baseType = {
      type: '',
      categoryId: null,
      productId: null,
      demandAreaId: null,
      statusLevelId: null,
    };

    if (subType) {
      switch (subType) {
        case SUB_ELEMENT_TYPE.CATEGORY:
          if (elemVal === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_NAVIGATION) {
            return {
              type: SUB_ELEMENT_TYPE.CATEGORY_NAVIGATION,
              categoryId: subElem.value,
            };
          }

          return { type: SUB_ELEMENT_TYPE.CATEGORY, categoryId: subElem.value };

        case SUB_ELEMENT_TYPE.PRODUCT:
          if (elemVal === CAMPAIGN_POSITION_ELEMENT_OPTIONS.BULLET_POINT) {
            return {
              type: CAMPAIGN_POSITION_ELEMENT_OPTIONS.BULLET_POINT,
              productId: subElem.value,
            };
          }
          return { type: SUB_ELEMENT_TYPE.PRODUCT, productId: subElem.value };

        case SUB_ELEMENT_TYPE.ICON:
          return { type: SUB_ELEMENT_TYPE.ICON };

        case SUB_ELEMENT_TYPE.DEMAND_AREA:
          return { type: SUB_ELEMENT_TYPE.DEMAND_AREA, demandAreaId: subElem.value };

        case CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION:
          return {
            type: CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION,
            demandAreaId: subElem.value,
          };
        case CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE:
          return {
            type: CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE,
            demandAreaId: subElem.value,
          };

        case SUB_ELEMENT_TYPE.STATUS_LEVEL:
          return { type: SUB_ELEMENT_TYPE.STATUS_LEVEL, statusLevelId: subElem.value };

        case SUB_ELEMENT_TYPE.HEADLINE:
          if (sectionVal === CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS) {
            return { type: elemVal };
          }
          return { type: SUB_ELEMENT_TYPE.HEADLINE };
      }
    }

    if (!subElem) {
      if (sectionVal === CAMPAIGN_POSITION_SECTION_OPTIONS.BENEFITS) {
        return { type: SUB_ELEMENT_TYPE.HEADLINE };
      }
      if (sectionVal === CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS) {
        return { type: elemVal };
      }
      if (
        sectionVal === CAMPAIGN_POSITION_SECTION_OPTIONS.CATEGORY_OVERVIEW &&
        elemVal === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE
      ) {
        return { type: elemVal };
      }
    }

    return baseType;
  }, []);

  useEffect(() => {
    if (!campaignSettings) return;
    if (isGuestMode) {
      setFoundObjects([]);
      return;
    }

    const { campaignTSObjectList, campaignTSObjectPositionList } = campaignSettings;

    const s3UrlByUuid = campaignTSObjectList?.reduce<Record<string, string>>(
      (acc, obj) => {
        acc[obj.campaignTSObjectUuid] = obj.campaignTSObjectImage?.s3Url ?? '';
        return acc;
      },
      {},
    );

    const matchedPositionsObjects = campaignTSObjectPositionList
      ?.filter((pos) => {
        return (
          pos.campaignTSObjectPositionPage?.value === page &&
          sections.includes(pos.campaignTSObjectPositionSection?.value) &&
          elements.includes(pos.campaignTSObjectPositionElement?.value)
        );
      })
      .map((pos) => {
        return {
          uuid: pos.campaignTSObjectPositionName.value,
          s3Url: s3UrlByUuid[pos.campaignTSObjectPositionName.value] || '',
          ...getObjectType(pos),
        };
      });

    const newString = JSON.stringify(matchedPositionsObjects);
    const oldString = JSON.stringify(foundObjects);
    if (newString !== oldString) {
      setFoundObjects(matchedPositionsObjects || []);
    }
  }, [
    campaignSettings,
    page,
    sections,
    elements,
    foundObjects,
    getObjectType,
    isGuestMode,
  ]);

  return { foundObjects };
};
