import { CampaignFromValuesT } from '../types/types';
import { FormItemName } from '../../../lib/models/Form';
import { CreateCampaignPayloadT } from '../../../lib/types/campaigns';
import { log } from 'util';

export const prepareCampaignFormData = (values: CampaignFromValuesT) => {
  const generalData = values?.[FormItemName.CAMPAIGN_GENERAL_DETAILS] || {};
  const campaignTypeSpecific = values?.[FormItemName.CAMPAIGN_TYPE_SPECIFIC] || {};

  const campaignObjectsList =
    campaignTypeSpecific?.campaignTSObjectList?.map((object) => ({
      uuid: object[FormItemName.CAMPAIGN_TS_OBJECT_UUID],
      name: object[FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME],
      image: object[FormItemName.CAMPAIGN_TS_OBJECT_IMAGE],
    })) || [];

  const prepareJsonSettings = () => {
    const data = {
      ...generalData,
      ...campaignTypeSpecific,
      [FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_BACKGROUND_COLOR],
      [FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_HEADLINE_SUBLINE_COLOR]?.value,
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_TEXT_COLOR]?.value,
      [FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_PRIMARY_BUTTON_BACKGROUND_COLOR]?.value,
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_TEXT_COLOR]?.value,
      [FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR]:
        generalData[FormItemName.CAMPAIGN_TEASER_SECONDARY_BUTTON_BACKGROUND_COLOR]
          ?.value,
      [FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR]:
        campaignTypeSpecific[FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR]
          ?.value,
    };

    return JSON.stringify(data);
  };

  const endDate = new Date(generalData[FormItemName.CAMPAIGN_END_DATE]);
  endDate.setHours(23, 59, 59, 0);

  const preparedData = {
    name: generalData[FormItemName.CAMPAIGN_NAME],
    type: generalData[FormItemName.CAMPAIGN_TYPE] || 'Suche',
    startDate: generalData[FormItemName.CAMPAIGN_START_DATE]
      ? new Date(generalData[FormItemName.CAMPAIGN_START_DATE]).toISOString()
      : new Date(),
    endDate: generalData[FormItemName.CAMPAIGN_END_DATE]
      ? endDate.toISOString()
      : new Date(),
    campaignSettings: prepareJsonSettings(),
  } as CreateCampaignPayloadT;

  return { preparedData, campaignObjectsList };
};
