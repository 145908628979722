import { EndUserDemandAreaDesktop } from '../EndUserDemandAreaDesktop';
import { EndUserDemandAreaMobile } from '../EndUserDemandAreaMobile';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { SquareShadowHouse } from '../SquareShadowHouse';
import { useMemo } from 'react';
import { SquareShadowTiles } from '../SquareShadowTiles';
import { SquareShadowHouseNew } from '../SquareShadowHouseNew';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
  SUB_ELEMENT_TYPE,
} from '../../../campaignDetails/hooks/useGetPositionOption';

export const EndUserDemandArea = () => {
  const { isMobile, isSmallSpecificTablet } = useContainerQuery();
  const { demandArea, tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);
  const allDemandAreas = demandArea?.listDemandArea || [];

  const applyNewDesign =
    tenantAndProject?.subDomain === 'demo-company' &&
    tenantAndProject?.tenantId === 11289;

  const isPreprodEnergieSwaben =
    tenantAndProject?.domain === 'preprod.hausversorger.de' &&
    tenantAndProject?.tenantId === 11005;

  const applyNewHouse =
    (tenantAndProject?.domain === 'dev.hvm.impltech.online' &&
      tenantAndProject?.id === 11486) ||
    (tenantAndProject?.subDomain === 'energie-schwaben' &&
      tenantAndProject?.tenantId === 11002) ||
    isPreprodEnergieSwaben;

  const { foundObjects } = useGetCampaignObjectPositions({
    page: CAMPAIGN_POSITION_PAGES_OPTIONS.PRODUCT_PAGE,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE],
    elements: [
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION,
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_NAVIGATION,
    ],
  });

  const updatedWithCampaignSlicedDemandAreas = slicedDemandAreas?.map((demandArea) => {
    const foundCampaignObjectsForDemandAreaNavigation = foundObjects.filter(
      (obj) => obj.demandAreaId === demandArea.id,
    );

    const shouldAddCampaignObject = foundCampaignObjectsForDemandAreaNavigation.find(
      (obj) => obj.demandAreaId === demandArea.id,
    );

    const updatedCategories = demandArea.categories.map((category) => {
      const foundCampaignObjectForCategory = foundObjects.find(
        (obj) => obj.categoryId === category.id && obj.type === SUB_ELEMENT_TYPE.CATEGORY,
      );

      const foundCampaignObjectForCategoryNavigation = foundObjects.find(
        (obj) =>
          obj.categoryId === category.id &&
          obj.type === SUB_ELEMENT_TYPE.CATEGORY_NAVIGATION,
      );

      return {
        ...category,
        campaignObject: foundCampaignObjectForCategory
          ? foundCampaignObjectForCategory
          : null,
        campaignObjectCategoryNavigation: foundCampaignObjectForCategoryNavigation
          ? foundCampaignObjectForCategoryNavigation
          : null,
      };
    });

    return {
      ...demandArea,
      campaignObject: shouldAddCampaignObject ? shouldAddCampaignObject : null,
      categories: updatedCategories,
    };
  });

  const DesktopSquareComponent = useMemo(() => {
    if (applyNewDesign) {
      return SquareShadowTiles;
    } else if (applyNewHouse) {
      return SquareShadowHouseNew;
    } else {
      return SquareShadowHouse;
    }
  }, [applyNewDesign, applyNewHouse]);

  return (
    <>
      {isMobile || isSmallSpecificTablet ? (
        <EndUserDemandAreaMobile
          demandAreas={
            applyNewDesign ? allDemandAreas : updatedWithCampaignSlicedDemandAreas
          }
        />
      ) : (
        <EndUserDemandAreaDesktop
          demandAreas={
            applyNewDesign ? allDemandAreas : updatedWithCampaignSlicedDemandAreas
          }
          ComponentProp={DesktopSquareComponent}
          applyNewDesign={applyNewDesign}
        />
      )}
    </>
  );
};
