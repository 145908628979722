import { useAppDispatch } from '../../store/hooks';
import {
  useGetBrandKitSettingsForEndUserQuery,
  useGetDesignSettingsForEndUserQuery,
  useGetGeneralSettingsForEndUserQuery,
  useGetPublicDemandAreasQuery,
  useGetPublicModelInfoQuery,
  useGetSectionQuery,
  useGetTenantAndProjectIdsQuery,
} from '../../store/api/endUser';
import { loadFonts } from '../../utils/load-fonts';
import { useEffect, useMemo } from 'react';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { BrandKitThemeConfiguration } from '../../types/colors&fonts';
import {
  setBrandKit,
  setDemandAreas,
  setDesignSettings,
  setGeneralSettings,
  setPublicModel,
  setSections,
  setTenantAndProject,
  updateModelStatus,
} from '../../store/slices/endUserSlice';
import { isProductionBuild, parseJSON } from '../../utils/helpers';
import { useGetFontsQuery } from '../../store/api/brandKit';
import { mapSections } from '../../utils/end-user-helpers';
import { SectionsSliceT } from '../../types/sections';
import {
  END_USER_FRENCH_TENANT_MODEL_NAME,
  END_USER_HARDCODED_DOMAIN,
  END_USER_HARDCODED_MODEL_URL,
} from '../../utils/constants';
import { LANGUAGE } from '../../i18n/types';
import { useTranslation } from 'react-i18next';

// Use it for develop on localhost
const modelUrlForDev = 'https://mein-zuhause-energie-schwaben.dev.hvm.impltech.online';
// const modelUrlForDev = 'https://stadtwerk-tauberfranken.preprod.hausversorger.de';
// const modelUrlForDev = 'https://stadtwerk-tauberfranken.hausversorger.de';
// const modelUrlForDev = 'https://evi-hildesheim.hausversorger.de';
// const modelUrlForDev = 'https://mein-zuhause-energie-schwaben.preprod.hausversorger.de';
// const modelUrlForDev = 'https://energie-schwaben.hausversorger.de';
// const modelUrlForDev = 'https://demo-company.hausversorger.de';

export const useGetPublicEndUserData = (isSubdomain?: boolean) => {
  const { hostname } = window.location;
  const { i18n } = useTranslation();

  const modelUrl = isProductionBuild
    ? hostname === END_USER_HARDCODED_DOMAIN
      ? END_USER_HARDCODED_MODEL_URL
      : window.location.origin
    : modelUrlForDev;

  const dispatch = useAppDispatch();

  const { data: tenantAndProjectData, isLoading: isLoadingTenantAndProjectData } =
    useGetTenantAndProjectIdsQuery({ modelUrl }, { skip: !isSubdomain });

  const { data: fontsFromServer, isLoading: isLoadingFonts } = useGetFontsQuery(
    {
      tenantId: String(tenantAndProjectData?.tenantId),
    },
    { skip: !tenantAndProjectData },
  );

  const { data: endUserData, isLoading: isLoadingEndUserData } =
    useGetPublicDemandAreasQuery(
      {
        projectId: tenantAndProjectData?.projectId,
        tenantId: tenantAndProjectData?.tenantId,
      },
      { skip: !tenantAndProjectData },
    );

  const { data: endUserModel, isLoading: isLoadingEndUserModel } =
    useGetPublicModelInfoQuery(
      {
        projectId: tenantAndProjectData?.projectId,
        tenantId: tenantAndProjectData?.tenantId,
      },
      { skip: !tenantAndProjectData },
    );

  const { data: designSettingsData, isLoading: isLoadingDesignSettings } =
    useGetDesignSettingsForEndUserQuery(
      {
        tenantId: tenantAndProjectData?.tenantId,
        projectId: tenantAndProjectData?.projectId,
      },
      {
        skip: !tenantAndProjectData,
      },
    );

  const { data: brandKitData, isLoading: isLoadingBrandKit } =
    useGetBrandKitSettingsForEndUserQuery(
      {
        tenantId: tenantAndProjectData?.tenantId,
        projectId: tenantAndProjectData?.projectId,
      },
      {
        skip: !tenantAndProjectData,
      },
    );

  const { data: sectionsData, isLoading: isLoadingSections } = useGetSectionQuery(
    {
      tenantId: String(tenantAndProjectData?.tenantId),
      projectId: String(tenantAndProjectData?.projectId),
    },
    {
      skip: !tenantAndProjectData,
    },
  );

  const { data: generalSettingsData, isLoading: isLoadingGeneralSettings } =
    useGetGeneralSettingsForEndUserQuery(
      {
        tenantId: String(tenantAndProjectData?.tenantId),
        projectId: Number(tenantAndProjectData?.projectId),
      },
      {
        skip: !tenantAndProjectData,
      },
    );

  const parsedGeneralSettingsData = useMemo(() => {
    if (generalSettingsData) {
      const generalSettings = generalSettingsData?.[0];
      const parsedGeneralSettings = parseJSON(generalSettings?.settings);

      return parsedGeneralSettings;
    }
  }, [generalSettingsData]);

  const parsedDesignSettingsData = useMemo(() => {
    if (designSettingsData) {
      const designSettings = designSettingsData?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);
      return parsedData;
    }
  }, [designSettingsData]);

  const parsedDataBrandKitData = useMemo(() => {
    if (brandKitData) {
      const brandKit = brandKitData?.[0];
      const brandKitParsedData: BrandKitThemeConfiguration = parseJSON(
        brandKit?.settings,
      );
      return brandKitParsedData;
    }
  }, [brandKitData]);

  useEffect(() => {
    if (parsedDesignSettingsData) {
      dispatch(setDesignSettings(parsedDesignSettingsData));
    }
  }, [dispatch, parsedDesignSettingsData]);

  useEffect(() => {
    if (parsedDataBrandKitData) {
      dispatch(setBrandKit(parsedDataBrandKitData));
    }
  }, [dispatch, parsedDataBrandKitData]);

  useEffect(() => {
    if (sectionsData) {
      const sections = mapSections(sectionsData.content);
      dispatch(setSections(sections as SectionsSliceT));
    }
  }, [dispatch, sectionsData]);

  useEffect(() => {
    if (endUserData) {
      if (endUserData?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME) {
        i18n.changeLanguage(LANGUAGE.FR);
      }

      dispatch(setDemandAreas(endUserData));
      dispatch(updateModelStatus(endUserData?.project?.activated));
    }
  }, [dispatch, endUserData, i18n]);

  useEffect(() => {
    if (endUserModel) {
      dispatch(setPublicModel(endUserModel));
    }
  }, [dispatch, endUserModel]);

  useEffect(() => {
    if (tenantAndProjectData) {
      dispatch(setTenantAndProject(tenantAndProjectData));
    }
  }, [dispatch, tenantAndProjectData]);

  useEffect(() => {
    if (parsedGeneralSettingsData) {
      dispatch(setGeneralSettings(parsedGeneralSettingsData));
    }
  }, [dispatch, parsedGeneralSettingsData]);

  useEffect(() => {
    if (fontsFromServer) {
      loadFonts(fontsFromServer);
    }
  }, [fontsFromServer]);

  const isPublicInfoLoading = useMemo(
    () =>
      isLoadingTenantAndProjectData ||
      isLoadingEndUserData ||
      isLoadingBrandKit ||
      isLoadingSections ||
      isLoadingDesignSettings ||
      isLoadingEndUserModel ||
      isLoadingGeneralSettings ||
      isLoadingFonts,
    [
      isLoadingTenantAndProjectData,
      isLoadingEndUserData,
      isLoadingBrandKit,
      isLoadingSections,
      isLoadingDesignSettings,
      isLoadingEndUserModel,
      isLoadingGeneralSettings,
      isLoadingFonts,
    ],
  );

  return { isPublicInfoLoading };
};
