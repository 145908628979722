import { FC, useMemo } from 'react';
import { RegionalOfferCard } from '../RegionalOfferCard';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import {
  openLinkAndTrack,
  sendDataToAnalytics,
} from '../../../../lib/utils/sendDataToAnalytics';

import styles from './index.module.scss';

import cn from 'classnames';
import { useGetCurrentPageName } from '../../../../lib/hooks/endUserHooks/useGetCurrentPageName';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
} from '../../../campaignDetails/hooks/useGetPositionOption';

export const RegionalOffersSection: FC = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.REGIONAL_OFFERS];

  const { currentPage } = useGetCurrentPageName();

  const { foundObjects } = useGetCampaignObjectPositions({
    page: currentPage,
    sections: [CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS],
    elements: [CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO],
  });

  const handleClickOffer = (link?: string, index?: number) => () => {
    if (link) {
      sendDataToAnalytics(
        'Interesse an energie schwaben',
        'Wechsel zur Website',
        index === 0
          ? 'User nimmt Kontakt zu energie schwaben auf'
          : 'User informiert sich über Meldung von energie schwaben',
      );

      openLinkAndTrack(link);
    }
  };

  const handleClickTitle = () => {
    if (demandArea?.tenant.clientName === 'Energie Schwaben') {
      const link =
        'https://www.energie-schwaben.de/fileadmin/user_upload/he3_2023_WEB_kompr.pdf';

      openLinkAndTrack(link);
    }
  };

  const isThereThirdOrMoreWidgets =
    currentSectionContent && currentSectionContent?.offers?.length >= 3;

  const updatedWithCampaignObjectsSectionContent = useMemo(() => {
    if (currentSectionContent?.offers) {
      const campaignObjectFirstContainer = foundObjects.find(
        (obj) => obj.type === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_ONE,
      );

      const campaignObjectSecondContainer = foundObjects.find(
        (obj) => obj.type === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO,
      );

      const campaignObjectThirdContainer = foundObjects.find(
        (obj) => obj.type === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_THREE,
      );

      const findCampaignObjectByIndex = (index: number) => {
        switch (index) {
          case 1:
            return campaignObjectFirstContainer;
          case 2:
            return campaignObjectSecondContainer;
          case 3:
            return campaignObjectThirdContainer;
        }
      };

      return currentSectionContent.offers.map((section, index) => ({
        ...section,
        campaignObject: findCampaignObjectByIndex(index + 1),
      }));
    }

    return [];
  }, [currentSectionContent, foundObjects]);

  if (!currentSectionContent) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <EndUserTypography
        type="headlineSecond"
        component="Title"
        level={2}
        className={styles.title}
      >
        <div role="presentation" onClick={handleClickTitle}>
          {currentSectionContent?.headline2}
        </div>
      </EndUserTypography>

      <EndUserTypography
        type="headlineThird"
        component="Title"
        level={3}
        className={styles.subTitle}
      >
        {currentSectionContent?.headline3}
      </EndUserTypography>
      <div
        className={cn(
          styles.offers,
          isThereThirdOrMoreWidgets && styles.offersThirdMoreWidgets,
        )}
      >
        {updatedWithCampaignObjectsSectionContent.map(
          ({ link, image, description, title, campaignObject }, index) => (
            <RegionalOfferCard
              isThereThirdOrMoreWidgets={isThereThirdOrMoreWidgets}
              image={image}
              campaignObject={campaignObject}
              title={title}
              description={description}
              onClickOffer={handleClickOffer(link, index)}
              key={`${title}-${index}`}
            />
          ),
        )}
      </div>
    </div>
  );
};
