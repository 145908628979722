import { Dispatch, FC } from 'react';
import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import {
  CAMPAIGN_SETTINGS_OPERATIONS,
  CampaignGeneralSettingsT,
} from '../../../../types/types';
import { Form, FormInstance, Switch, Typography } from 'antd';
import { FormItem } from '../../../../../../components/FormItem';
import { FormItemName } from '../../../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { PictureFormItem } from '../../../../../productConfigurator/components/PictureFormItem';
import { AppSelect } from '../../../../../../components/AppSelect';
import { OPERATION_OPTIONS } from '../../../../helpers/consts';

const { Text } = Typography;

const translationTitle = 'pages.campaigns.campaignDetails.titles';
const translationLabels = 'pages.campaigns.campaignDetails.form.labels';

type Props = {
  form: FormInstance;
};

export const CampaignBadgeBox: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const switchState = Form.useWatch(
    [FormItemName.CAMPAIGN_GENERAL_DETAILS, FormItemName.CAMPAIGN_BADGE_ENABLED],
    form,
  );

  const DESTINATION_OPTIONS = [
    {
      title: 'Teaser',
      value: 'Teaser',
    },
  ];

  const isDisabled = !switchState;

  return (
    <div>
      <div className={styles.switchContainer}>
        <FormItem
          hasFeedback
          name={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_BADGE_ENABLED,
          ]}
          valuePropName="checked"
          initialValue={false}
          className={styles.switchFormItem}
        >
          <Switch size="small" />
        </FormItem>
        <Text className={styles.title}>{t(`${translationTitle}.badge`)}</Text>
      </div>

      <AppCardBasic className={styles.card}>
        <PictureFormItem
          disabled={isDisabled}
          form={form}
          formName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_BADGE_IMAGE,
          ]}
        />

        <FormItem
          hasFeedback={true}
          name={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_BADGE_OPERATION,
          ]}
        >
          <AppSelect
            disabled={isDisabled}
            options={OPERATION_OPTIONS}
            label={t(`${translationLabels}.operation`)}
          />
        </FormItem>

        <FormItem
          hasFeedback={true}
          name={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_BADGE_DESTINATION,
          ]}
        >
          <AppSelect
            disabled={isDisabled}
            options={DESTINATION_OPTIONS}
            label={t(`${translationLabels}.destination`)}
          />
        </FormItem>
      </AppCardBasic>
    </div>
  );
};
