import { useTranslation } from 'react-i18next';
import { CampaignT } from '../../../lib/types/campaigns';
import { useCampaignSearchDataColumn } from './useCampaignSearchDataColumn';
import { CAMPAIGN_COLUMNS } from '../helpers/consts';
import { Typography } from 'antd';

const title = 'pages.campaigns.campaignTable.columns.campaignType';

export const useCampaignTypeColumn = () => {
  const { t } = useTranslation();

  return useCampaignSearchDataColumn({
    title: t(title),
    arrangedId: CAMPAIGN_COLUMNS.CAMPAIGN_TYPE,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 240,
      render: (name: string, row: CampaignT) => {
        return (
          <span role="presentation" style={{ cursor: 'pointer' }}>
            <Typography.Text>{name}</Typography.Text>
          </span>
        );
      },
    },
  });
};
