import { Form, FormInstance } from 'antd';
import { FormItemName } from '../../../lib/models/Form';
import { useGetProductCategoriesForFilteringQuery } from '../../../lib/store/api/productCategories';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetStatusLevelsQuery } from '../../../lib/store/api/statusLevel';
import { useGetDemandAreasQuery } from '../../../lib/store/api/demandArea';
import { useGetBonusCatalogQuery } from '../../../lib/store/api/bonusCatalog';
import { normalizePageForRequest } from '../../../lib/utils/helpers';
import { GetBonusCatalogPayloadT } from '../../../lib/types/bonusCatalog';

export enum CAMPAIGN_POSITION_PAGES_OPTIONS {
  LANDING_PAGE = 'landingPage',
  PRODUCT_PAGE = 'productPage',
  BONUS_PAGE = 'bonusPage',
  WISHLIST_PAGE = 'wishlistPage',
}

export enum CAMPAIGN_POSITION_SECTION_OPTIONS {
  HOUSE = 'house',
  STATUS_BAR = 'statusBar',
  BENEFITS = 'benefits',
  REGIONAL_OFFERS = 'regionalOffers',
  FRIENDS = 'friends',
  BONUSES = 'bonuses',
  CATEGORY_OVERVIEW = 'categoryOverview',
  PRODUCT_LIST = 'productList',
}

export enum CAMPAIGN_POSITION_ELEMENT_OPTIONS {
  CATEGORY_ICON = 'categoryIcon',
  DA_NAVIGATION = 'DANavigation',
  DA_HEADLINE = 'DAHeadline',
  HEADLINE = 'headline',
  CONTAINER_TWO = 'containerTwo',
  CONTAINER_ONE = 'containerOne',
  CONTAINER_THREE = 'containerThree',
  BONUS_NAVIGATION = 'bonusNavigation',
  BONUS_CONTAINER = 'bonusContainer',
  BULLET_POINT = 'bulletPoint',
  CATEGORY_NAVIGATION = 'categoryNavigation',
}

export enum CAMPAIGN_POSITION_HEADLINE_OPTIONS {
  HEADLINE2 = 'headline',
}
export enum CAMPAIGN_POSITION_ICON_OPTIONS {
  ICON = 'icon',
}

export enum SUB_ELEMENT_TYPE {
  PRODUCT = 'product',
  PRODUCT_HEADLINE = 'productHeadline',
  CATEGORY = 'category',
  CATEGORY_NAVIGATION = 'categoryNavigation',
  DEMAND_AREA = 'demandAreaNavigation',
  HEADLINE = 'headline',
  ICON = 'icon',
  STATUS_LEVEL = 'statusLevel',
  BONUS = 'bonus',
  DA_HEADLINE = 'DAHeadline',
}

type Props = {
  form: FormInstance;
  formName: number;
};

export const useGetPositionOption = (props: Props) => {
  const { form, formName } = props;

  const selectedPage = Form.useWatch(
    [
      FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
      Number(formName),
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_PAGE,
    ],
    form,
  );

  const selectedSection = Form.useWatch(
    [
      FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
      Number(formName),
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_SECTION,
    ],
    form,
  );

  const selectedElement = Form.useWatch(
    [
      FormItemName.CAMPAIGN_TYPE_SPECIFIC,
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_LIST,
      Number(formName),
      FormItemName.CAMPAIGN_TS_OBJECT_POSITION_ELEMENT,
    ],
    form,
  );

  const { id: tenantId, modelId } = useParams();

  const { data: productCategories } = useGetProductCategoriesForFilteringQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
  });

  const { data: statusLevelsData } = useGetStatusLevelsQuery({
    tenantId: String(tenantId),
    projectId: Number(modelId),
  });

  const { data: demandAreasData } = useGetDemandAreasQuery({
    tenantId: String(tenantId),
    modelId: String(modelId),
    page: 0,
  });

  const { data: bonusCatalog } = useGetBonusCatalogQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
    page: 0,
    size: 200,
  });

  const bonusCatalogOptions = useMemo(
    () =>
      bonusCatalog?.content.map((bonus) => ({
        value: bonus.id,
        title: bonus.title,
        type: SUB_ELEMENT_TYPE.BONUS,
      })),
    [bonusCatalog],
  );

  const statusLevelOptions = useMemo(
    () =>
      statusLevelsData?.content.map((statusLevel) => ({
        value: statusLevel?.id,
        title: statusLevel?.name,
        type: SUB_ELEMENT_TYPE.STATUS_LEVEL,
      })),
    [statusLevelsData],
  );

  const productOptions = useMemo(() => {
    return (
      productCategories?.content?.flatMap(
        (category) =>
          category?.products?.map((product) => ({
            value: product?.id,
            title: product?.name,
            type: SUB_ELEMENT_TYPE.PRODUCT,
          })) || [],
      ) || []
    );
  }, [productCategories]);

  const categoriesOptions = useMemo(
    () =>
      productCategories?.content.map((category) => ({
        value: category.id,
        title: category.name,
        type: SUB_ELEMENT_TYPE.CATEGORY,
      })),
    [productCategories?.content],
  );

  const headlineOptions = useMemo(
    () => [
      {
        value: CAMPAIGN_POSITION_HEADLINE_OPTIONS.HEADLINE2,
        title: 'Headline 2',
        type: SUB_ELEMENT_TYPE.HEADLINE,
      },
    ],
    [],
  );

  const iconOptions = useMemo(
    () => [
      {
        value: CAMPAIGN_POSITION_ICON_OPTIONS.ICON,
        title: 'Icon',
        type: SUB_ELEMENT_TYPE.ICON,
      },
    ],
    [],
  );

  const getDemandAreasOptions = (type: string) => {
    const demandAreasOptions = demandAreasData?.content.map((demandArea) => ({
      value: demandArea.id,
      title: demandArea.name,
      type: type,
    }));

    return demandAreasOptions;
  };

  const subElementOptions = useMemo(
    () => ({
      products: productOptions,
      statusLevels: statusLevelOptions,
      categories: categoriesOptions,
      headlines: headlineOptions,
      icons: iconOptions,
      bonuses: bonusCatalogOptions,
    }),
    [
      categoriesOptions,
      headlineOptions,
      iconOptions,
      productOptions,
      statusLevelOptions,
      bonusCatalogOptions,
    ],
  );

  const objectElementsCount = Form.useWatch(
    [FormItemName.CAMPAIGN_TYPE_SPECIFIC, FormItemName.CAMPAIGN_TS_OBJECT_LIST],
    form,
  );

  const objectsOptions =
    objectElementsCount?.map(
      (item: {
        [FormItemName.CAMPAIGN_TS_OBJECT_UUID]: string;
        [FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME]: string;
      }) => ({
        title: item[FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME],
        value: item[FormItemName.CAMPAIGN_TS_OBJECT_UUID],
      }),
    ) || [];

  const pagesOptions = [
    {
      title: 'Landing page',
      value: CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE,
    },
    {
      title: 'Product page',
      value: CAMPAIGN_POSITION_PAGES_OPTIONS.PRODUCT_PAGE,
    },
    {
      title: 'Bonus page',
      value: CAMPAIGN_POSITION_PAGES_OPTIONS.BONUS_PAGE,
    },
    {
      title: 'Wishlist page',
      value: CAMPAIGN_POSITION_PAGES_OPTIONS.WISHLIST_PAGE,
    },
  ];

  const sectionOptions = useMemo(
    () => [
      {
        title: 'House',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE,
      },
      {
        title: 'USPs',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.BENEFITS,
      },
      {
        title: 'Friends',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS,
      },
      {
        title: 'Bonuses',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.BONUSES,
      },
      {
        title: 'Category Overview',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.CATEGORY_OVERVIEW,
      },
      {
        title: 'Product list',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.PRODUCT_LIST,
      },
      {
        title: 'Status Bar',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.STATUS_BAR,
      },
      {
        title: 'Regional Offers',
        value: CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS,
      },
    ],
    [],
  );

  const elementsOptions = useMemo(
    () => [
      {
        title: 'Headline',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
      },
      {
        title: 'Category Navigation',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_NAVIGATION,
      },
      {
        title: 'Category Icon',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
      },
      {
        title: 'Bonus Container',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_CONTAINER,
      },
      {
        title: 'Bonus Navigation',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_NAVIGATION,
      },
      {
        title: 'Bullet Point',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.BULLET_POINT,
      },
      {
        title: 'Container 1',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_ONE,
      },
      {
        title: 'Container 2',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO,
      },
      {
        title: 'Container 3',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_THREE,
      },
      {
        title: 'DA Headline',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE,
      },
      {
        title: 'DA Navigation',
        value: CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION,
      },
    ],
    [],
  );

  const formSectionOptions = useMemo(() => {
    if (!selectedPage) return [];

    switch (selectedPage.value) {
      case CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE:
        return sectionOptions.filter(
          (el) =>
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.CATEGORY_OVERVIEW,
        );
      case CAMPAIGN_POSITION_PAGES_OPTIONS.PRODUCT_PAGE:
        return sectionOptions.filter(
          (el) =>
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.STATUS_BAR ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.BENEFITS ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS ||
            el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.PRODUCT_LIST,
        );
      case CAMPAIGN_POSITION_PAGES_OPTIONS.BONUS_PAGE:
        return sectionOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.BONUSES,
        );
      case CAMPAIGN_POSITION_PAGES_OPTIONS.WISHLIST_PAGE:
        return sectionOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE,
        );
      default:
        return [];
    }
  }, [sectionOptions, selectedPage]);

  const formElementOptions = useMemo(() => {
    if (!selectedSection) return [];

    switch (selectedSection.value) {
      case CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE:
        if (selectedPage.value === CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE) {
          return elementsOptions.filter(
            (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
          );
        } else if (selectedPage.value === CAMPAIGN_POSITION_PAGES_OPTIONS.PRODUCT_PAGE) {
          return elementsOptions.filter(
            (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION,
          );
        } else if (selectedPage.value === CAMPAIGN_POSITION_PAGES_OPTIONS.WISHLIST_PAGE) {
          return elementsOptions.filter(
            (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE,
          );
        }
        return elementsOptions.filter(
          (el) =>
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_NAVIGATION,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.STATUS_BAR:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.BENEFITS:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
          // ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_ONE ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_THREE,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS:
        return elementsOptions.filter(
          (el) =>
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_ONE ||
            el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_THREE,
          // ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO,
          // ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.BONUSES:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_NAVIGATION,
          // ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_CONTAINER,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.CATEGORY_OVERVIEW:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
        );
      case CAMPAIGN_POSITION_SECTION_OPTIONS.PRODUCT_LIST:
        return elementsOptions.filter(
          (el) => el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.BULLET_POINT,
          // ||
          // el.value === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
        );
      default:
        return [];
    }
  }, [elementsOptions, selectedPage, selectedSection]);

  const formSubElementOptions = useMemo(() => {
    if (!selectedElement) return [];

    switch (selectedElement.value) {
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON:
        if (selectedSection?.value === CAMPAIGN_POSITION_SECTION_OPTIONS.STATUS_BAR) {
          return [];
        }

        return subElementOptions.categories;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE:
        return getDemandAreasOptions(CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_HEADLINE);
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION:
        return getDemandAreasOptions(CAMPAIGN_POSITION_ELEMENT_OPTIONS.DA_NAVIGATION);
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_ONE:
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_TWO:
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.CONTAINER_THREE:
        if (
          selectedSection?.value === CAMPAIGN_POSITION_SECTION_OPTIONS.REGIONAL_OFFERS
        ) {
          return headlineOptions;
        }

        if (selectedSection?.value === CAMPAIGN_POSITION_SECTION_OPTIONS.FRIENDS) {
          return [];
        }

        return subElementOptions.icons;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_NAVIGATION:
        return subElementOptions.statusLevels;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.BULLET_POINT:
        return subElementOptions.products;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_NAVIGATION:
        return subElementOptions.categories;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.BONUS_CONTAINER:
        return subElementOptions.bonuses;
      case CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE:
        if (selectedSection.value === CAMPAIGN_POSITION_SECTION_OPTIONS.PRODUCT_LIST) {
          return subElementOptions.products.map((product) => ({
            ...product,
            type: SUB_ELEMENT_TYPE.PRODUCT_HEADLINE,
          }));
        } else {
          return [];
        }
      default:
        return [];
    }
  }, [
    getDemandAreasOptions,
    headlineOptions,
    selectedElement,
    selectedSection?.value,
    subElementOptions.bonuses,
    subElementOptions.categories,
    subElementOptions.icons,
    subElementOptions.products,
    subElementOptions.statusLevels,
  ]);

  return {
    objectsOptions,
    pagesOptions,
    formSectionOptions,
    formElementOptions,
    formSubElementOptions,
  };
};
