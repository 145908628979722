import { createSlice, current, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { BrandKitThemeConfiguration, ColorsList } from '../../types/colors&fonts';
import { SectionsSliceT } from '../../types/sections';
import { AdditionalChooseColors } from '../../../modules/brandKit/util/constants';
import { ProductT } from '../../types/product';
import {
  DomainT,
  EndUserDataT,
  EndUserPublicModelT,
  GetEndUserTenantAndProjectT,
  UserStatusLevelT,
  RuleActionStatusLevelT,
  EndUserCampaignT,
  CampaignSettingsT,
  CompletedCampaignElement,
} from '../../types/endUser';
import { GeneralSettingsT } from '../../types/generalSettings';
import {
  FinderProduct,
  FinderT,
  ProductCategoryT,
  SelectionType,
} from '../../types/entities';
import { StatusLevelT } from '../../types/statusLevel';
import {
  markRecommendedProductsForAllCategories,
  markRecommendedProductsForSelectedCategory,
} from '../../utils/helpers';
import { logoutAction } from '../actions';

type SliceStateT = {
  isGuestMode: boolean;
  selectedCategory: ProductCategoryT | null;
  designSettings: DesignSettingsDataT | null;
  generalSettings: GeneralSettingsT | null;
  brandKit: BrandKitThemeConfiguration | null;
  sections: SectionsSliceT | null;
  wishlistProducts: ProductT[];
  demandArea: EndUserDataT | null;
  tenantAndProject: GetEndUserTenantAndProjectT | null;
  domains: DomainT[];
  model: EndUserPublicModelT | null;
  userStatusLevel: UserStatusLevelT;
  ruleActionStatusLevel: RuleActionStatusLevelT;
  statusLevels: StatusLevelT[];
  allProducts: ProductT[];
  wishlistRuleBonusesIds: number[];
  invisibleCategoryProducts: ProductT[];
  statusLevelNotification: string | null;
  matomoEnabledByUserInGuestMode: boolean;
  currentFinder: FinderT | null;
  finderProducts: FinderProduct[];
  activeCampaign: EndUserCampaignT | null;
  campaignSettings: CampaignSettingsT | null;
  completedCampaignElements: CompletedCampaignElement[] | [];
};

const initialState: SliceStateT = {
  selectedCategory: null,
  designSettings: null,
  generalSettings: null,
  brandKit: null,
  sections: null,
  wishlistProducts: [],
  demandArea: null,
  tenantAndProject: null,
  domains: [],
  model: null,
  isGuestMode: false,
  userStatusLevel: {
    currentPoints: 0,
    currentPointsInWishlist: 0,
    wishedPoints: 0,
  },
  ruleActionStatusLevel: {
    maxAvailablePoints: null,
    extraPoints: null,
  },
  statusLevels: [],
  allProducts: [],
  wishlistRuleBonusesIds: [],
  invisibleCategoryProducts: [],
  statusLevelNotification: null,
  matomoEnabledByUserInGuestMode: false,
  currentFinder: null,
  finderProducts: [],
  // campaigns
  activeCampaign: null,
  campaignSettings: null,
  completedCampaignElements: [],
};

const endUserSlice = createSlice({
  name: 'endUserSlice',
  initialState,
  reducers: {
    //eslint-disable-next-line
    setCurrentCategory: (state, action: PayloadAction<{ category: any }>) => {
      state.selectedCategory = action.payload.category;
    },
    setDesignSettings: (state, action: PayloadAction<DesignSettingsDataT>) => {
      state.designSettings = action.payload;
    },
    setBrandKit: (state, action: PayloadAction<BrandKitThemeConfiguration>) => {
      state.brandKit = {
        ...action.payload,
        colors: { ...action.payload.colors, ...AdditionalChooseColors } as ColorsList,
      };
    },
    setSections: (state, action: PayloadAction<SectionsSliceT>) => {
      state.sections = action.payload;
    },
    setWishlistProducts: (state, action: PayloadAction<ProductT[]>) => {
      state.wishlistProducts = action.payload;
    },
    setWishlistProduct: (state, action: PayloadAction<ProductT>) => {
      const productIdx = state.wishlistProducts.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (productIdx === -1) {
        if (action.payload.category.selectionType === SelectionType.SINGLE) {
          const sameCategoryProductIdx = state.wishlistProducts.findIndex(
            (item) => item.category.id === action.payload.category.id,
          );

          if (sameCategoryProductIdx !== -1) {
            state.wishlistProducts.splice(sameCategoryProductIdx, 1);
          }
        }

        state.wishlistProducts = [...state.wishlistProducts, action.payload];
      } else {
        state.wishlistProducts.splice(productIdx, 1);
      }
    },
    setDemandAreas: (state, action: PayloadAction<EndUserDataT>) => {
      state.demandArea = action.payload;
    },
    turnOffCongratulation: (state) => {
      if (state.demandArea) {
        state.demandArea.user.congratulation = true;
      }
    },
    setInvisibleCategoryProducts: (state, action: PayloadAction<ProductT[]>) => {
      state.invisibleCategoryProducts = action.payload;
    },
    setTenantAndProject: (state, action: PayloadAction<GetEndUserTenantAndProjectT>) => {
      state.tenantAndProject = action.payload;
    },
    setDomains: (state, action: PayloadAction<DomainT[]>) => {
      state.domains = action.payload;
    },
    setPublicModel: (state, action: PayloadAction<EndUserPublicModelT>) => {
      state.model = action.payload;
    },
    updateModelStatus: (state, action: PayloadAction<boolean>) => {
      if (state.model) {
        state.model.activated = action.payload;
      }
    },
    setGuestMode: (state, action: PayloadAction<boolean>) => {
      state.isGuestMode = action.payload;
    },
    setGeneralSettings: (state, action: PayloadAction<GeneralSettingsT>) => {
      state.generalSettings = action.payload;
    },
    setUserStatusLevel: (
      state,
      action: PayloadAction<Omit<UserStatusLevelT, 'wishedPointsBeforeUpdatingWishList'>>,
    ) => {
      state.userStatusLevel = { ...state.userStatusLevel, ...action.payload };
    },
    setRuleActionStatusLevel: (state, action: PayloadAction<RuleActionStatusLevelT>) => {
      state.ruleActionStatusLevel = action.payload;
    },
    setAllProducts: (state, action: PayloadAction<ProductT[]>) => {
      const finderProducts = state.finderProducts;

      if (finderProducts.length > 0) {
        if (state.currentFinder?.categoryId) {
          state.allProducts = markRecommendedProductsForSelectedCategory(
            current(state.allProducts),
            state.currentFinder.categoryId,
            finderProducts,
          );
        } else {
          state.allProducts = markRecommendedProductsForAllCategories(
            action.payload,
            finderProducts,
          );
        }
      } else {
        state.allProducts = action.payload;
      }
    },
    setStatusLevels: (state, action: PayloadAction<StatusLevelT[]>) => {
      state.statusLevels = action.payload;
    },
    setWishListRuleBonuses: (state, action: PayloadAction<number[]>) => {
      state.wishlistRuleBonusesIds = action.payload;
    },
    modifyProduct: (state, action: PayloadAction<ProductT>) => {
      state.allProducts = state.allProducts.map((product) =>
        product.productId === action.payload.productId
          ? {
              ...product,
              countUserProducts: action.payload.countUserProducts,
              irrelevantSurveyReason: action.payload.irrelevantSurveyReason,
            }
          : product,
      );
    },
    setMatomoTrackingForGuest: (
      state,
      action: PayloadAction<{ trackingEnabled: boolean }>,
    ) => {
      state.matomoEnabledByUserInGuestMode = action.payload.trackingEnabled;
    },
    setCurrentFinder: (state, action: PayloadAction<FinderT | null>) => {
      state.currentFinder = action.payload;
    },
    setNotificationStatusLevel: (state, action: PayloadAction<string | null>) => {
      state.statusLevelNotification = action.payload;
    },
    setFinderProducts: (
      state,
      action: PayloadAction<{
        categoryId?: number | null;
        finderProducts: FinderProduct[];
        onNavigateCallback?: (product: ProductT) => void;
      }>,
    ) => {
      const { categoryId, finderProducts, onNavigateCallback } = action.payload;

      state.finderProducts = finderProducts;

      if (finderProducts.length > 0) {
        if (categoryId) {
          state.allProducts = markRecommendedProductsForSelectedCategory(
            current(state.allProducts),
            categoryId,
            finderProducts,
          );
        } else {
          state.allProducts = markRecommendedProductsForAllCategories(
            current(state.allProducts),
            finderProducts,
            onNavigateCallback,
            current(state.demandArea?.listDemandArea),
          );
        }
      }
    },
    setActiveCampaign: (state, action: PayloadAction<EndUserCampaignT | null>) => {
      state.campaignSettings = action.payload
        ? JSON.parse(action.payload.campaignSettings)
        : null;

      state.activeCampaign = action.payload;
    },
    setCompletedCampaignElements: (
      state,
      action: PayloadAction<CompletedCampaignElement[]>,
    ) => {
      state.completedCampaignElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(logoutAction), () => initialState);
  },
});

export const {
  setCurrentCategory,
  setDesignSettings,
  setBrandKit,
  setSections,
  setWishlistProducts,
  setWishlistProduct,
  setDemandAreas,
  turnOffCongratulation,
  setInvisibleCategoryProducts,
  setNotificationStatusLevel,
  setTenantAndProject,
  setDomains,
  setPublicModel,
  updateModelStatus,
  setGuestMode,
  setGeneralSettings,
  setUserStatusLevel,
  setAllProducts,
  setWishListRuleBonuses,
  setStatusLevels,
  setRuleActionStatusLevel,
  modifyProduct,
  setMatomoTrackingForGuest,
  setCurrentFinder,
  setFinderProducts,
  setActiveCampaign,
  setCompletedCampaignElements,
} = endUserSlice.actions;
export const endUser = endUserSlice.reducer;
