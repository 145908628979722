import { FC } from 'react';
import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance, Typography } from 'antd';

import styles from './index.module.scss';
import { FormSection } from '../../../../../../components/FormSection';
import { FormItemName } from '../../../../../../lib/models/Form';
import { CampaignButtonColorCard } from '../../../CampaignButtonColorCard';
import { FormItem } from '../../../../../../components/FormItem';
import { AppSegmented } from '../../../../../../components/AppSegmented';
import { CounterBackgroundSegmentOption } from '../../../../types/types';
import { GradientFormPicker } from '../../../GradientFormPicker';
import { PictureFormItem } from '../../../../../productConfigurator/components/PictureFormItem';
import { AppSelect } from '../../../../../../components/AppSelect';

const { Text } = Typography;

const translationTitles = 'pages.campaigns.campaignTypeSpecific.titles';
const translationLabels = 'pages.campaigns.campaignDetails.form.labels';

type Props = {
  form: FormInstance;
};

export const CampaignCounterSettingsBox: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const counterBgOption = Form.useWatch(
    [FormItemName.CAMPAIGN_TYPE_SPECIFIC, FormItemName.CAMPAIGN_TS_COUNTER_BG_OPTION],
    form,
  );

  const OPERATION_OPTIONS = [
    {
      title: 'Link',
      value: 'Link',
    },
  ];

  const backgroundOptions = [
    {
      label: t(`${translationLabels}.color`),
      value: CounterBackgroundSegmentOption.COLOR,
    },
    {
      label: t(`${translationLabels}.image`),
      value: CounterBackgroundSegmentOption.IMAGE,
    },
  ];

  return (
    <AppCardBasic className={styles.card}>
      <Text className={styles.title}>{t(`${translationTitles}.badge`)}</Text>

      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE,
          ],
        }}
        textFieldProps={{
          label: t(`${translationLabels}.headline`),
          className: styles.inputText,
        }}
      />

      <div className={styles.row}>
        <GradientFormPicker
          form={form}
          label={t(`${translationLabels}.backgroundColor`)}
          formName={[
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_COUNTER_BADGE_BG_COLOR,
          ]}
        />

        <CampaignButtonColorCard
          formName={[
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_COUNTER_BADGE_HEADLINE_COLOR,
          ]}
          label={t(`${translationLabels}.headlineColor`)}
        />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.row}>
          <Text className={styles.title}>{t(`${translationTitles}.background`)}</Text>
          <FormItem
            className={styles.appSegment}
            hasFeedback
            name={[
              FormItemName.CAMPAIGN_TYPE_SPECIFIC,
              FormItemName.CAMPAIGN_TS_COUNTER_BG_OPTION,
            ]}
          >
            <AppSegmented options={backgroundOptions} />
          </FormItem>
        </div>

        {counterBgOption === CounterBackgroundSegmentOption.COLOR ? (
          <GradientFormPicker
            form={form}
            label={t(`${translationLabels}.backgroundColor`)}
            formName={[
              FormItemName.CAMPAIGN_TYPE_SPECIFIC,
              FormItemName.CAMPAIGN_TS_COUNTER_BG_COLOR,
            ]}
          />
        ) : (
          <>
            <div>
              <Text className={styles.title}>
                {t(`${translationTitles}.desktopBackgroundImage`)}
              </Text>

              <PictureFormItem
                form={form}
                formName={[
                  FormItemName.CAMPAIGN_TYPE_SPECIFIC,
                  FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE,
                ]}
              />
            </div>

            <div>
              <Text className={styles.title}>
                {t(`${translationTitles}.mobileBackgroundImage`)}
              </Text>

              <PictureFormItem
                form={form}
                formName={[
                  FormItemName.CAMPAIGN_TYPE_SPECIFIC,
                  FormItemName.CAMPAIGN_TS_COUNTER_BG_IMAGE_MOBILE,
                ]}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.wrapper}>
        <Text className={styles.title}>{t(`${translationTitles}.emptyStatus`)}</Text>

        <div>
          <PictureFormItem
            form={form}
            formName={[
              FormItemName.CAMPAIGN_TYPE_SPECIFIC,
              FormItemName.CAMPAIGN_TS_COUNTER_EMPTY_IMAGE,
            ]}
          />
        </div>
      </div>

      <div className={styles.wrapper}>
        <Text className={styles.title}>{t(`${translationTitles}.link`)}</Text>

        <FormItem
          hasFeedback={true}
          name={[
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_COUNTER_OPERATION,
          ]}
        >
          <AppSelect
            options={OPERATION_OPTIONS}
            label={t(`${translationLabels}.operation`)}
          />
        </FormItem>

        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [
              FormItemName.CAMPAIGN_TYPE_SPECIFIC,
              FormItemName.CAMPAIGN_TS_COUNTER_URL,
            ],
          }}
          textFieldProps={{
            label: 'URL',
            className: styles.inputText,
          }}
        />
      </div>
    </AppCardBasic>
  );
};
