import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';
import { setActiveCampaign } from '../../store/slices/endUserSlice';
import { useGetPublicActiveCampaignsQuery } from '../../store/api/endUser';

export const useGetPublicActiveCampaigns = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const tenantId = tenantAndProject?.tenantId;
  const modelId = tenantAndProject?.projectId;

  const { data: activeCampaignsData } = useGetPublicActiveCampaignsQuery(
    {
      tenantId: String(tenantId),
      projectId: Number(modelId),
    },
    {
      skip: !isGuestMode,
    },
  );

  useEffect(() => {
    if (activeCampaignsData?.length) {
      dispatch(setActiveCampaign(activeCampaignsData[0]));
    }
  }, [activeCampaignsData, dispatch]);
};
