import { FC } from 'react';
import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import { FormInstance, Typography, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';

import styles from './index.module.scss';
import { FormItemName } from '../../../../../../lib/models/Form';
import { CampaignObjectFromItem } from '../CampaignObjectFromItem';
import { Button } from '../../../../../../components/Button';

const { Text } = Typography;

const translationTitles = 'pages.campaigns.campaignTypeSpecific.titles';

const OBJECT_LIMIT = 15;

type Props = {
  form: FormInstance;
};

export const CampaignObjectSettings: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const elementsCount = Form.useWatch(
    [FormItemName.CAMPAIGN_TYPE_SPECIFIC, FormItemName.CAMPAIGN_TS_OBJECT_LIST],
    form,
  );

  const isThereAtLeastOneObject = elementsCount?.length > 0;
  const isAddButtonDisabled = elementsCount?.length === OBJECT_LIMIT;

  return (
    <div>
      <Text className={styles.title}>{t(`${translationTitles}.setUpObjects`)}</Text>
      <AppCardBasic className={styles.card}>
        {isThereAtLeastOneObject && (
          <div className={styles.listHeader}>
            <Text className={styles.listHeaderItem}>
              {t(`${translationTitles}.objectName`)}
            </Text>
            <Text className={styles.listHeaderItem}>
              {t(`${translationTitles}.objectImage`)}
            </Text>
          </div>
        )}

        <Form.List
          name={[
            FormItemName.CAMPAIGN_TYPE_SPECIFIC,
            FormItemName.CAMPAIGN_TS_OBJECT_LIST,
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              <div
                style={{
                  maxHeight: '370px',
                  overflowY: 'auto',
                  paddingTop: 18,
                }}
              >
                {fields.map(({ key, name }, _, array) => (
                  <CampaignObjectFromItem
                    key={key}
                    name={name}
                    form={form}
                    remove={remove}
                    arrayLength={array.length}
                  />
                ))}
              </div>
              <Button
                onClick={() =>
                  add({
                    [(FormItemName.CAMPAIGN_TYPE_SPECIFIC,
                    FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME)]:
                      `Ei-${elementsCount?.length ? elementsCount.length + 1 : 1}`,
                    [(FormItemName.CAMPAIGN_TYPE_SPECIFIC,
                    FormItemName.CAMPAIGN_TS_OBJECT_UUID)]: nanoid(),
                  })
                }
                type="link"
                className={styles.addObjectButton}
                disabled={isAddButtonDisabled}
              >
                {t(`${translationTitles}.addObject`)}
              </Button>
            </>
          )}
        </Form.List>
      </AppCardBasic>
    </div>
  );
};
