import { FC } from 'react';
import { Typography } from 'antd';
import { IconT } from '../../../designSettings/types';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { FoundElementT } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

const { Text } = Typography;
const statusLevelT = 'endUser.statusLevel';

type Props = {
  remainingPointsToNextLevel: number;
  userPoints: number;
  maxPoints: number;
  levels: any;
  icon: IconT | undefined;
  displayedUserPoints?: number;
  statusLevelCampaignElement?: FoundElementT | null;
};

export const SmallCurrentStatusLevelCard: FC<Props> = ({
  remainingPointsToNextLevel,
  userPoints,
  maxPoints,
  levels,
  icon,
  statusLevelCampaignElement,
}) => {
  const nextLevel = levels.find((subLevel: any) => subLevel.points > Number(userPoints));
  const { brandKit } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const sortedLevels = [...levels].sort((a: any, b: any) => b.points - a.points);
  const userCoveredLevels = sortedLevels.filter(
    (level: any) => Number(userPoints) >= level.points,
  );
  const currentLevel =
    userCoveredLevels.length > 0
      ? userCoveredLevels[0]
      : sortedLevels[sortedLevels.length - 1];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <EndUserTypography type="headlineEight" component="Text" className={styles.title}>
          {t(`${statusLevelT}.yourStatus`)}
        </EndUserTypography>
        <EndUserTypography
          type="headlineEight"
          component="Text"
          style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
        >
          {currentLevel?.statusLevelName}
        </EndUserTypography>
      </div>

      <div
        className={styles.pointsSection}
        style={{ marginBottom: remainingPointsToNextLevel >= 0 ? 0 : 24 }}
      >
        <Text className={styles.currentPoints}>
          {userPoints}
          <img src={icon?.s3Url} alt="icon" style={{ width: '10px', height: '14px' }} />/
        </Text>
        <Text className={styles.points}>
          {maxPoints}
          {statusLevelCampaignElement ? (
            <CampaignObjectImage
              campaignObject={statusLevelCampaignElement}
              imageClassName={styles.campaignElement}
              completedIconClassName={styles.campaignCompletedObject}
            />
          ) : (
            <IconComponent
              iconData={icon}
              color="#858899"
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          )}
        </Text>
      </div>

      {remainingPointsToNextLevel >= 0 ? (
        <Text className={styles.description}>
          <span className={styles.remainingPoints}>
            {remainingPointsToNextLevel}{' '}
            <img src={icon?.s3Url} alt="icon" style={{ width: '10px', height: '14px' }} />{' '}
          </span>{' '}
          {t(`${statusLevelT}.tillSmall`)}
          <span>
            {' '}
            {nextLevel?.statusLevelName || sortedLevels?.at(0)?.statusLevelName}{' '}
            {t(`${statusLevelT}.status`)}
          </span>
        </Text>
      ) : null}
    </div>
  );
};
