export enum ElementValues {
  Score = 'Score',
  Bonus = 'Bonus',
  StatusLevel = 'Status level',
  Product = 'Product',
  DataField = 'Datafield',
  Campaign = 'Campaign',
}

export enum ElementSubValues {
  ListOfBonuses = 'ListOfBonuses',
  StatusLevel = 'StatusLevel',
  Threshold = 'Threshold',
  OptInMarketing = 'OptInMarketing',
  RegistrationDate = 'RegistrationDate',
  Product = 'Product',
  ProductPrice = 'ProductPrice',
  ProductPoints = 'ProductPoints',
  CoverageAmount = 'CoverageAmount',
  ContractDate = 'ContractDate',
  CampaignElement = 'Campaign element',
  ForEachCampaign = 'For each campaign',
}

export enum ElementSubValues2 {
  ListOfStatusLevels = 'ListOfStatusLevels',
  Next = 'Next',
  Current = 'Current',
  listOfProducts = 'listOfProducts',
}

export enum DataTypesT {
  INTEGER = 'INTEGER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
}

// Quantifiers

export enum QuantifiersValues {
  ANY = 'Any',
  All = 'All',
  None = 'None',
  Number = 'Number',
}

export enum QuantifiersSubValues {
  InputField = 'Input field',
}

// Values

export enum ValuesT {
  Percent = 'Percent',
  Digit = 'Digit',
  Date = 'Date',
  Boolean = 'Boolean',
}

export enum ValuesSubValues {
  InputField = 'Input field',
  Today = 'Today',
  Day = 'Day',
  DayDigit = 'Day Digit',
  True = 'False',
  False = 'True',
}

export enum ValuesSubValues2 {
  CalendarSelector = 'Calendar selector',
  InputField = 'Input field',
}

// State

export enum StateValues {
  OnWishlist = 'On wishlist',
  Recommended = 'Recommended',
}

// Logical

export enum LogicalValues {
  IF = 'IF',
  ELSE_IF = 'ELSE IF',
  ELSE = 'ELSE',
  THEN = 'THEN',
  OR = 'OR',
  AND = 'AND',
  OP_BRACKET = '(',
  CLO_BRACKET = ')',
}

// Arithmetic

export enum ArithmeticValues {
  PLUS = '+',
  SUBTRACT = '-',
  MULTIPLY = '*',
  ASSIGN = '=',
}

// Comparison

export enum ComparisonValues {
  MORE = '>',
  LESS = '<',
  MORE_EQUAL = '>=',
  LESS_EQUAL = '<=',
  EQUALS = '==',
  NOT_EQUAL = '!=',
}
