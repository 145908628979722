import { CSSProperties, FC } from 'react';
import { EndUserTypography } from '../../../EndUserTypography';

import styles from './index.module.scss';
import { EndUserButton } from '../../../EndUserButton';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import cn from 'classnames';
import { CampaignElementObjectT, ImageObject } from '../../../../lib/types/endUser';

type Props = {
  title: string;
  campaignBackgroundMobileTablet: CSSProperties;
  emptyImageUrl: string;
  handleClickBadge: () => void;
  campaignElements: Array<
    CampaignElementObjectT & {
      isCompleted: boolean;
      completedImage?: ImageObject | null;
    }
  >;
};

export const MobileTabletElementsList: FC<Props> = ({
  title,
  campaignBackgroundMobileTablet,
  campaignElements,
  emptyImageUrl,
  handleClickBadge,
}) => {
  const { isMobile } = useContainerQuery();

  return (
    <div className={cn(styles.container, isMobile && styles.containerMobile)}>
      <EndUserTypography className={styles.title} type="headlineSecond">
        {title}
      </EndUserTypography>

      <div className={styles.elementsContainer} style={campaignBackgroundMobileTablet}>
        {campaignElements.map((el) => (
          <div
            key={el.id}
            className={cn(
              styles.elementItem,
              !el.isCompleted && styles.notFoundElementItem,
            )}
          >
            {el.isCompleted ? (
              <img
                alt="found element"
                src={el.completedImage?.s3Url}
                className={styles.foundElement}
              />
            ) : (
              <img
                className={styles.notFoundElement}
                src={emptyImageUrl}
                alt="empty palceholder"
              />
            )}
          </div>
        ))}
      </div>

      <EndUserButton className={styles.button} type="primary" onClick={handleClickBadge}>
        Zu den Prämien
      </EndUserButton>
    </div>
  );
};
