import { FC } from 'react';
import { Form, FormInstance, Switch, Typography } from 'antd';
import styles from './index.module.scss';
import { FormItem } from '../../../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormSection } from '../../../../../../components/FormSection';
import { AppSelect } from '../../../../../../components/AppSelect';
import { OPERATION_OPTIONS } from '../../../../helpers/consts';
import { CampaignButtonColorCard } from '../../../CampaignButtonColorCard';

const translationLabels = 'pages.campaigns.campaignDetails.form.labels';

const { Text } = Typography;

type Props = {
  switchFormName: string | string[];
  buttonTextFormName: string | string[];
  buttonOperationFormName: string | string[];
  buttonDestinationFormName: string | string[];
  buttonTextColorFormName: string | string[];
  buttonBackgroundFormName: string | string[];
  form: FormInstance;
  switchText: string;
  isTeaserDisabled: boolean;
};

export const CampaignTeaserButton: FC<Props> = ({
  switchFormName,
  buttonTextFormName,
  buttonOperationFormName,
  buttonDestinationFormName,
  buttonTextColorFormName,
  buttonBackgroundFormName,
  form,
  switchText,
  isTeaserDisabled,
}) => {
  const { t } = useTranslation();

  const switchState = Form.useWatch(switchFormName, form);
  const isDisabled = !switchState;

  return (
    <div className={styles.container}>
      <div className={styles.switchContainer}>
        <FormItem
          hasFeedback
          name={switchFormName}
          valuePropName="checked"
          initialValue={false}
          className={styles.switchFormItem}
        >
          <Switch disabled={isTeaserDisabled} size="small" />
        </FormItem>
        <Text className={styles.title}>{switchText}</Text>
      </div>

      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: buttonTextFormName,
          rules: [{ required: !isDisabled }],
        }}
        textFieldProps={{
          label: t(`${translationLabels}.buttonText`),
          disabled: isDisabled,
        }}
      />

      <FormItem className={styles.col} hasFeedback={true} name={buttonOperationFormName}>
        <AppSelect
          disabled={isDisabled}
          options={OPERATION_OPTIONS}
          label={t(`${translationLabels}.operation`)}
        />
      </FormItem>

      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: buttonDestinationFormName,
          rules: [{ required: !isDisabled }],
        }}
        textFieldProps={{
          label: t(`${translationLabels}.destination`),
          disabled: isDisabled,
        }}
      />

      <CampaignButtonColorCard
        formName={buttonTextColorFormName}
        label={t(`${translationLabels}.textColor`)}
        disabled={isDisabled}
        className={styles.colorSelect}
      />

      <CampaignButtonColorCard
        formName={buttonBackgroundFormName}
        label={t(`${translationLabels}.backgroundColor`)}
        disabled={isDisabled}
        className={styles.colorSelect}
      />
    </div>
  );
};
