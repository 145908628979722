import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { ColorsList } from '../../types/colors&fonts';

export type CampaignTeaserSettings = {
  campaignTeaserEnabled: boolean;
  campaignTeaserHeadline: string;
  campaignTeaserSubLine: string;
  campaignTeaserBackgroundColor?: string;
  campaignTeaserHeadlineSubLineColor?: string;
  campaignTeaserPrimaryButtonEnabled: boolean;
  campaignTeaserPrimaryButtonText: string;
  campaignTeaserPrimaryButtonOperation?: string;
  campaignTeaserPrimaryButtonDestination: string;
  campaignTeaserPrimaryButtonTextColor?: string;
  campaignTeaserPrimaryButtonBackgroundColor?: string;
  campaignTeaserSecondaryButtonEnabled: boolean;
  campaignTeaserSecondaryButtonText: string;
  campaignTeaserSecondaryButtonOperation?: string;
  campaignTeaserSecondaryButtonDestination: string;
  campaignTeaserSecondaryButtonTextColor?: string;
  campaignTeaserSecondaryButtonBackgroundColor?: string;
  campaignTeaserRightSideImage?: string;
};

export const useApplyCampaignTeaser = (): CampaignTeaserSettings | null => {
  const { campaignSettings, designSettings, brandKit } = useAppSelector(
    ({ endUser }) => endUser,
  );

  const [allColors, setAllColors] = useState<ColorsList>();

  useEffect(() => {
    if (designSettings && brandKit) {
      const { colors } = brandKit;
      const { additionalColors } = designSettings;

      setAllColors({ ...additionalColors, ...colors });
    }
  }, [brandKit, designSettings]);

  if (!campaignSettings || !allColors) {
    return null;
  }

  return {
    campaignTeaserEnabled: campaignSettings.isCampaignTeaserEnabled,
    campaignTeaserHeadline: campaignSettings.campaignTeaserHeadline,
    campaignTeaserSubLine: campaignSettings.campaignTeaserSubLine,
    campaignTeaserBackgroundColor: campaignSettings.campaignTeaserBackgroundColor,
    campaignTeaserHeadlineSubLineColor:
      allColors[campaignSettings.campaignTeaserHeadlineSubLineColor],
    // primary btn
    campaignTeaserPrimaryButtonEnabled:
      campaignSettings.campaignTeaserPrimaryButtonEnabled,
    campaignTeaserPrimaryButtonText: campaignSettings.campaignTeaserPrimaryButtonText,
    campaignTeaserPrimaryButtonOperation:
      campaignSettings.campaignTeaserPrimaryButtonOperation?.value,
    campaignTeaserPrimaryButtonDestination:
      campaignSettings.campaignTeaserPrimaryButtonDestination,
    campaignTeaserPrimaryButtonTextColor:
      allColors[campaignSettings.campaignTeaserPrimaryButtonTextColor],
    campaignTeaserPrimaryButtonBackgroundColor:
      allColors[campaignSettings.campaignTeaserPrimaryButtonBackgroundColor],
    // secondary btn
    campaignTeaserSecondaryButtonEnabled:
      campaignSettings.campaignTeaserSecondaryButtonEnabled,
    campaignTeaserSecondaryButtonText: campaignSettings.campaignTeaserSecondaryButtonText,
    campaignTeaserSecondaryButtonOperation:
      campaignSettings.campaignTeaserSecondaryButtonOperation?.value,
    campaignTeaserSecondaryButtonDestination:
      campaignSettings.campaignTeaserSecondaryButtonDestination,
    campaignTeaserSecondaryButtonTextColor:
      allColors[campaignSettings.campaignTeaserSecondaryButtonTextColor],
    campaignTeaserSecondaryButtonBackgroundColor:
      allColors[campaignSettings.campaignTeaserSecondaryButtonBackgroundColor],
    // right side image
    campaignTeaserRightSideImage: campaignSettings.campaignTeaserRightSideImage?.s3Url,
  };
};
