import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { ColorsList } from '../../types/colors&fonts';
import { CompletedCampaignElement, EndUserCampaignT } from '../../types/endUser';
import { CounterBackgroundSegmentOption } from '../../../modules/campaignDetails/types/types';

export type CampaignCounterSettings = {
  campaignTSCounterBadgeHeadline: string;
  campaignTSCounterBadgeHeadlineColor?: string;
  campaignTSCounterBadgeBgColor: string;
  campaignTSCounterBgImage: string;
  campaignTSCounterBgImageMobile: string;
  campaignTSCounterEmptyImage: string;
  campaignTSCounterOperation: string;
  campaignTSCounterUrl: string;
  activeCampaign: EndUserCampaignT | null;
  completedCampaignElements: CompletedCampaignElement[];
  isGuestMode: boolean;
  campaignTSCounterBgOption: CounterBackgroundSegmentOption;
  campaignTSCounterBgColor: string;
};

export const useGetEndUserCampaignCounterSettings =
  (): CampaignCounterSettings | null => {
    const {
      campaignSettings,
      designSettings,
      brandKit,
      activeCampaign,
      completedCampaignElements,
      isGuestMode,
    } = useAppSelector(({ endUser }) => endUser);

    const [allColors, setAllColors] = useState<ColorsList>();

    useEffect(() => {
      if (designSettings && brandKit) {
        const { colors } = brandKit;
        const { additionalColors } = designSettings;

        setAllColors({ ...additionalColors, ...colors });
      }
    }, [brandKit, designSettings]);

    if (!campaignSettings || !allColors) {
      return null;
    }

    return {
      campaignTSCounterBadgeHeadline: campaignSettings.campaignTSCounterBadgeHeadline,
      campaignTSCounterBadgeHeadlineColor:
        allColors[campaignSettings.campaignTSCounterBadgeHeadlineColor],
      campaignTSCounterBadgeBgColor: campaignSettings.campaignTSCounterBadgeBgColor,
      campaignTSCounterBgImage: campaignSettings.campaignTSCounterBgImage?.s3Url,
      campaignTSCounterEmptyImage: campaignSettings.campaignTSCounterEmptyImage?.s3Url,
      campaignTSCounterOperation: campaignSettings.campaignTSCounterOperation?.value,
      campaignTSCounterUrl: campaignSettings.campaignTSCounterUrl,
      campaignTSCounterBgOption: campaignSettings.campaignTSCounterBgOption,
      campaignTSCounterBgColor: campaignSettings.campaignTSCounterBgColor,
      campaignTSCounterBgImageMobile:
        campaignSettings.campaignTSCounterBgImageMobile?.s3Url,
      activeCampaign,
      completedCampaignElements,
      isGuestMode,
    };
  };
