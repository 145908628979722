import { CampaignTabs, QueryParams } from '../../../../lib/types/queryParams';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTabQuery } from '../../../../lib/hooks/useTabQuery';
import { AppTabs } from '../../../../components/AppTabs';

import { Button } from '../../../../components/Button';

import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/left-arrow.svg';

import styles from './index.module.scss';
import cn from 'classnames';

import { CampaignGeneralSettings } from '../CampaignGeneralSettings';
import { CampaignTypeSpecificSettings } from '../СampaignTypeSpecificSettings';

import { useCampaignDetailsForm } from '../../hooks/useCampaignDetailsForm';
import { useUpdateCampaignMutation } from '../../../../lib/store/api/campaigns';
import { CAMPAIGN_STATUSES } from '../../../campaignTable/helpers/consts';
import { MessageInstance } from 'antd/es/message/interface';
import { DeleteCampaignModal } from '../DeleteCampaignModal';
import { FormItemName } from '../../../../lib/models/Form';
import { CampaignBonusRules } from '../CampaignBounusRules';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { setSelectedCampaign } from '../../../../lib/store/slices/campaignsSlice';

const tabTranslation = 'pages.campaigns.campaignTable.tabs';
const genericTranslation = 'generic';
const errorMsgs = 'pages.campaigns.campaignDetails.errorMessages';
const successMsgs = 'pages.campaigns.campaignDetails.successMessages';

type Props = {
  onBackClick: () => void;
  messageApi: MessageInstance;
};

export const CampaignDetailsTabs: FC<Props> = ({ onBackClick, messageApi }) => {
  const { id: tenantId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get(QueryParams.TAB);

  const [deleteCampaignModalOpen, setDeleteCampaignModalOpen] = useState(false);

  const [activeKey, setActiveKey] = useState<CampaignTabs | null>(null);

  const elementsCount = Form.useWatch(
    [FormItemName.CAMPAIGN_TYPE_SPECIFIC, FormItemName.CAMPAIGN_TS_OBJECT_LIST],
    form,
  );

  const { onFinish, selectedCampaign } = useCampaignDetailsForm({
    form,
    messageApi,
    onBackClick,
  });

  const [updateCampaign] = useUpdateCampaignMutation();

  useTabQuery(activeKey as string);

  useEffect(() => {
    setActiveKey(queryTab ? (queryTab as CampaignTabs) : CampaignTabs.GENERAL);
  }, [queryTab]);
  const handleTabChange = (key: string) => {
    setActiveKey(key as CampaignTabs);
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedCampaign(null));
    };
  }, [dispatch]);

  const items = [
    {
      label: t(`${tabTranslation}.general`),
      children: <CampaignGeneralSettings form={form} />,
      key: CampaignTabs.GENERAL,
      forceRender: true,
    },
    {
      label: t(`${tabTranslation}.typeSpecific`),
      children: <CampaignTypeSpecificSettings form={form} />,
      key: CampaignTabs.TYPE_SPECIFIC,
      forceRender: true,
    },
    {
      label: t(`${tabTranslation}.bonusRules`),
      children: <CampaignBonusRules form={form} selectedCampaign={selectedCampaign} />,
      key: CampaignTabs.BONUS_RULES,
      forceRender: true,
    },
  ];

  const onSubmit = () => {
    form.submit();
  };

  const onDeleteCampaignModalOpen = () => {
    setDeleteCampaignModalOpen(true);
  };

  const closeDeleteCampaignModalOpen = () => {
    setDeleteCampaignModalOpen(false);
  };

  const onChangeCampaignStatus = async () => {
    if (selectedCampaign) {
      if (!elementsCount || (elementsCount && elementsCount.length < 5)) {
        const msg = t(`${errorMsgs}.activationWarn`);

        messageApi.error(msg);
        return;
      }

      try {
        await updateCampaign({
          id: selectedCampaign?.id,
          status: CAMPAIGN_STATUSES.ACTIVE,
          tenantId: tenantId,
        }).unwrap();

        const successText = t(`${successMsgs}.activationSuccess`);

        messageApi.success(successText);

        onBackClick();
        //eslint-disable-next-line
      } catch (error: any) {
        messageApi.error(error?.data?.message || t(`${errorMsgs}.activationError`));
      }
    }
  };

  return (
    <div>
      <DeleteCampaignModal
        isOpen={deleteCampaignModalOpen}
        close={closeDeleteCampaignModalOpen}
        selectedCampaign={selectedCampaign}
        messageApi={messageApi}
        onGoBack={onBackClick}
      />
      <Form
        form={form}
        className={styles.root}
        onFinish={onFinish}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
      >
        <AppTabs
          className={styles.container}
          activeKey={activeKey as string}
          tabBarStyle={{ borderBottom: 'none' }}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: (
              <Button onClick={onBackClick} type="text" className={styles.backButton}>
                <LeftArrowIcon />
                {t(`${genericTranslation}.back`)}
              </Button>
            ),
            right: (
              <div className={styles.rightButtonsContainer}>
                <Button
                  onClick={onChangeCampaignStatus}
                  type="default"
                  className={cn(styles.btn, styles.deleteButton)}
                >
                  {t(`${genericTranslation}.activate`)}
                </Button>
                <Button
                  onClick={onDeleteCampaignModalOpen}
                  type="default"
                  className={cn(styles.btn, styles.deleteButton)}
                >
                  {t(`${genericTranslation}.delete`)}
                </Button>
                <Button
                  onClick={onSubmit}
                  type="text"
                  className={cn(styles.btn, styles.saveButton)}
                >
                  {t(`${genericTranslation}.save`)}
                </Button>
              </div>
            ),
          }}
          items={items}
        />
      </Form>
    </div>
  );
};
