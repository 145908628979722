import { FC } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Typography } from 'antd';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/left-arrow.svg';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  text: string;
  link: string;
  hideBackButton?: boolean;
};

export const PlatformSubHeader: FC<Props> = ({ text, link, hideBackButton }) => {
  return (
    <>
      <header>
        {!hideBackButton && (
          <Link className={styles.back_link} to={link}>
            <LeftArrowIcon className={styles.back_link_icon} />
            <Text>{text}</Text>
          </Link>
        )}
      </header>
      <Outlet />
    </>
  );
};
