import { FC, useMemo } from 'react';
import { CategoriesList } from '../CategoriesList';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';

import {
  checkBoughtProductsInCategory,
  getCoveredCategories,
  getProductPointsFromCategory,
} from '../../../../lib/utils/end-user-helpers';

import styles from './index.module.scss';
import { parseJSON } from '../../../../lib/utils/helpers';
import { useGetCampaignObjectPositions } from '../../../../lib/hooks/endUserHooks/useGetCampaignObjectPositions';
import {
  CAMPAIGN_POSITION_ELEMENT_OPTIONS,
  CAMPAIGN_POSITION_PAGES_OPTIONS,
  CAMPAIGN_POSITION_SECTION_OPTIONS,
  SUB_ELEMENT_TYPE,
} from '../../../campaignDetails/hooks/useGetPositionOption';
import { CampaignObjectImage } from '../../../../components/CampaignObjectImage';

export const CategoryOverviewSection: FC = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  // const navigate = useNavigate();

  const { foundObjects } = useGetCampaignObjectPositions({
    page: CAMPAIGN_POSITION_PAGES_OPTIONS.LANDING_PAGE,
    sections: [
      CAMPAIGN_POSITION_SECTION_OPTIONS.CATEGORY_OVERVIEW,
      CAMPAIGN_POSITION_SECTION_OPTIONS.HOUSE,
    ],
    elements: [
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
      CAMPAIGN_POSITION_ELEMENT_OPTIONS.CATEGORY_ICON,
    ],
  });

  const foundHeadlineCampaignObject = foundObjects.find(
    (obj) => obj.type === CAMPAIGN_POSITION_ELEMENT_OPTIONS.HEADLINE,
  );

  const currentSectionContent = sections?.[SectionName.CATEGORY_OVERVIEW];

  const sortedCategories = useMemo(() => {
    const sortedDemandAreas = [...(demandArea?.listDemandArea || [])].map(
      (demandArea) => {
        const sortedDemandAreaCategories = [...(demandArea.categories || [])].sort(
          (a, b) => a.priority.number - b.priority.number,
        );

        return {
          ...demandArea,
          categories: sortedDemandAreaCategories,
        };
      },
    );

    return sortedDemandAreas || [];
  }, [demandArea]);

  const { categories, coveredCategories } = useMemo(() => {
    let coveredCategories = 0;

    const categories = sortedCategories
      ?.map((demandArea) => {
        coveredCategories += getCoveredCategories(demandArea.categories).length;

        const foundCampaignObjectsForCategories = foundObjects.filter(
          (obj) => obj.type === SUB_ELEMENT_TYPE.CATEGORY,
        );

        return demandArea.categories?.map((item) => {
          const foundCampaignForCategory = foundCampaignObjectsForCategories.find(
            (obj) => obj.categoryId === item.id,
          );

          return {
            ...item,
            covered: Boolean(checkBoughtProductsInCategory(item)),
            points: getProductPointsFromCategory(item),
            categoryColor: parseJSON(demandArea.color)?.value,
            campaignObject: foundCampaignForCategory ? foundCampaignForCategory : null,
          };
        });
      })
      .flat();

    return {
      categories,
      coveredCategories,
    };
  }, [foundObjects, sortedCategories]);

  // const handleClickButton = () => {
  //   if (currentSectionContent?.buttonRedirectTo) {
  //     const link = `/${currentSectionContent?.buttonRedirectTo}`;
  //
  //     navigate(link);
  //
  //     sendDataToAnalytics('CategoryOverviewSection', 'redirect to', link);
  //
  //     window.scrollTo(0, 0);
  //   }
  // };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.headerWrapper}>
            <EndUserTypography
              type="headlineSecond"
              component="Title"
              level={2}
              className={styles.title}
            >
              {currentSectionContent?.headline2}
            </EndUserTypography>
            {foundHeadlineCampaignObject && (
              <CampaignObjectImage
                campaignObject={foundHeadlineCampaignObject}
                imageClassName={styles.campaignObject}
              />
            )}
          </div>
          <EndUserTypography
            type="headlineThird"
            component="Title"
            level={3}
            className={styles.subTitle}
          >
            {currentSectionContent?.headline3}
          </EndUserTypography>

          <EndUserTypography
            type="headlineFourth"
            component="Text"
            className={styles.categoriesAmount}
          >
            {coveredCategories}/{categories?.length} {currentSectionContent?.categoryName}
          </EndUserTypography>
        </div>
      </div>
      <CategoriesList categories={categories} />
      {/*<EndUserButton className={styles.button} type="primary" onClick={handleClickButton}>*/}
      {/*  {currentSectionContent?.buttonText}*/}
      {/*</EndUserButton>*/}
    </>
  );
};
