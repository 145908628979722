import { FC, useEffect, useState } from 'react';
import { Header as AntHeader } from 'antd/es/layout/layout';
import { HeaderConfigT } from '../../../../modules/designSettings/types';
import styles from './index.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../lib/store/hooks';
import cx from 'classnames';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserProfilePopup } from '../../../../components/EndUserProfilePopup';
import {
  openLinkAndTrack,
  sendDataToAnalytics,
} from '../../../../lib/utils/sendDataToAnalytics';
import { EndUserDeleteProfileModal } from '../../../../components/EndUserDeleteProfileModal';
import { notification, Tooltip } from 'antd';

import IconParty from '../../../../assets/icons/party-popper.png';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/question.svg';

import { CongratulationModalWithBonuses } from '../../../../modules/endUserCongratulationModal/components/CongratulationModalWithBonuses';
import { appDayJs } from '../../../../lib/models/Time';
import { useTranslation } from 'react-i18next';
import { getWishlistStatusLevelsData } from '../../../../modules/endUserStatusLevel/utils/helpers';
import { useCheckUserStatusLevelNotification } from '../../../../lib/hooks/endUserHooks/useCheckUserStatusLevelNotification';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { useIsEnergieScwabenTenant } from '../../../../lib/hooks/endUserHooks/useIsEnergieScwabenTenant';
import { useHardcodedRedirectToCategory } from '../../../../lib/hooks/endUserHooks/useHardcodedRedirectToCategory';
import { IconButton } from '../../../../components/IconButton';
import { useIsEviTenant } from '../../../../lib/hooks/endUserHooks/useIsEviTenant';
import { useGetTenantsFaq } from '../../../../lib/hooks/useGetTenantsFaq';
import { EndUserCampaignCounter } from '../../../../components/EndUserCampaignCounter';

const reportDateText = 'endUser.shared.reportDate';
const shared = 'endUser.shared';

type Props = {
  headerConfig: HeaderConfigT;
};

enum MenuItem {
  LOGO = 'Logo',
  HOME = 'Home',
  PRODUCTS = 'Products',
  BONUSES = 'Bonuses',
  WISHLIST = 'Wishlist',
}

export const DesktopHeader: FC<Props> = ({ headerConfig }) => {
  const { isBackgroundShadow, backgroundColor, logo, navigation } = headerConfig;
  const {
    brandKit,
    wishlistProducts,
    allProducts,
    statusLevelNotification,
    invisibleCategoryProducts,
    demandArea,
    isGuestMode,
    userStatusLevel,
  } = useAppSelector(({ endUser }) => endUser);

  const { energieScwabenFaqLink, eviFaqLink } = useGetTenantsFaq();

  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;
  const isEviTenant = useIsEviTenant();
  const { isEnergieSchwaben } = useIsEnergieScwabenTenant();

  const { currentPointsInWishlist, wishedPoints } = userStatusLevel;

  const { checkUserStatusLevelNotification } = useCheckUserStatusLevelNotification();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const reportDate = demandArea?.project?.reportTime;

  const { hardcodedRedirect } = useHardcodedRedirectToCategory();

  const onClickNavigationMenuItem = (menuItem: MenuItem) => () => {
    if (menuItem === MenuItem.LOGO) {
      sendDataToAnalytics(
        'Navigation',
        'Wechsel zur Website',
        'User wechselt zur Website',
      );

      navigate('/home');
    }

    if (menuItem === MenuItem.HOME) {
      sendDataToAnalytics(
        'Navigation',
        'Seitenwechsel',
        'User kehrt zu Hausmodell zurück',
      );
    }

    if (menuItem === MenuItem.PRODUCTS) {
      sendDataToAnalytics(
        'Navigation',
        'Aufruf Bedarfsbereich',
        'User ruft Produktwelt auf',
      );

      if (isEnergieSchwaben) {
        hardcodedRedirect();
      }
    }

    if (menuItem === MenuItem.BONUSES) {
      sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User ruft Bonusübersicht auf');
    }

    if (menuItem === MenuItem.WISHLIST) {
      sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User ruft Merkliste auf');
    }
  };

  const onClickOpenModal = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFaqClick = () => {
    let link;
    if (isEviTenant) {
      link = eviFaqLink;
    } else if (isEnergieSchwaben) {
      link = energieScwabenFaqLink;
    }

    openLinkAndTrack(link);
  };

  useEffect(() => {
    if (!isGuestMode) {
      const { wishedPoints: newWishedPoints, currentPointsInWishlist: newCurrentPoints } =
        getWishlistStatusLevelsData(wishlistProducts, [
          ...allProducts,
          ...invisibleCategoryProducts,
        ]);

      if (newWishedPoints + newCurrentPoints > wishedPoints + currentPointsInWishlist) {
        checkUserStatusLevelNotification({
          prevPoints: wishedPoints + currentPointsInWishlist,
          newPoints: newWishedPoints + newCurrentPoints,
        });
      }
    }
  }, [wishlistProducts]);

  useEffect(() => {
    if (statusLevelNotification) {
      api.open({
        message: ``,
        description: t(`${shared}.congratulationTitle`, { statusLevelNotification }),
        icon: null,
      });
    }
  }, [api, statusLevelNotification, t]);

  return (
    <>
      <AntHeader
        className={cx(styles.header, { [styles.withShadow]: isBackgroundShadow })}
        style={{ backgroundColor: brandKit?.colors[backgroundColor] }}
      >
        {contextHolder}
        <div className={styles.header_wrap}>
          <div
            onClick={onClickNavigationMenuItem(MenuItem.LOGO)}
            role="presentation"
            className={styles.header_logo_wrap}
          >
            <img src={logo?.s3Url} alt="logo" className={styles.header_logo_image} />
          </div>
          <div className={styles.navigation}>
            {reportDate && !isGuestMode && (
              <EndUserTypography
                type="subText"
                component="Text"
                className={styles.lastUpdate}
              >
                {t(reportDateText)} {appDayJs(reportDate).utc().format('DD.MM.YYYY')}
              </EndUserTypography>
            )}

            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.homeFr : ROUTER_PATHS.home}
              onClick={onClickNavigationMenuItem(MenuItem.HOME)}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              style={({ isActive }) =>
                isActive
                  ? { background: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
                  : { color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
              }
            >
              <EndUserTypography type="body" component="Text">
                {navigation?.landingPage}
              </EndUserTypography>
            </NavLink>
            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel}
              onClick={onClickNavigationMenuItem(MenuItem.PRODUCTS)}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              style={({ isActive }) =>
                isActive
                  ? { background: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
                  : { color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
              }
            >
              <EndUserTypography type="body" component="Text">
                {navigation?.demandAreas}
              </EndUserTypography>
            </NavLink>
            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.bonusesFr : ROUTER_PATHS.bonuses}
              onClick={onClickNavigationMenuItem(MenuItem.BONUSES)}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              style={({ isActive }) =>
                isActive
                  ? { background: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
                  : { color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
              }
            >
              <EndUserTypography type="body" component="Text">
                {navigation?.bonuses}
              </EndUserTypography>
            </NavLink>
            <NavLink
              to={isFrenchTenant ? ROUTER_PATHS.wishlistFr : ROUTER_PATHS.wishlist}
              onClick={onClickNavigationMenuItem(MenuItem.WISHLIST)}
              className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
              style={({ isActive }) =>
                isActive
                  ? { background: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
                  : { color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }
              }
            >
              <EndUserTypography type="body" component="Text">
                {navigation?.wishlist}{' '}
                {wishlistProducts?.length ? `(${wishlistProducts?.length}) ` : null}
                {statusLevelNotification && <img src={IconParty} alt="icon-popper" />}
              </EndUserTypography>
            </NavLink>
          </div>
          <div className={styles.profileWrapper}>
            <div className={styles.profileButton}>
              <EndUserProfilePopup
                handleDeleteProfile={onClickOpenModal}
                backgroundColor={brandKit?.colors[ColorVariants.PRIMARY_COLOR]}
              />
            </div>
            {(isEnergieSchwaben || isEviTenant) && (
              <Tooltip
                color="#191919"
                title="FAQ"
                placement="bottom"
                overlayInnerStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px 16px',
                  fontSize: 16,
                }}
              >
                <span style={{ marginLeft: 16 }}>
                  <IconButton
                    onClick={handleFaqClick}
                    className={styles.faqButton}
                    style={
                      {
                        '--hoverColor': brandKit?.colors[ColorVariants.PRIMARY_COLOR],
                      } as any
                    }
                    icon={<QuestionIcon />}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </AntHeader>
      <EndUserCampaignCounter />
      {isModalOpen && (
        <EndUserDeleteProfileModal close={onModalClose} isModalOpen={isModalOpen} />
      )}
      <CongratulationModalWithBonuses
        isOpen={demandArea?.user?.congratulation === false}
      />
    </>
  );
};
