import { Dispatch, FC } from 'react';
import { CampaignGeneralSettingsT } from '../../../../types/types';
import { AppCardBasic } from '../../../../../../components/AppCardBasic';
import { FormSection } from '../../../../../../components/FormSection';
import { FormItemName } from '../../../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import FormItem from 'antd/es/form/FormItem';
import { AppSelect } from '../../../../../../components/AppSelect';
import { CAMPAIGN_STATUSES } from '../../../../../campaignTable/helpers/consts';
import { CampaignDatePicker } from '../../../CampaignDatePicker';
import { FormInstance } from 'antd';
import cn from 'classnames';
import TextArea from 'antd/lib/input/TextArea';

const translation = 'pages.campaigns.campaignDetails.form';
const translationStatuses = 'pages.campaigns.campaignTable.statuses';

type Props = {
  form: FormInstance;
};

export const CampaignGeneralDetailsBox: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const STATUS_OPTIONS = [
    {
      value: CAMPAIGN_STATUSES.ACTIVE,
      title: t(`${translationStatuses}.active`),
    },
    {
      value: CAMPAIGN_STATUSES.DEACTIVATED,
      title: t(`${translationStatuses}.deactivated`),
    },
    {
      value: CAMPAIGN_STATUSES.UPCOMING,
      title: t(`${translationStatuses}.upcoming`),
    },
  ];

  const TYPE_OPTIONS = [
    {
      value: 'Suche',
      title: 'Suche',
    },
  ];

  return (
    <AppCardBasic className={styles.card}>
      <div className={styles.row}>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [FormItemName.CAMPAIGN_GENERAL_DETAILS, FormItemName.CAMPAIGN_NAME],
            rules: [{ required: true }],
          }}
          textFieldProps={{
            label: t(`${translation}.labels.name`),
            className: styles.inputText,
          }}
        />
      </div>

      <div className={styles.row}>
        <FormItem
          className={styles.col}
          hasFeedback={true}
          name={[FormItemName.CAMPAIGN_GENERAL_DETAILS, FormItemName.CAMPAIGN_TYPE]}
        >
          <AppSelect
            defaultValue={TYPE_OPTIONS[0]}
            options={TYPE_OPTIONS}
            label={t(`${translation}.labels.type`)}
          />
        </FormItem>

        <FormItem
          className={styles.col}
          hasFeedback={true}
          name={[FormItemName.CAMPAIGN_GENERAL_DETAILS, FormItemName.CAMPAIGN_STATUS]}
        >
          <AppSelect
            options={STATUS_OPTIONS}
            disabled
            label={t(`${translation}.labels.status`)}
          />
        </FormItem>
      </div>

      <div className={cn(styles.row, styles.calendarsRow)}>
        <CampaignDatePicker
          form={form}
          label={t(`${translation}.labels.startDate`)}
          formName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_START_DATE,
          ]}
        />

        <CampaignDatePicker
          form={form}
          label={t(`${translation}.labels.endDate`)}
          formName={[
            FormItemName.CAMPAIGN_GENERAL_DETAILS,
            FormItemName.CAMPAIGN_END_DATE,
          ]}
        />
      </div>

      <div className={styles.row}>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [
              FormItemName.CAMPAIGN_GENERAL_DETAILS,
              FormItemName.CAMPAIGN_DESCRIPTION,
            ],
          }}
          textFieldProps={{
            label: t(`${translation}.labels.description`),
            autoSize: { minRows: 3, maxRows: 3 },
            as: TextArea,
            className: styles.inputTextArea,
            showCount: true,
          }}
        />
      </div>
    </AppCardBasic>
  );
};
