import { FC } from 'react';
import { FormSection } from '../../../../../../components/FormSection';
import { FormItemName } from '../../../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';
import { PictureFormItem } from '../../../../../productConfigurator/components/PictureFormItem';
import { FormInstance } from 'antd';

import styles from './index.module.scss';
import { IconButton } from '../../../../../../components/IconButton';

import { ReactComponent as BasketIcon } from '../../../../../../assets/icons/bakset-icon.svg';
import cn from 'classnames';

const translationLabels = 'pages.campaigns.campaignDetails.form.labels';

type Props = {
  name: number | string;
  form: FormInstance;
  remove: (number: number) => void;
  arrayLength: number;
};

export const CampaignObjectFromItem: FC<Props> = ({
  form,
  name,
  remove,
  arrayLength,
}) => {
  const { t } = useTranslation();

  const onRemove = () => {
    remove(name as number);
  };

  return (
    <div className={cn(styles.container, arrayLength >= 6 && styles.scrollRightPadding)}>
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.CAMPAIGN_TS_OBJECT_ITEM_NAME],
        }}
        className={cn(styles.inputWrapper, arrayLength >= 6 && styles.wrapperOnScroll)}
        textFieldProps={{
          label: t(`${translationLabels}.headline`),
          className: styles.inputText,
        }}
      />

      <PictureFormItem
        className={cn(
          styles.imagePickerWrapper,
          arrayLength >= 6 && styles.wrapperOnScroll,
        )}
        pictureClassName={styles.pictureClassName}
        imageWrapperClassName={styles.imageWrapperClassName}
        form={form}
        formName={[
          FormItemName.CAMPAIGN_TYPE_SPECIFIC,
          FormItemName.CAMPAIGN_TS_OBJECT_LIST,
          name as string,
          FormItemName.CAMPAIGN_TS_OBJECT_IMAGE,
        ]}
      />

      <IconButton
        onClick={onRemove}
        className={styles.removeButton}
        icon={<BasketIcon />}
      />
    </div>
  );
};
